import { Directive, HostListener, OnInit } from "@angular/core";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";

import { FeatureFlag } from "../feature-flag.enum";
import { ModalService, ViewType } from "../modal.service";

/**
 * @deprecated to be removed in RMM-39373
 */
@Directive({
  selector: "[appLegacyModal]",
})
export class LegacyModalDirective implements OnInit {
  private shouldOpenDialog: boolean = true;

  constructor(
    private modalService: ModalService,
    private featurePreviewService: FeaturePreviewService
  ) {}

  ngOnInit(): void {
    this.featurePreviewService
      .isOn(FeatureFlag.platformBarv2)
      .subscribe((isEnabled) => (this.shouldOpenDialog = !isEnabled));
  }

  @HostListener("window:open-supportcenter")
  openSupportCentre(): void {
    this.openDialog(ViewType.support);
  }

  @HostListener("window:open-about")
  openAbout(): void {
    this.openDialog(ViewType.about);
  }

  private openDialog(viewType: ViewType): void {
    if (this.shouldOpenDialog) {
      this.modalService.openDialogByViewType(viewType);
    }
  }
}
