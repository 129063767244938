export enum FeatureFlag {
  EcosystemIntegration = "ECOSYSTEM_INTEGRATION",
  monitoredDevices = "SIMEDI_RMM37763_MONITORED_DEVICES",
  ssoFlag = "SIMEDI_RMM36035_ALLOW_QAPI_SSO_TOKEN_USE",
  /**
   * @deprecated to be removed in RMM-39376
   */
  useNableBranding = "SIMEDI_RMM38793_PLATFORM_BAR_2_NAV_BAR_SWITCH_ON_NEW_BRAND",
  /**
   * @deprecated to be removed in RMM-39373
   */
  platformBarv2 = "SIMEDI_RMM38783_PLATFORM_BAR_2",
}
