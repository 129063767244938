import { Routes } from "@angular/router";

import { activeIssuesRoutes } from "./active-issues/active-issues-routes";
import { DashboardComponent } from "./dashboard.component";
import { monitoredDevicesRoutes } from "./monitored-devices/monitored-devices-routes";

export const dashboardRoutes: Routes = [
  {
    path: "dash",
    component: DashboardComponent,
    data: {
      url: "",
      view: "dashboard",
    },
  },
  ...activeIssuesRoutes,
  ...monitoredDevicesRoutes,
];
