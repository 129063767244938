import { Injectable } from "@angular/core";
import {
  MaestroSettings,
  SessionResponse,
  UserSessionService,
} from "@solarwinds/msp-rmm-user-session";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AccountSettings, DashboardSettings } from "./account-settings.interface";

@Injectable({
  providedIn: "root",
})
export class AccountSettingsProvider {
  constructor(private userSessionService: UserSessionService) {}

  settingsList(deAware: boolean = false): Observable<AccountSettings> {
    return this.getUserAccountSettings().pipe(
      map(({ accountSettings }) => ({
        maestro: this.maestroSettingsHandler(accountSettings, deAware),
        checkProcessor: accountSettings["checkProcessor"],
        clientGroupClients: accountSettings["clientGroupClients"],
        dashboardServer: this.getDashboardServerSettings(accountSettings),
      }))
    );
  }

  filterOutDomain(url: string): string {
    const regex = /^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i;
    return `${url.match(regex)[0].replace(/\/$/, "")}/v2/api/`;
  }

  private getUserAccountSettings(): Observable<SessionResponse["result"]> {
    return from(
      this.userSessionService.loadSession().then(({ result }: SessionResponse) => result)
    );
  }

  private maestroSettingsHandler(settings: Object, deAware: boolean = false): MaestroSettings {
    if (deAware && settings["maestro2DeSettings"] && settings["maestro2DeSettings"].url) {
      return this.modifyMaestroSettings(settings["maestro2DeSettings"]);
    }

    if (settings["maestro2Settings"]) {
      return this.modifyMaestroSettings(settings["maestro2Settings"]);
    }
    return this.modifyMaestroSettings(settings["maestroSettings"]);
  }

  private modifyMaestroSettings({ jwt, url }): MaestroSettings {
    return {
      url: this.filterOutDomain(url),
      jwt,
    };
  }

  private getDashboardServerSettings(
    accountSettings: Partial<AccountSettings>
  ): DashboardSettings | undefined {
    if (accountSettings["dashboardServer"]) {
      return {
        ...accountSettings["dashboardServer"],
        accountId: accountSettings["id"],
        userId: accountSettings["userSettings"]["userId"],
      };
    }

    return undefined;
  }
}
