import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { EqualityUtil } from "@solarwinds/msp-psa-common";
import { PsaIssuesComponent } from "@solarwinds/msp-psa-issues";
import { PsaMappingComponent } from "@solarwinds/msp-psa-mapping";
import {
  DialogType,
  Outage,
  OutageInfo,
  TicketInfoDialogService,
} from "@solarwinds/msp-psa-ticket-info";

import { PsaOverlayService } from "./psa-overlay.service";

enum PsaScreen {
  Issues = "issues",
  Mapping = "mapping",
}

interface PsaIssueScreenParameters {
  clientGroup: {
    name: string;
    clientIds: string[];
  };
}

@Injectable()
export class PsaService {
  constructor(
    private psaOverlayService: PsaOverlayService,
    private ticketInfoDialogService: TicketInfoDialogService
  ) {}

  openPsaScreen(screen: string, parameters?: { [key: string]: any }): void {
    switch (screen) {
      case PsaScreen.Issues:
        this.openAndInitialisePsaIssuesScreen(<PsaIssueScreenParameters>parameters);
        break;
      case PsaScreen.Mapping:
        this.openScreen(PsaMappingComponent);
        break;
      default:
        console.error(`Unexpected PSA screen value encountered: ${screen}`);
    }
  }

  openPsaDialog(dialog: string, payload?: { [key: string]: any }): void {
    if (EqualityUtil.isDefined(dialog)) {
      this.ticketInfoDialogService.open(<DialogType>dialog, <Outage | OutageInfo>payload);
    } else {
      console.error(`Unexpected PSA dialog value encountered: ${dialog}`);
    }
  }

  private openScreen<T>(component: ComponentType<T>): T {
    return this.psaOverlayService.open(component);
  }

  private openAndInitialisePsaIssuesScreen(parameters: PsaIssueScreenParameters): void {
    const issuesComponent: PsaIssuesComponent = this.openScreen(PsaIssuesComponent);

    if (parameters && parameters.clientGroup && parameters.clientGroup.clientIds) {
      issuesComponent.clientIds = parameters.clientGroup.clientIds;
    }
  }
}
