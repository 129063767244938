import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { Observable, zip } from "rxjs";
import { map } from "rxjs/operators";

import {
  clearedCheckDeviceTypeQueryMapping,
  failingCheckDeviceTypeQueryMapping,
  warningCheckDeviceTypeQueryMapping,
} from "../consts/device-query.const";
import { DeviceType } from "../enums/device-type.enum";

import { MaestroService } from "./device/maestro/maestro.service";
import { WidgetDataBuilderService } from "./widget-data-builder.service";

@Injectable({
  providedIn: "root",
})
export class ProblemDevicesService {
  constructor(
    private maestro: MaestroService,
    private widgetDataBuilder: WidgetDataBuilderService
  ) {}

  getServers(): Observable<object> {
    return this.retrieveProblemDataByDevice(DeviceType.SERVER).pipe(
      map((data: ChartItem[]) =>
        this.widgetDataBuilder.createDataObject("problemServersData", data)
      )
    );
  }

  getWorkstations(): Observable<object> {
    return this.retrieveProblemDataByDevice(DeviceType.WORKSTATION).pipe(
      map((data: ChartItem[]) =>
        this.widgetDataBuilder.createDataObject("problemWorkstationsData", data)
      )
    );
  }

  private retrieveProblemDataByDevice(deviceType: DeviceType): Observable<object> {
    const failingCheckQuery = this.getDevicesByTypeFromMapping(
      deviceType,
      failingCheckDeviceTypeQueryMapping
    );
    const failingCheckDevices = this.maestro.getDevices(failingCheckQuery);
    const warningDevicesQuery = this.getDevicesByTypeFromMapping(
      deviceType,
      warningCheckDeviceTypeQueryMapping
    );
    const warningDevices = this.maestro.getDevices(warningDevicesQuery);
    const clearedDevicesQuery = this.getDevicesByTypeFromMapping(
      deviceType,
      clearedCheckDeviceTypeQueryMapping
    );
    const clearedDevices = this.maestro.getDevices(clearedDevicesQuery);

    const queries = [failingCheckQuery, warningDevicesQuery, clearedDevicesQuery];

    return zip(failingCheckDevices, warningDevices, clearedDevices).pipe(
      map((data: object[]) => this.createProblemWidgetDataArray(data, queries))
    );
  }

  private getDevicesByTypeFromMapping(
    deviceType: DeviceType,
    deviceMap: Map<DeviceType, object>
  ): object {
    if (!deviceMap.has(deviceType)) {
      throw new Error(`Unknown device type ${deviceType}`);
    }

    return deviceMap.get(deviceType);
  }

  private createProblemWidgetDataArray(
    [failingCheckDevices, okDevices, clearedDevices]: any[],
    [failingCheckQuery, okDevicesQuery, clearedDevicesQuery]: object[]
  ): ChartItem[] {
    return [
      {
        name: $localize`Failing`,
        value: failingCheckDevices.count,
        sourceQuery: {
          deviceFilter: failingCheckQuery,
        },
      },
      {
        name: $localize`Soft failure`,
        value: okDevices.count,
        sourceQuery: {
          deviceFilter: okDevicesQuery,
        },
      },
      {
        name: $localize`Cleared`,
        value: clearedDevices.count,
        sourceQuery: {
          deviceFilter: clearedDevicesQuery,
        },
      },
    ];
  }
}
