import {
  DeviceFeature,
  DeviceFeatureDisplayStatus,
  DeviceFeatureStatus,
} from "../enums/device-feature.enum";
import { DeviceType } from "../enums/device-type.enum";

export const serverCondition = {
  type: "eq",
  name: "device_type",
  value: "server",
};

export const workstationCondition = {
  type: "in",
  name: "device_type",
  value: ["desktop", "laptop"],
};

export const failingCheckFrequencyV2Query = (checkFrequency: String) => ({
  size: 0,
  query: {
    bool: {
      filter: [
        {
          nested: {
            path: "checks",
            query: {
              bool: {
                must: [
                  {
                    match: {
                      "checks.status": "FAILING",
                    },
                  },
                  {
                    match: {
                      "checks.frequency": checkFrequency,
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  },
});

export const featureStatusQuery = (status: DeviceFeatureStatus, features: Array<DeviceFeature>) =>
  new Map<DeviceType, object>([
    [
      DeviceType.ALL,
      {
        query: {
          bool: {
            filter: [
              {
                nested: {
                  path: "features",
                  query: {
                    bool: {
                      must: [
                        {
                          term: {
                            "features.status": status,
                          },
                        },
                        {
                          terms: {
                            "features.name": features,
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        },
      },
    ],
  ]);

export const featureDisplayStatusQuery = (
  displayStatus: DeviceFeatureDisplayStatus,
  features: Array<DeviceFeature>
) =>
  new Map<DeviceType, object>([
    [
      DeviceType.ALL,
      {
        query: {
          bool: {
            filter: [
              {
                nested: {
                  path: "features",
                  query: {
                    bool: {
                      must: [
                        {
                          term: {
                            "features.display_status": displayStatus,
                          },
                        },
                        {
                          terms: {
                            "features.name": features,
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        },
      },
    ],
  ]);
