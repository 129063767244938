import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PsaIssuesModule } from "@solarwinds/msp-psa-issues";
import { PsaMappingModule } from "@solarwinds/msp-psa-mapping";
import { PsaTicketInfoModule } from "@solarwinds/msp-psa-ticket-info";

import { PsaOverlayService, PsaService } from "./services";

@NgModule({
  imports: [CommonModule, PsaMappingModule, PsaIssuesModule, PsaTicketInfoModule],
  exports: [PsaMappingModule, PsaIssuesModule],
  providers: [PsaService, PsaOverlayService],
})
export class PsaModule {}
