import { Injectable } from "@angular/core";
import { DefaultFilter } from "@solarwinds/msp-rmm-unmonitored-devices/lib/services/unmonitored-devices-configuration.service";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class UnmonitoredDevicesDefaultFilterService {
  private filter: Observable<DefaultFilter>;

  constructor() {
    this.filter = of({ groupId: "", filterId: "" });
  }

  setFilter(filter: Observable<DefaultFilter>) {
    this.filter = filter;
  }

  getFilter(): Observable<DefaultFilter> {
    return this.filter;
  }
}
