import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { AccountWizardComponent, StepService } from "@solarwinds/account-wizard-module";

interface AccountWizardOverlayConfig extends OverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: AccountWizardOverlayConfig = {
  hasBackdrop: true,
  backdropClass: "default-overlay-backdrop",
  panelClass: "account-wizard-overlay",
};

@Injectable()
export class AccountWizardOverlayService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private stepService: StepService) {}

  open(config: AccountWizardOverlayConfig = {}): void {
    this.createOverlay(this.getOverlayConfig({ ...DEFAULT_CONFIG, ...config }));

    this.stepService.exitAccountWizardEvent.subscribe(() => {
      this.close();
    });
  }

  close(): void {
    this.overlayRef.dispose();
  }

  private createOverlay(overlayConfig: OverlayConfig): void {
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(new ComponentPortal<AccountWizardComponent>(AccountWizardComponent));
  }

  private getOverlayConfig(config: AccountWizardOverlayConfig): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global(),
    });
  }
}
