<div class="overlay" *appOverlay></div>

<header>
  <msp-rmm-platform-bar></msp-rmm-platform-bar>
</header>

<div appLegacyModal class="main-container">
  <nav [style.visibility]="navHidden ? 'hidden' : 'visible'">
    <nav-menu
      id="rmm-application-nav-menu"
      defaultInitialToggleState="collapsed"
      [legacy]="!(shouldUseNableBranding$ | async)"
    >
      <app-dashboard-nav></app-dashboard-nav>
      <nav-menu-item
        *appPermitted="'update:filter:private'"
        i18n-title="@@filters_button"
        title="Filters"
        icon="section_filters"
        href="/filters"
        noreferrer
      ></nav-menu-item>
      <nav-menu-item
        *appPermitted="'read:hogshead'"
        i18n-title="@@netpath_button"
        title="NetPath"
        icon="NetPath"
        href="/netpath"
        noreferrer
      ></nav-menu-item>
      <nav-menu-item
        *appPermitted="'read:asset_tracking'"
        i18n-title="@@assettracking_button"
        title="Asset Tracking"
        icon="section_asset_tracking"
        href="/assettracking"
        noreferrer
      ></nav-menu-item>
      <nav-menu-item
        *appPermitted="'read:wallchart'"
        i18n-title="@@wallchart_button"
        title="Wall Chart"
        icon="section_wall_chart"
        href="/wallchart"
        noreferrer
      ></nav-menu-item>
      <nav-menu-item
        *ngIf="advancedReportingNavItemEnabled"
        i18n-title="@@advancedreporting_button"
        title="Analytics"
        icon="section_reports"
        href="/advancedreporting"
        noreferrer
      ></nav-menu-item>
      <nav-menu-item
        *ngIf="
          (isIntegrationManagementEnabled$ | async) &&
          (anyIntegrationsPermitted || (isIntegrationManagementPermitted$ | async))
        "
        i18n-title="@@integrations_header"
        title="Integrations"
        icon="section_integration_management"
      >
        <nav-menu-item
          *ngIf="isIntegrationManagementPermitted$ | async"
          i18n-title="@@integrationmanagement_button"
          title="Integration Management"
          href="/integration-management"
          noreferrer
        ></nav-menu-item>
        <ng-container *ngFor="let integrationMenu of integrationMenus">
          <nav-menu-item
            *ngIf="integrationMenusPermitted(integrationMenu?.integrationId)"
            [title]="integrationName(integrationMenu?.integrationId)"
          >
            <nav-menu-item
              *ngFor="let integrationSubMenu of integrationMenu.menuList"
              [title]="integrationSubMenu?.displayName"
              [href]="getIntegrationMenuPath(integrationMenu?.integrationId, integrationSubMenu)"
              noreferrer
            ></nav-menu-item>
          </nav-menu-item>
        </ng-container>
      </nav-menu-item>
      <nav-menu-item
        *ngIf="satEnabled"
        title="Security Assessment Tool"
        icon="Security_Assessment"
        href="/sat"
        noreferrer
      ></nav-menu-item>
    </nav-menu>
  </nav>

  <main appViewportOverlay appPendo>
    <router-outlet></router-outlet>
    <app-shell-container></app-shell-container>
  </main>
</div>

<app-dialog-overlay></app-dialog-overlay>
