import { DeviceType } from "../enums/device-type.enum";

export const deviceMainOSQueryMapping = new Map<DeviceType, object>([
  [
    DeviceType.ALL,
    {
      limit: 50000,
      page: 1,
      start: 0,
    },
  ],
]);
