import { Injectable } from "@angular/core";
import { UnmonitoredDevicesConfig } from "@solarwinds/msp-rmm-unmonitored-devices";
import { DefaultFilter } from "@solarwinds/msp-rmm-unmonitored-devices/lib/services/unmonitored-devices-configuration.service";
import { from, of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { map, switchMap } from "rxjs/operators";

import { AccountSettingsProvider } from "../auth/account-settings-provider";
import { AccountSettings } from "../auth/account-settings.interface";

import { UnmonitoredDevicesDefaultFilterService } from "./unmonitored-devices-default-filter-service";

@Injectable({
  providedIn: "root",
})
export class UnmonitoredDevicesConfigProvider {
  constructor(
    private accountSettingsProvider: AccountSettingsProvider,
    private unmonitoredDevicesDefaultFilterService: UnmonitoredDevicesDefaultFilterService
  ) {}

  getConfig(): Observable<UnmonitoredDevicesConfig> {
    return this.getDefaultFilters().pipe(switchMap((filter) => this.mapSettings(filter)));
  }

  getDefaultFilters(): Observable<DefaultFilter> {
    return this.unmonitoredDevicesDefaultFilterService.getFilter();
  }

  mapSettings(defaultFilter): Observable<UnmonitoredDevicesConfig> {
    return from(this.accountSettingsProvider.settingsList(true)).pipe(
      map((settings: AccountSettings) => ({
        maestroUrl: settings.maestro.url,
        maestroJwtToken: settings.maestro.jwt,
        defaultFilter: defaultFilter,
        clientGroupClients: settings.clientGroupClients,
      }))
    );
  }
}
