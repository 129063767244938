import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RmmFeaturePreviewModule } from "@solarwinds/msp-rmm-feature-preview";
import { RmmUserSessionModule } from "@solarwinds/msp-rmm-user-session";

import { LegacyModalDirective } from "./legacy-modal.directive";
import { PendoDirective } from "./pendo.directive";

@NgModule({
  imports: [CommonModule, RmmFeaturePreviewModule, RmmUserSessionModule],
  declarations: [PendoDirective, LegacyModalDirective],
  exports: [PendoDirective, LegacyModalDirective],
})
export class DirectivesModule {}
