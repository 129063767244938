import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DeviceFilterQueryService {
  private selectByQuery = new Subject<any>();

  // tslint:disable-next-line:member-ordering
  readonly selectByQueryState = this.selectByQuery.asObservable();

  fireSelectByQuery(data): void {
    return this.selectByQuery.next(data);
  }
}
