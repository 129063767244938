import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface ClientCreationResponse {
  success: boolean;
  clientid?: number;
  formerrors?: Array<Array<string>>;
}

@Injectable()
export class ClientService {
  private loadingPromise: Promise<ClientCreationResponse>;

  constructor(private http: HttpClient) {}

  public createClient(name: string): Promise<ClientCreationResponse> {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("function", "client");
    formData.append("action", "submitclient");

    return (this.loadingPromise = new Promise<ClientCreationResponse>((resolve) => {
      this.http
        .post<ClientCreationResponse>("data_processor.php", formData, {})
        .subscribe((data) => {
          resolve(data);
        });
    }));
  }
}
