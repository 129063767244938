import { Inject, Injectable } from "@angular/core";
import { DefaultApiConfigProvider, RemoteApiConfig } from "@solarwinds/quasar-api-client";

declare global {
  interface Window {
    QAPI_SETTINGS: {
      qapiUrl: string;
      territory: string;
      accountId: number;
    };
  }
}
@Injectable({
  providedIn: "root",
})
export class QapiApiConfigProvider extends DefaultApiConfigProvider {
  constructor(@Inject("Window") private window) {
    super();
  }

  get config(): RemoteApiConfig {
    return {
      url: this.window?.QAPI_SETTINGS?.qapiUrl ?? this.remoteUrl,
      rmmTerritory: this.window?.QAPI_SETTINGS?.territory,
      rmmAccountId: `${this.window?.QAPI_SETTINGS?.accountId ?? ""}`,
    };
  }
}
