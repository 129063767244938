import { Injectable } from "@angular/core";
import { Device } from "@solarwinds/monitoring-checks";

import { AveryDevice } from "./avery-device";

@Injectable()
export class DeviceModelMappers {
  getDevices(averyDevices: AveryDevice[]): Device[] {
    const devices: Device[] = averyDevices.map(
      (device) =>
        new Device({
          name: device.Alias || device.DeviceName,
          manufacturer: device.Manufacturer || "",
          guid: device.MonitoredDeviceID || "",
        })
    );
    return devices;
  }
}
