import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { UnmonitoredDevicesConfig } from "@solarwinds/msp-rmm-unmonitored-devices/public_api";
import {
  BASE_SEARCH_TERMS,
  unmonitoredNetworkDevicesQuery,
} from "app/dashboard/consts/unmonitored-network-devices-query.const";
import { UnmonitoredDevicesConfigProvider } from "app/unmonitored-devices/unmonitored-devices-config-provider.service";
import { iif, Observable, of } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";

import { THIRTY_DAYS } from "../../consts/unmonitored-devices-common.const";
import { WidgetDataBuilderService } from "../widget-data-builder.service";

import { MaestroService } from "./maestro/maestro.service";
import { UnmonitoredDeviceSummary } from "./unmonitored-device-summary";

@Injectable({
  providedIn: "root",
})
export class UnmonitoredDevicesService {
  constructor(
    private widgetDataBuilder: WidgetDataBuilderService,
    private maestroService: MaestroService,
    private featurePreviewService: FeaturePreviewService,
    private unmonitoredDevicesConfigProvider: UnmonitoredDevicesConfigProvider
  ) {}

  getUnmonitoredNetworkDevices(): Observable<object> {
    const maestroData = this.unmonitoredDevicesConfigProvider.getConfig().pipe(
      switchMap((conf: UnmonitoredDevicesConfig) => {
        const query = this.getQuery(conf.clientGroupClients);

        return this.maestroService.getV2DevicesBypass(query).pipe(
          map((data: UnmonitoredDeviceSummary) =>
            this.createUnmonitoredNetworkDevicesDataArray(data)
          ),
          map((data: ChartItem[]) =>
            this.widgetDataBuilder.createDataObject("unMonitoredNetworkDevicesData", data)
          )
        );
      })
    );

    return this.featurePreviewService
      .isOn("MTD_AB3951_UNMONITOREDWIDGETS")
      .pipe(mergeMap((flagOn: boolean) => iif(() => flagOn, maestroData, of({}))));
  }

  getUnmonitoredDevicesByOs(): Observable<object> {
    const maestroData = this.unmonitoredDevicesConfigProvider.getConfig().pipe(
      switchMap((conf: UnmonitoredDevicesConfig) => {
        const query = this.getQuery(conf.clientGroupClients);

        return this.maestroService.getV2DevicesBypass(query).pipe(
          map((data: UnmonitoredDeviceSummary) => this.createUnmonitoredDevicesByOsDataArray(data)),
          map((data: ChartItem[]) =>
            this.widgetDataBuilder.createDataObject("unMonitoredDevicesByOsData", data)
          )
        );
      })
    );

    return this.featurePreviewService
      .isOn("MTD_AB3951_UNMONITOREDWIDGETS")
      .pipe(mergeMap((flagOn: boolean) => iif(() => flagOn, maestroData, of({}))));
  }

  getUnmonitoredDevices(): Observable<object> {
    const maestroData = this.unmonitoredDevicesConfigProvider.getConfig().pipe(
      switchMap((conf: UnmonitoredDevicesConfig) => {
        const query = this.getQuery(conf.clientGroupClients);

        return this.maestroService.getV2DevicesBypass(query).pipe(
          map((data: UnmonitoredDeviceSummary) => this.createUnmonitoredDevicesDataArray(data)),
          map((data: ChartItem[]) =>
            this.widgetDataBuilder.createDataObject("unMonitoredDevicesData", data)
          )
        );
      })
    );

    return this.featurePreviewService
      .isOn("MTD_AB3951_UNMONITOREDWIDGETS")
      .pipe(mergeMap((flagOn: boolean) => iif(() => flagOn, maestroData, of({}))));
  }

  private getQuery(clientGroupClients: number[]) {
    const searchTerms: any[] = [...BASE_SEARCH_TERMS];

    if (clientGroupClients) {
      searchTerms.push({
        terms: {
          ecid: clientGroupClients,
        },
      });
    }

    const date: Date = new Date();
    date.setDate(date.getDate() - THIRTY_DAYS);

    return unmonitoredNetworkDevicesQuery(Math.floor(date.valueOf() / 1000), searchTerms);
  }

  private createUnmonitoredNetworkDevicesDataArray(data: UnmonitoredDeviceSummary): ChartItem[] {
    const printersItem: ChartItem = new ChartItem();
    printersItem.name = $localize`Printers`;
    printersItem.value = data.aggregations.Printers.value;

    const switchesItem: ChartItem = new ChartItem();
    switchesItem.name = $localize`Switches`;
    switchesItem.value = data.aggregations.Switches.value;

    const routersItem: ChartItem = new ChartItem();
    routersItem.name = $localize`Routers`;
    routersItem.value = data.aggregations.Routers.value;

    const firewallsItem: ChartItem = new ChartItem();
    firewallsItem.name = $localize`Firewalls`;
    firewallsItem.value = data.aggregations.Firewalls.value;

    const otherItem: ChartItem = new ChartItem();
    otherItem.name = $localize`Other`;
    otherItem.value = data.aggregations.Other.value;

    return [printersItem, switchesItem, routersItem, firewallsItem, otherItem];
  }

  private createUnmonitoredDevicesByOsDataArray(data: UnmonitoredDeviceSummary): ChartItem[] {
    const windowsItem: ChartItem = new ChartItem();
    windowsItem.name = $localize`Windows`;
    windowsItem.value = data.aggregations.Windows.value;

    const macOsxItem: ChartItem = new ChartItem();
    macOsxItem.name = $localize`Mac OSX`;
    macOsxItem.value = data.aggregations.MacOsx.value;

    const linuxItem: ChartItem = new ChartItem();
    linuxItem.name = $localize`Linux`;
    linuxItem.value = data.aggregations.Linux.value;

    return [windowsItem, macOsxItem, linuxItem];
  }

  private createUnmonitoredDevicesDataArray(data: UnmonitoredDeviceSummary): ChartItem[] {
    const workstationsItem: ChartItem = new ChartItem();
    workstationsItem.name = $localize`Workstations`;
    workstationsItem.value = data.aggregations.Workstations.value;

    const serversItem: ChartItem = new ChartItem();
    serversItem.name = $localize`Servers`;
    serversItem.value = data.aggregations.Servers.value;

    const laptopsItem: ChartItem = new ChartItem();
    laptopsItem.name = $localize`Laptops`;
    laptopsItem.value = data.aggregations.Laptops.value;

    return [workstationsItem, serversItem, laptopsItem];
  }
}
