import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { ComponentRef, Injector } from "@angular/core";

export interface Image {
  name: string;
  url: string;
}

interface NovaDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  image?: Image;
}

const DEFAULT_CONFIG: NovaDialogConfig = {
  hasBackdrop: true,
  backdropClass: "dark-backdrop",
  panelClass: "tm-file-preview-dialog-panel",
  image: null,
};

export abstract class NovaDialogOverlayService {
  protected overlayRef: OverlayRef;

  protected constructor(private injector: Injector, private overlay: Overlay) {}

  open<T>(component: ComponentType<T>): T {
    const dialogConfig: NovaDialogConfig = { ...DEFAULT_CONFIG };

    this.overlayRef = this.createOverlay(dialogConfig);

    return this.attachDialogContainer(component, this.overlayRef);
  }

  private createOverlay(config: NovaDialogConfig): OverlayRef {
    const overlayConfig: OverlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer<T>(component: ComponentType<T>, overlayRef: OverlayRef): T {
    const injector: PortalInjector = this.createInjector(component, overlayRef);

    const containerPortal: ComponentPortal<T> = new ComponentPortal<T>(component, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector<T>(component: ComponentType<T>, dialogRef: OverlayRef): PortalInjector {
    const injectionTokens: WeakMap<ComponentType<T>, OverlayRef> = new WeakMap<
      ComponentType<T>,
      OverlayRef
    >();

    injectionTokens.set(component, dialogRef);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: NovaDialogConfig): OverlayConfig {
    const positionStrategy: PositionStrategy = this.overlay.position().global();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: positionStrategy,
    });
  }
}
