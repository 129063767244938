import { CurrentFeatureInstallStatus } from "../enums/device-status.enum";
import { DeviceType } from "../enums/device-type.enum";

export const installFailedConditionQuery = new Map<DeviceType, object>([
  [
    DeviceType.ALL,
    {
      criteria: {
        type: "in",
        name: "feature_status_backup_and_recovery",
        value: CurrentFeatureInstallStatus.INSTALL_FAILED,
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const pendingConditionQuery = new Map<DeviceType, object>([
  [
    DeviceType.ALL,
    {
      criteria: {
        type: "in",
        name: "feature_status_backup_and_recovery",
        value: [CurrentFeatureInstallStatus.PENDING, CurrentFeatureInstallStatus.DEACTIVATING],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const rebootRequiredConditionQuery = new Map<DeviceType, object>([
  [
    DeviceType.ALL,
    {
      criteria: {
        type: "in",
        name: "feature_status_backup_and_recovery",
        value: CurrentFeatureInstallStatus.REBOOT_REQUIRED,
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const notCompatibleConditionQuery = new Map<DeviceType, object>([
  [
    DeviceType.ALL,
    {
      criteria: {
        type: "in",
        name: "feature_status_backup_and_recovery",
        value: [
          CurrentFeatureInstallStatus.NOT_INSTALLED,
          CurrentFeatureInstallStatus.NOT_COMPATIBLE,
        ],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const activeConditionQuery = new Map<DeviceType, object>([
  [
    DeviceType.ALL,
    {
      criteria: {
        type: "in",
        name: "feature_status_backup_and_recovery",
        value: CurrentFeatureInstallStatus.ACTIVE,
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);
