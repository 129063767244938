import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { Observable, zip } from "rxjs";
import { map } from "rxjs/operators";

import {
  offlineDevicesByTypeQuery,
  onlineDevicesByTypeQuery,
  overdueDevicesByTypeQuery,
  rebootDevicesByTypeQuery,
} from "../../consts/device-status-query.const";
import { DeviceType } from "../../enums/device-type.enum";
import { WidgetDataBuilderService } from "../widget-data-builder.service";

import { MaestroService } from "./maestro/maestro.service";

@Injectable({
  providedIn: "root",
})
export class DeviceStatusService {
  private devicesByTypeQueries: Map<DeviceType, object>[] = [
    overdueDevicesByTypeQuery,
    rebootDevicesByTypeQuery,
    offlineDevicesByTypeQuery,
    onlineDevicesByTypeQuery,
  ];
  constructor(
    private maestro: MaestroService,
    private widgetDataBuilder: WidgetDataBuilderService
  ) {}

  getServerStatus() {
    return this.getStatusByDevice(DeviceType.SERVER).pipe(
      map((data: ChartItem[]) => this.widgetDataBuilder.createDataObject("serverStatusData", data))
    );
  }

  getWorkstation(): Observable<object> {
    return this.getStatusByDevice(DeviceType.WORKSTATION).pipe(
      map((widgetData: ChartItem[]) =>
        this.widgetDataBuilder.createDataObject("workstationStatusData", widgetData)
      )
    );
  }

  private getStatusByDevice(deviceType): Observable<object> {
    const queries = this.deviceStatusCheckQueries(deviceType);
    const statuses: Observable<object>[] = this.getdevicesStatuses(queries);

    return zip(...statuses).pipe(
      map((data: object) => this.createStatusWidgetDataArray(data, queries))
    );
  }

  private getdevicesStatuses(queries: object[]): Observable<object>[] {
    return queries.map((query: Map<DeviceType, object>) => this.maestro.getDevices(query));
  }

  private deviceStatusCheckQueries(deviceType: DeviceType): object[] {
    return this.devicesByTypeQueries.map((query: Map<DeviceType, object>) =>
      this.getDevicesStatusByDeviceTypeFromQuery(deviceType, query)
    );
  }

  private getDevicesStatusByDeviceTypeFromQuery(
    deviceType: DeviceType,
    deviceStatusQuery: Map<DeviceType, object>
  ): object {
    if (!deviceStatusQuery.has(deviceType)) {
      throw new Error(`Unknown device type ${deviceType}`);
    }

    return deviceStatusQuery.get(deviceType);
  }

  private createStatusWidgetDataArray(data: object, queries: object): ChartItem[] {
    const statuses: string[] = [
      $localize`Overdue`,
      $localize`Reboot`,
      $localize`Offline`,
      $localize`Online`,
    ];

    return statuses.map((status, i) => ({
      name: status,
      value: data[i].count,
      sourceQuery: {
        deviceFilter: queries[i],
      },
    }));
  }
}
