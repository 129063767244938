import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { DefaultViewService } from "@solarwinds/msp-dashboard-module";

@Injectable({
  providedIn: "root",
})
export class DashboardRedirectService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private defaultViewService: DefaultViewService
  ) {}

  catchNavigatedRoute(event, router) {
    if (event instanceof NavigationStart && router.navigated) {
      sessionStorage.setItem("navigate_url", event.url);
    }
  }

  redirectToNGDashboard() {
    const isDashDefault = this.defaultViewService.isDashboardDefault();
    const notNavigatedRoute = !sessionStorage.getItem("navigate_url");

    if (isDashDefault && notNavigatedRoute) {
      this.router.navigate(["/dash"], {
        relativeTo: this.activatedRoute.parent,
      });
    }
  }
}
