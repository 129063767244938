import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { Observable, zip } from "rxjs";
import { map } from "rxjs/operators";

import { serverCondition, workstationCondition } from "../../consts/device-query.const";
import { DeviceType } from "../../enums/device-type.enum";
import { WidgetDataBuilderService } from "../widget-data-builder.service";

import { MaestroService } from "./maestro/maestro.service";

interface RebootTimeTier {
  daysSinceLastReboot: number;
  deviceType: DeviceType;
  label: string;
}

@Injectable({
  providedIn: "root",
})
export class DeviceLastRebootService {
  readonly rebootTimeTiersInDays: RebootTimeTier[] = [
    {
      daysSinceLastReboot: 30,
      deviceType: DeviceType.SERVER,
      label: $localize`Servers > 30 days`,
    },
    {
      daysSinceLastReboot: 60,
      deviceType: DeviceType.SERVER,
      label: $localize`Servers > 60 days`,
    },
    {
      daysSinceLastReboot: 90,
      deviceType: DeviceType.SERVER,
      label: $localize`Servers > 90 days`,
    },
    {
      daysSinceLastReboot: 60,
      deviceType: DeviceType.WORKSTATION,
      label: $localize`Workstations > 60 days`,
    },
    {
      daysSinceLastReboot: 90,
      deviceType: DeviceType.WORKSTATION,
      label: $localize`Workstations > 90 days`,
    },
  ];

  constructor(
    private maestro: MaestroService,
    private widgetDataBuilder: WidgetDataBuilderService
  ) {}

  public getLastBootTimeServers() {
    const queries: object[] = this.rebootTimeTiersInDays.map(
      ({ daysSinceLastReboot, deviceType }) =>
        this.sinceLastBootTimeQuery(daysSinceLastReboot, deviceType)
    );
    const servers: Observable<object>[] = this.getDevicesStatuses(queries);

    return zip(...servers).pipe(
      map((data: object) => this.createStatusWidgetDataArray(data, queries)),
      map((data: ChartItem[]) =>
        this.widgetDataBuilder.createDataObject("serverLastRebootTimesData", data)
      )
    );
  }

  private getDevicesStatuses(queries: object[]): Observable<object>[] {
    return queries.map((query: Map<DeviceType, object>) => this.maestro.getDevices(query));
  }

  private sinceLastBootTimeQuery(daysSinceLastBootTime: number, deviceType: DeviceType): object {
    return this.lastBootTimeBydeviceTypeQuery(this.daysToHours(daysSinceLastBootTime), deviceType);
  }

  private lastBootTimeBydeviceTypeQuery(
    hoursSinceLastBootTime: number,
    deviceType: DeviceType
  ): object {
    const typeCondition: object =
      deviceType === DeviceType.SERVER ? serverCondition : workstationCondition;
    return {
      criteria: {
        type: "group and",
        children: [
          typeCondition,
          {
            type: "gt",
            name: "last_reboot",
            value: hoursSinceLastBootTime,
          },
        ],
      },
      limit: 1,
      page: 1,
      start: 0,
    };
  }

  private createStatusWidgetDataArray(data: object, queries: object): ChartItem[] {
    return this.rebootTimeTiersInDays.map(({ daysSinceLastReboot, deviceType, label }, i) => ({
      name: label,
      value: data[i].count,
      sourceQuery: {
        deviceFilter: queries[i],
      },
    }));
  }

  private daysToHours(days: number) {
    return days * 24;
  }
}
