import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ViewportOverlayService {
  private state: boolean = false;
  private subject = new BehaviorSubject<boolean>(this.state);

  get viewportOverlayState(): Observable<boolean> {
    return this.subject.asObservable();
  }

  switchViewportOverlay(state: boolean) {
    if (this.state !== state) {
      this.state = state;
      this.subject.next(state);
    }
  }
}
