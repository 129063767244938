import { Directive, Inject, OnDestroy, OnInit } from "@angular/core";
import { PendoSettings, UserSessionService } from "@solarwinds/msp-rmm-user-session";
import isNil from "lodash-es/isNil";
import { from, iif, Observable, of, Subject, Subscription, timer } from "rxjs";
import { mergeMap, takeUntil } from "rxjs/operators";

declare global {
  interface Window {
    pendo: {
      initialize: (settings: PendoSettings) => void;
      isReady: () => boolean;
    };
  }
}

const pendoPollingInterval: number = 5000;

@Directive({
  selector: "[appPendo]",
})
export class PendoDirective implements OnInit, OnDestroy {
  private readonly pendoApiKey: string = "415d8614-41ec-4a2a-4976-a6248bc118ad";
  private readonly stopPolling: Subject<void> = new Subject<void>();
  private readonly stopPolling$: Observable<void> = this.stopPolling.asObservable();
  private readonly pollForWhenPendoIsReady$: Observable<void> = timer(0, pendoPollingInterval).pipe(
    takeUntil(this.stopPolling$),
    mergeMap(() => of(this.shouldInitialisePendo())),
    mergeMap((shouldInit) => iif(() => shouldInit, of(null)))
  );

  private pendoSettings: PendoSettings;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private userSessionService: UserSessionService,
    @Inject("Window") private window: Window
  ) {}

  ngOnInit(): void {
    this.loadPendoScript();

    this.loadPendoSettings();

    this.initialisePendoWhenReady();
  }

  ngOnDestroy(): void {
    this.stopPolling.next();
    this.subscriptions.unsubscribe();
  }

  private loadPendoSettings(): void {
    this.subscriptions.add(
      from(this.userSessionService.getPendoMetadata()).subscribe((result) => {
        this.pendoSettings = result;
      })
    );
  }

  private initialisePendoWhenReady(): void {
    this.pollForWhenPendoIsReady$.subscribe(() => {
      this.window.pendo.initialize(this.pendoSettings);
      this.stopPolling.next();
    });
  }

  private shouldInitialisePendo(): boolean {
    return (
      this.pendoSettings &&
      this.window?.pendo?.isReady &&
      !isNil(this.window?.pendo?.isReady()) &&
      !this.window?.pendo?.isReady()
    );
  }

  private loadPendoScript(): void {
    return ((apiKey) => {
      ((p, e, n, d, o) => {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ["initialize", "identify", "updateOptions", "pageLoad", "isReady"];
        for (w = 0, x = v.length; w < x; ++w) {
          ((m) => {
            o[m] =
              o[m] ||
              // tslint:disable-next-line: only-arrow-functions
              function () {
                o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        }
        y = e.createElement(n);
        y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, "script", "pendo");
    })(this.pendoApiKey);
  }
}
