import { OverlayModule, OverlayRef } from "@angular/cdk/overlay";
import { CommonModule, LocationStrategy } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  AcceptSsaService as AccountWizardAcceptSsaService,
  AccountWizardModule,
  StepService,
} from "@solarwinds/account-wizard-module";
import { AddDeviceWizardFeatureModule } from "@solarwinds/add-device-module";
import {
  CustomChecksCommunityModule,
  CustomChecksLibraryModule,
  CustomChecksLiteModule,
  CustomChecksModule,
} from "@solarwinds/monitoring-checks";
import { MspClientGroupsModule } from "@solarwinds/msp-client-groups-module";
import { MspCommonModule } from "@solarwinds/msp-common";
import {
  EcosystemApiConfigService,
  EcosystemService,
  ECOSYSTEM_API_CONFIG_TOKEN,
  MspEcosystemUiModule,
} from "@solarwinds/msp-ecosystem-ui";
import {
  MspPlatformBarConfig,
  MspPlatformBarModule,
  MSP_PLATFORM_BAR_CONFIGS,
} from "@solarwinds/msp-platform-bar";
import { PlatformBarWithUserModule } from "@solarwinds/msp-platform-bar/pb-with-user-module";
import { ErrorResolverService, ErrorTypeService, I18nEntityPipe } from "@solarwinds/msp-psa-issues";
import { RmmEcosystemModule } from "@solarwinds/msp-rmm-ecosystem";
import { RmmEventDispatcherModule } from "@solarwinds/msp-rmm-event-dispatcher";
import { RmmFeaturePreviewModule } from "@solarwinds/msp-rmm-feature-preview";
import { RmmPlatformBarModule } from "@solarwinds/msp-rmm-platform-bar";
import { UnmonitoredDevicesModule } from "@solarwinds/msp-rmm-unmonitored-devices";
import { RmmUserSessionModule, UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { NavMenuModule } from "@solarwinds/nav-menu";
import { LogLevel, NuiCommonModule, NuiEnvironment, NuiSpinnerModule } from "@solarwinds/nova-bits";
import {
  ApiClientModule,
  ApiConfigProvider,
  ApiTokenProvider,
} from "@solarwinds/quasar-api-client";
import { RmmAdmTokenProviderModule } from "@solarwinds/rmm-adm-host";
import { SatFeatureModule } from "@solarwinds/sat-module";
import { environment } from "environments/environment";

import { AcceptSsaService } from "./accept-ssa.service";
import { AccountWizardOverlayService } from "./account-wizard-overlay.service";
import { AccountWizardSubmissionService } from "./account-wizard-submission.service";
import { AdvancedReportingService } from "./advanced-reporting/advanced-reporting.service";
import { AgentService } from "./agent.service";
import { APP_CONFIG_KEY } from "./app-config";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { QAPIAuthService } from "./auth/qapi-auth.service";
import { QapiApiConfigProvider } from "./auth/qapi-config.service";
import { ClientGroupsComponent } from "./client-groups/client-groups.component";
import { ClientService } from "./client.service";
import { DeviceModelMappers } from "./custom-checks/device-model-mappers";
import { DashboardModule } from "./dashboard/dashboard.module";
import { DialogOverlayComponent } from "./dialog-overlay/dialog-overlay.component";
import { DirectivesModule } from "./directives/directives.module";
import { FileSaveService } from "./file-save.service";
import { FlagOnDirective } from "./flag-on.directive";
import { LegacyRouteComponent } from "./legacy-route/legacy-route.component";
import { ModalService } from "./modal.service";
import { OverlayDirective } from "./overlay.directive";
import { OverlayService } from "./overlay.service";
import { PermittedDirective } from "./permitted.directive";
import { PsaModule } from "./psa";
import { RMM_DASHBOARD_ROOT } from "./rmm-config";
import { RmmShellComponent } from "./rmm-shell/rmm-shell.component";
import { SanitizePipe } from "./sanitize.pipe";
import { SessionExpiringHandlerService } from "./session-expiring-handler.service";
import { SessionKeepAliveHandlerService } from "./session-keep-alive-handler.service";
import { ShellContainerComponent } from "./shell-container/shell-container.component";
import { SiteService } from "./site.service";
import { TidyLocationStrategy } from "./tidy-location.strategy";
import { ViewportOverlayDirective } from "./viewport-overlay.directive";
import { ViewportOverlayService } from "./viewport-overlay.service";
import { WindowManagerService } from "./window-manager.service";

export function getRmmDashboardRoot() {
  return (<any>window).rmmDashboardRoot || "";
}

export function loadSession(userSessionService: UserSessionService) {
  return () => userSessionService.loadSession();
}

export function getEcosystemApiConfigService(
  userSessionService: UserSessionService
): EcosystemApiConfigService {
  const ecosystemSettings = userSessionService.getEcosystemSettings();
  return {
    url: ecosystemSettings ? ecosystemSettings.url : "",
    jwt: ecosystemSettings ? ecosystemSettings.jwt : "",
  };
}

export function mspPlatformBarConfigFactory(): MspPlatformBarConfig {
  return MSP_PLATFORM_BAR_CONFIGS[environment?.environmentType];
}

enableConfigDebugging();

@Injectable()
export class RmmNuiEnvironment extends NuiEnvironment {
  logLevel: LogLevel = LogLevel.info;
}

@NgModule({
  declarations: [
    AppComponent,
    RmmShellComponent,
    PermittedDirective,
    OverlayDirective,
    ShellContainerComponent,
    SanitizePipe,
    ViewportOverlayDirective,
    DialogOverlayComponent,
    LegacyRouteComponent,
    FlagOnDirective,
    ClientGroupsComponent,
  ],
  imports: [
    CommonModule,
    RmmFeaturePreviewModule,
    RmmEventDispatcherModule,
    AccountWizardModule.forRoot(),
    CustomChecksModule,
    CustomChecksLibraryModule,
    CustomChecksCommunityModule,
    UnmonitoredDevicesModule,
    CustomChecksLiteModule,
    MspClientGroupsModule,
    MspEcosystemUiModule,
    NuiCommonModule,
    NuiSpinnerModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NavMenuModule,
    AppRoutingModule,
    DashboardModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule,
    PsaModule,
    AddDeviceWizardFeatureModule,
    ApiClientModule,
    ApiClientModule,
    SatFeatureModule,
    RmmEcosystemModule,
    RmmUserSessionModule,
    RmmAdmTokenProviderModule,
    MspPlatformBarModule.forRoot(mspPlatformBarConfigFactory),
    PlatformBarWithUserModule,
    MspCommonModule,
    RmmPlatformBarModule,
    DirectivesModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadSession,
      deps: [UserSessionService],
      multi: true,
    },
    {
      provide: RMM_DASHBOARD_ROOT,
      useFactory: getRmmDashboardRoot,
    },
    {
      provide: LocationStrategy,
      useClass: TidyLocationStrategy,
    },
    {
      provide: "Window",
      useValue: window,
    },
    {
      provide: OverlayRef,
      useValue: {},
    },
    UserSessionService,
    OverlayService,
    SessionExpiringHandlerService,
    SessionKeepAliveHandlerService,
    WindowManagerService,
    ViewportOverlayService,
    ModalService,
    AcceptSsaService,
    AccountWizardAcceptSsaService,
    AccountWizardOverlayService,
    AccountWizardSubmissionService,
    ClientService,
    SiteService,
    StepService,
    AcceptSsaService,
    AgentService,
    FileSaveService,
    DeviceModelMappers,
    AdvancedReportingService,
    QapiApiConfigProvider,
    {
      provide: ECOSYSTEM_API_CONFIG_TOKEN,
      useFactory: getEcosystemApiConfigService,
      deps: [UserSessionService],
    },
    EcosystemService,
    {
      provide: NuiEnvironment,
      useClass: RmmNuiEnvironment,
    },
    {
      provide: ApiConfigProvider,
      useExisting: QapiApiConfigProvider,
    },
    {
      provide: ApiTokenProvider,
      useExisting: QAPIAuthService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResolverService,
      multi: true,
      deps: [I18nEntityPipe, ErrorTypeService, LOCALE_ID],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function enableConfigDebugging(): void {
  const DEBUG_CONFIG_KEY: string = "debugConfig";

  if (sessionStorage.getItem(DEBUG_CONFIG_KEY) && localStorage.getItem(APP_CONFIG_KEY)) {
    // Allows debugging of config in a live application
    window[APP_CONFIG_KEY] = JSON.parse(localStorage.getItem(APP_CONFIG_KEY));
  } else {
    window[APP_CONFIG_KEY] = environment;
  }
  localStorage.setItem(APP_CONFIG_KEY, JSON.stringify(window[APP_CONFIG_KEY]));

  if (!window[APP_CONFIG_KEY]) {
    throw new Error("Application configuration is missing");
  }
}
