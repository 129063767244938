import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AddDeviceWizardBaseComponent } from "@solarwinds/add-device-module";
import {
  CustomChecksCommunityModule,
  CustomChecksLibraryModule,
  CustomChecksModule,
} from "@solarwinds/monitoring-checks";
import { MdmComponentRoutes } from "@solarwinds/msp-mdm-ui";
import {
  EcosystemExternalPageComponent,
  EcosystemExternalPageGuard,
  IntegrationManagementComponent,
  IntegrationManagementGuard,
  IntegrationResolverService,
  IntegrationSettingsComponent,
  IntegrationSettingsGuard,
  PolicyConfigComponent,
  PolicyListComponent,
  PolicyListGuard,
  RmmEcosystemModule,
} from "@solarwinds/msp-rmm-ecosystem";
import { FeatureFlagGuard } from "@solarwinds/msp-rmm-feature-preview";
import { UnmonitoredDevicesModule } from "@solarwinds/msp-rmm-unmonitored-devices";
import { routes as satRoutes } from "@solarwinds/sat-module";

import { ClientGroupsComponent } from "./client-groups/client-groups.component";
import { dashboardRoutes } from "./dashboard/dashboard-routes";
import { FeatureFlag } from "./feature-flag.enum";
import { LegacyRouteComponent } from "./legacy-route/legacy-route.component";

export function loadCustomChecksLibraryModule() {
  return CustomChecksLibraryModule;
}

export function loadCustomChecksWizardModule() {
  return CustomChecksModule;
}

export function loadCustomChecksCommunityModule() {
  return CustomChecksCommunityModule;
}

export function loadUnmonitoredDevicesModule() {
  return UnmonitoredDevicesModule;
}

export function loadRmmEcosystemModule() {
  return RmmEcosystemModule;
}

const routes: Routes = [
  ...dashboardRoutes,
  {
    path: "add-device",
    component: AddDeviceWizardBaseComponent,
  },
  {
    path: "library",
    loadChildren: loadCustomChecksLibraryModule,
  },
  {
    path: "wizard",
    loadChildren: loadCustomChecksWizardModule,
  },
  {
    path: "community-library",
    loadChildren: loadCustomChecksCommunityModule,
  },
  {
    path: "unmonitored-devices",
    loadChildren: loadUnmonitoredDevicesModule,
  },
  {
    path: "department-groups",
    component: ClientGroupsComponent,
    canActivate: [FeatureFlagGuard],
    data: { featureFlag: "CO_MANAGED_KT_RMM32003_CLIENT_GROUPS" },
  },
  {
    path: "client-groups",
    component: ClientGroupsComponent,
    canActivate: [FeatureFlagGuard],
    data: { featureFlag: "CO_MANAGED_KT_RMM32003_CLIENT_GROUPS" },
  },
  {
    path: "integration-management",
    component: IntegrationManagementComponent,
    canActivate: [FeatureFlagGuard, IntegrationManagementGuard],
    data: { featureFlag: FeatureFlag.EcosystemIntegration },
  },
  {
    path: "integration-policy-list/:integrationId",
    component: PolicyListComponent,
    canActivate: [FeatureFlagGuard, PolicyListGuard],
    data: { featureFlag: FeatureFlag.EcosystemIntegration },
  },
  {
    path: "integration-policy-config/:integrationId/:policyId",
    component: PolicyConfigComponent,
    canActivate: [FeatureFlagGuard, PolicyListGuard],
    data: { featureFlag: FeatureFlag.EcosystemIntegration },
  },
  {
    path: `integration-external/:integrationId/:menuId`,
    component: EcosystemExternalPageComponent,
    canActivate: [FeatureFlagGuard, EcosystemExternalPageGuard],
    data: { featureFlag: FeatureFlag.EcosystemIntegration },
  },
  {
    path: `integration-settings/:integrationId`,
    component: IntegrationSettingsComponent,
    canActivate: [FeatureFlagGuard, IntegrationSettingsGuard],
    resolve: {
      integration: IntegrationResolverService,
    },
    data: {
      featureFlag: FeatureFlag.EcosystemIntegration,
    },
  },
  {
    path: "",
    pathMatch: "full",
    component: LegacyRouteComponent,
    data: {
      url: "default.php",
      view: "devices",
      handleSessionTimeoutWindowPopup: true,
      overlay: false,
      viewportOverlay: false,
    },
  },
  {
    path: "filters",
    component: LegacyRouteComponent,
    data: {
      url: "default.php",
      view: "filters",
      overlay: true,
      viewportOverlay: true,
    },
  },
  {
    path: "assettracking",
    component: LegacyRouteComponent,
    data: {
      url: "assettracking/",
      forwardQueryParams: ["clientId", "siteId", "asDeviceId"],
      shouldNotifyQueryChange: true,
    },
  },
  {
    path: "wallchart",
    component: LegacyRouteComponent,
    data: {
      url: "wallchart/",
      shouldReload: true,
    },
  },
  {
    path: "netpath",
    component: LegacyRouteComponent,
    data: {
      url: "netpath/",
      forwardQueryParams: ["graph"],
    },
  },
  {
    path: "advancedreporting",
    component: LegacyRouteComponent,
    data: {
      url: "advancedreporting/",
    },
  },
  // ADM Routes
  ...MdmComponentRoutes.routes,

  // SAT routes to workaround lazy loading
  ...satRoutes,
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
