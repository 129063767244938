import { PathLocationStrategy } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class TidyLocationStrategy extends PathLocationStrategy {
  replaceState(state: any, title: string, url: string, queryParams: string) {
    url = url.replace(/\?forcewrap=?/, "");
    super.replaceState(state, title, url, queryParams);
  }
}
