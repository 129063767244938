import { Routes } from "@angular/router";

import { MonitoredDevicesComponent } from "./monitored-devices.component";

export const monitoredDevicesRoutes: Routes = [
  {
    path: "monitored-devices",
    component: MonitoredDevicesComponent,
    data: {
      url: "",
      view: "dashboard",
    },
  },
];
