import { Component, OnDestroy, OnInit } from "@angular/core";
import { DashboardPermissionHandlerService } from "@solarwinds/msp-dashboard-module";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";

import { DashboardDataHandler } from "./service/dashboard-data-handler.service";
import { PermissionVerificationService } from "./service/permission-verification.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dashboardWidgetsData$ = new Observable();
  private refreshDashboardWidgets$ = new BehaviorSubject<void>(undefined);
  private permissionsSubscription = new Subscription();

  constructor(
    private dashboardDataHandler: DashboardDataHandler,
    private dashboardPermissionVerification: PermissionVerificationService,
    private dashboardPermission: DashboardPermissionHandlerService
  ) {}

  ngOnInit(): void {
    this.dashboardWidgetsData$ = this.refreshDashboardWidgets$
      .asObservable()
      .pipe(switchMap(() => this.dashboardDataHandler.dashboardWidgetData()));

    this.permissionsSubscription = this.dashboardPermissionVerification
      .verify()
      .subscribe((permissions) => {
        this.dashboardPermission.permissions(permissions);
      });
  }

  ngOnDestroy(): void {
    this.permissionsSubscription.unsubscribe();
    this.refreshDashboardWidgets$.complete();
  }

  refreshDashboard(): void {
    this.refreshDashboardWidgets$.next(null);
  }
}
