export enum DeviceType {
  SERVER = "server",
  WORKSTATION = "workstation",
  ALL = "all",
}

export enum DeviceTypes {
  SERVER = "server",
  DESKTOP = "desktop",
  LAPTOP = "laptop",
}
