import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { UserSessionService } from "@solarwinds/msp-rmm-user-session";

@Directive({
  selector: "[appPermitted]",
})
export class PermittedDirective {
  constructor(
    private userSessionService: UserSessionService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appPermitted(permission: string) {
    this.userSessionService.isPermitted(permission).then((permitted) => {
      if (permitted) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
    this.viewContainer.clear();
  }
}
