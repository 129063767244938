import { Routes } from "@angular/router";

import { ActiveIssuesComponent } from "./active-issues.component";

export const activeIssuesRoutes: Routes = [
  {
    path: "active-issues",
    component: ActiveIssuesComponent,
    data: {
      url: "",
      view: "dashboard",
    },
  },
];
