import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { MaestroSettings, UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { Observable, of } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MaestroService {
  constructor(
    private http: HttpClient,
    private sessionService: UserSessionService,
    private featurePreviewService: FeaturePreviewService
  ) {}

  getCount(query: object): Observable<number> {
    return this.getDevices(query).pipe(pluck("count"));
  }

  getV2Count(query: object): Observable<number> {
    return this.getV2Devices(query).pipe(pluck("hits", "total", "value"));
  }

  getDevices(query: object): Observable<object> {
    return this.getMaestroSettings().pipe(
      mergeMap(({ url, jwt }) => this.maestroCall(url, jwt, query))
    );
  }

  getV2Devices(query: object): Observable<object | unknown> {
    return this.getMaestroSettings().pipe(
      mergeMap(({ url, jwt }) => this.maestro2Call(url, jwt, query)),
      catchError((err) => err)
    );
  }

  getV2DevicesBypass(query: object): Observable<object | unknown> {
    return fromPromise(this.sessionService.getMaestro2Settings(true)).pipe(
      mergeMap(({ url, jwt }) => this.maestro2Call(url, jwt, query)),
      catchError((err) => err)
    );
  }

  private maestroCall(url, jwt, query: object): Observable<object> {
    return this.getMaestroURI().pipe(
      mergeMap((uri: String) =>
        this.http
          .post(url + uri, query, {
            headers: new HttpHeaders({
              Authorization: `Bearer ${jwt}`,
            }),
          })
          .pipe(
            mergeMap((response) => {
              response["count"] = parseInt(response["count"], 10);
              return of(response);
            }),
            catchError(() => this.legacyRmmServerCall(query))
          )
      )
    );
  }

  private maestro2Call(url, jwt, query: object): Observable<object> {
    return this.http
      .post(url + "/v2/api/devices/search", query, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${jwt}`,
        }),
      })
      .pipe(mergeMap((res) => of(res)));
  }

  private legacyRmmServerCall(query: object): Observable<object> {
    return this.http.post("api/devicefilters/devices", query).pipe(
      mergeMap((response) => {
        this.renameKey(response, "totalRecords", "count");
        this.renameKey(response, "data", "elements");
        return of(response);
      })
    );
  }

  private getMaestroSettings(): Observable<MaestroSettings> {
    return this.featurePreviewService.isOn("NEW_DASH_MAESTRO_V2").pipe(
      map((flagOn: boolean) => {
        if (flagOn) {
          return this.sessionService.getMaestro2Settings();
        }
        return this.sessionService.getMaestroSettings();
      }),
      mergeMap((settings: Promise<MaestroSettings>) => settings)
    );
  }

  private getMaestroURI(): Observable<String> {
    return this.featurePreviewService.isOn("NEW_DASH_MAESTRO_V2").pipe(
      map((v2On) => {
        if (v2On) {
          return "/v1/simpledevices";
        } else {
          return "simpledevices";
        }
      })
    );
  }

  private renameKey(obj, oldKey, newKey) {
    delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey];
  }
}
