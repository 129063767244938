import { HttpClient, HttpResponse } from "@angular/common/http";
import { ElementRef, Injectable } from "@angular/core";
import { DeviceOses } from "@solarwinds/account-wizard-module";
import { Observable } from "rxjs";

import { FileSaveService } from "./file-save.service";

export interface AgentVersionResponse {
  versions: {
    windows: {
      rcVersion: string;
      gaVersion: string;
      previewVersion: string;
    };
    osx: {
      rcVersion: string;
      gaVersion: string;
    };
  };
}

interface AgentPrefixes {
  key: string;
  prefix: string;
}

@Injectable()
export class AgentService {
  private readonly deviceOsKeyMap = new Map<DeviceOses, AgentPrefixes>([
    [DeviceOses.MAC, { key: "osx", prefix: "MACOS" }],
    [DeviceOses.WINDOWS, { key: "windows", prefix: "WIN" }],
  ]);

  private readonly baseAgentDownloadUrl =
    "/dashboard/data_processor.php?function=download_agent_distribution_package";

  constructor(private http: HttpClient, private fileSaveService: FileSaveService) {}

  getCurrentAgentVersionForOs({ versions }: AgentVersionResponse, deviceOs: DeviceOses): string {
    if (!this.deviceOsKeyMap.has(deviceOs)) {
      throw new Error(`Unknown device os: ${deviceOs}`);
    }

    const key = this.deviceOsKeyMap.get(deviceOs).key;
    const prefix = this.deviceOsKeyMap.get(deviceOs).prefix;

    if (!versions[key] || !versions[key].gaVersion) {
      throw new Error(`No ${key} version types`);
    }

    return `${prefix}_${versions[key].gaVersion}`;
  }

  getAvailableVersions(): Observable<AgentVersionResponse> {
    return this.http.get<AgentVersionResponse>("../api/agent/versions");
  }

  buildAgentPackage(clientId: number, siteId: number, agentVersion: string): Observable<string> {
    return this.http.get(this.getBuildAgentUrl(clientId, siteId, agentVersion), {
      responseType: "text",
    });
  }

  downloadAgentPackage(clientId: number, siteId: number, agentVersion: string, link: ElementRef) {
    this.http
      .get(this.getDownloadAgentUrl(clientId, siteId, agentVersion), {
        observe: "response",
        responseType: "arraybuffer",
      })
      .subscribe((response) => {
        this.fileSaveService.saveAs(response.body, this.getFileName(response), link);
      });
  }

  private getFileName(res: HttpResponse<ArrayBuffer>): string {
    const contentDisposition = res.headers.get("content-disposition") || "";
    const matches = /filename=["']?([^"']+)["']?;?/gi.exec(contentDisposition);
    if (matches && matches.length > 1) {
      return (matches[1] || "untitled").trim();
    }

    return "untitled";
  }

  private getBuildAgentUrl(clientId: number, siteId: number, agentVersion: string): string {
    return `${this.baseAgentDownloadUrl}&endcustomerid=${clientId}&siteid=${siteId}&agent_version=${agentVersion}&mode=build`;
  }

  private getDownloadAgentUrl(clientId: number, siteId: number, agentVersion: string): string {
    return `${this.baseAgentDownloadUrl}&endcustomerid=${clientId}&siteid=${siteId}&agent_version=${agentVersion}&mode=downloadrw`;
  }
}
