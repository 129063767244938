import { Injectable } from "@angular/core";
import { ClientGroupsModulePermissionsService } from "@solarwinds/msp-client-groups-module";
import { Observable, zip } from "rxjs";
import { map, share } from "rxjs/operators";

import { PermissionHandlerService } from "../../dashboard/service/feature-Install-status/permission-handler.service";
import { ClientGroupsPermissions } from "../client-groups-permssions.enum";

@Injectable({
  providedIn: "root",
})
export class ClientGroupsPermissionsHandlerService {
  constructor(
    private permission: PermissionHandlerService,
    private modulePermission: ClientGroupsModulePermissionsService
  ) {}

  permissionCheck(): Observable<void> {
    const createClientGroup = this.permission
      .isFeaturePermitted(ClientGroupsPermissions.createClientGroup)
      .pipe();
    const readClientGroup = this.permission
      .isFeaturePermitted(ClientGroupsPermissions.readClientGroup)
      .pipe();
    const updateClientGroup = this.permission
      .isFeaturePermitted(ClientGroupsPermissions.updateClientGroup)
      .pipe();
    const deleteClientGroup = this.permission
      .isFeaturePermitted(ClientGroupsPermissions.deleteClientGroup)
      .pipe();

    return zip(createClientGroup, readClientGroup, updateClientGroup, deleteClientGroup).pipe(
      map((result) => this.setPermission(result), share())
    );
  }

  private setPermission(result) {
    const permissionObject = {
      createClientGroup: result[0],
      readClientGroup: result[1],
      updateClientGroup: result[2],
      deleteClientGroup: result[3],
    };

    this.modulePermission.permissions(permissionObject);
  }
}
