import { Injectable } from "@angular/core";
import { CustomChecksConfig } from "@solarwinds/monitoring-checks";
import { AverySettings, UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CustomChecksConfigHandlerService {
  constructor(private userSessionService: UserSessionService) {}

  getConfig(): Observable<CustomChecksConfig> {
    return from(this.userSessionService.getAverySettings()).pipe(
      map((settings: AverySettings) => ({
        auth: settings.authString,
        url: settings.url,
        userName: settings.userName,
      }))
    );
  }
}
