import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ModalService } from "../modal.service";
import { RouteItem } from "../route-item";

@Component({
  selector: "app-dialog-overlay",
  templateUrl: "./dialog-overlay.component.html",
  styleUrls: ["./dialog-overlay.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DialogOverlayComponent implements OnInit, OnDestroy {
  public isActive: boolean;
  public routeItem: RouteItem;

  @HostBinding("style.z-index") zIndex: number = -1;
  private readonly urlBase: string = "dashboard_modals.php";
  private destroySubject = new Subject<void>();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.getView.pipe(takeUntil(this.destroySubject)).subscribe(
      (view) =>
        (this.routeItem = {
          url: this.urlBase,
          queryParams: {
            view: view,
          },
        })
    );

    this.modalService.isDialogActive.pipe(takeUntil(this.destroySubject)).subscribe((isActive) => {
      this.isActive = isActive;
      this.zIndex = isActive ? 201 : -1;
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.unsubscribe();
  }
}
