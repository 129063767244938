import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";

@Directive({
  selector: "[appFlagOn]",
})
export class FlagOnDirective {
  constructor(
    private featurePreviewService: FeaturePreviewService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appFlagOn(featureName: string) {
    this.featurePreviewService.isOn(featureName).subscribe((flagIsOn) => {
      if (flagIsOn) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
    this.viewContainer.clear();
  }
}
