import { Injectable } from "@angular/core";
import { ClearCheckSettingsService } from "@solarwinds/msp-active-issues";
import { ClearCheckSettings, UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ActiveIssuesUpdateCheck } from "./active-issues-permissions.enum";

@Injectable({
  providedIn: "root",
})
export class ActiveIssuesPermissionsService {
  constructor(
    private userSessionService: UserSessionService,
    private clearCheckPermissionsService: ClearCheckSettingsService
  ) {}

  isPermitted(): Observable<boolean> {
    return this.isCheckUsagePermitted().pipe(map((p: boolean[]) => p[0] && p[1]));
  }

  clearChecksPermissions(settings: ClearCheckSettings): void {
    this.clearCheckPermissionsService.settingsState(settings);
  }

  private isCheckUsagePermitted(): Observable<boolean[]> {
    return combineLatest([
      this.userSessionService.isPermitted(ActiveIssuesUpdateCheck.executeCheck),
      this.userSessionService.isPermitted(ActiveIssuesUpdateCheck.executeCheckClear),
    ]);
  }
}
