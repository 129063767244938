import { Directive, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { ViewportOverlayService } from "./viewport-overlay.service";

@Directive({
  selector: "[appViewportOverlay]",
})
export class ViewportOverlayDirective implements OnInit, OnDestroy {
  @HostBinding("class.viewport-overlay") private overlayState: boolean;
  private subscription: Subscription;

  constructor(private viewportOverlayService: ViewportOverlayService) {}

  ngOnInit(): void {
    this.subscription = this.viewportOverlayService.viewportOverlayState.subscribe((state) => {
      this.overlayState = state;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
