import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-client-groups",
  templateUrl: "./client-groups.component.html",
  styleUrls: ["./client-groups.component.scss"],
})
export class ClientGroupsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
