import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRequest, rmmRouterEndpoint, rpc } from "@solarwinds/msp-rmm-feature-preview";

export interface SiteCreationResponse {
  result: {
    newSiteId: number;
  };
}

export interface CreateSiteData {
  siteId: number;
  siteName: string;
  clientId: number;
}

@Injectable()
export class SiteService {
  private loadingPromise: Promise<SiteCreationResponse>;

  constructor(private http: HttpClient) {}

  public createSite(siteName: string, clientId: number): Promise<SiteCreationResponse> {
    const request: ApiRequest<Array<CreateSiteData>> = {
      action: "site",
      method: "save",
      data: [
        {
          siteId: 0,
          siteName,
          clientId,
        },
      ],
      type: rpc,
      tid: 1,
    };

    return (this.loadingPromise = new Promise((resolve) => {
      this.http.post<SiteCreationResponse>(rmmRouterEndpoint, request, {}).subscribe((data) => {
        resolve(data);
      });
    }));
  }
}
