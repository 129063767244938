import {
  DeviceFeature,
  DeviceFeatureDisplayStatus,
  DeviceFeatureStatus,
} from "../enums/device-feature.enum";

import { featureDisplayStatusQuery, featureStatusQuery } from "./device-query-v2.const";

const features = [DeviceFeature.WEB_PROTECTION, DeviceFeature.WEB_PROTECTION_ECHO];

export const installFailedConditionQuery = featureStatusQuery(
  DeviceFeatureStatus.INSTALL_FAILED,
  features
);

export const pendingConditionQuery = featureStatusQuery(DeviceFeatureStatus.PENDING, features);

export const rebootRequiredConditionQuery = featureDisplayStatusQuery(
  DeviceFeatureDisplayStatus.DISPLAY_STATUS_REBOOT_REQUIRED,
  features
);

export const notCompatibleConditionQuery = featureStatusQuery(
  DeviceFeatureStatus.NOT_COMPATIBLE,
  features
);

export const activeConditionQuery = featureStatusQuery(
  DeviceFeatureStatus.INSTALLED_ACTIVE,
  features
);
