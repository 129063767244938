import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { Observable, zip } from "rxjs";
import { map } from "rxjs/operators";

import { deviceByTypesQueryMapping } from "../../consts/device-query.const";
import { DeviceTypes } from "../../enums/device-type.enum";
import { WidgetDataBuilderService } from "../widget-data-builder.service";

import { DevicesTypeQuery } from "./devices";
import { MaestroService } from "./maestro/maestro.service";

@Injectable({
  providedIn: "root",
})
export class DeviceTypesService {
  private readonly deviceByTypeQueryMap = deviceByTypesQueryMapping;

  constructor(
    private maestro: MaestroService,
    private widgetDataBuilder: WidgetDataBuilderService
  ) {}

  getDeviceTypes(): Observable<any> {
    const queries: any[] = this.getAllDeviceTypesQuries();
    const types: Observable<object>[] = this.getAllDeviceTypes(queries);

    return zip(...types).pipe(
      map((data: DevicesTypeQuery[]) => this.createDeviceTypesWidgetData(data, queries)),
      map((data: ChartItem[]) => this.widgetDataBuilder.createDataObject("deviceTypesData", data))
    );
  }

  private getAllDeviceTypes(queries: any[]): Observable<object>[] {
    return queries.map((query: Map<DeviceTypes, object>) => this.maestro.getDevices(query));
  }

  private getAllDeviceTypesQuries(): object[] {
    return this.deviceTypesEnum().map((type: DeviceTypes) => this.getDeviceByTypesQuery(type));
  }

  private deviceTypesEnum(): string[] {
    return Object.keys(DeviceTypes).map((deviceType) => deviceType.toLowerCase());
  }

  private getDeviceByTypesQuery(deviceType: DeviceTypes): object {
    if (!this.deviceByTypeQueryMap.has(deviceType)) {
      throw new Error(`Unknown device type ${deviceType}`);
    }

    return this.deviceByTypeQueryMap.get(deviceType);
  }

  private createDeviceTypesWidgetData(data: DevicesTypeQuery[], queries: string[]): ChartItem[] {
    return Object.values(DeviceTypes).map((type: DeviceTypes, i: number) => ({
      name: this.deviceTypeToString(type),
      value: data[i].count,
      sourceQuery: {
        deviceFilter: queries[i],
      },
    }));
  }

  private deviceTypeToString(deviceType: DeviceTypes) {
    if (deviceType === DeviceTypes.DESKTOP) {
      return $localize`Desktops`;
    }

    if (deviceType === DeviceTypes.SERVER) {
      return $localize`Servers`;
    }

    if (deviceType === DeviceTypes.LAPTOP) {
      return $localize`Laptops`;
    }

    return $localize`Servers`;
  }
}
