import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";

import { OverlayService } from "./overlay.service";

@Directive({
  selector: "[appOverlay]",
})
export class OverlayDirective implements OnInit, OnDestroy {
  private subscription: Subscription;
  private isRendered: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private overlayService: OverlayService
  ) {}

  ngOnInit() {
    this.subscription = this.overlayService.overlayState.subscribe((state) => {
      if (!this.isRendered && state) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isRendered = true;
      } else if (this.isRendered && !state) {
        this.viewContainer.clear();
        this.isRendered = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
