import { Injectable } from "@angular/core";
import { UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PermissionHandlerService {
  constructor(private userSessionService: UserSessionService) {}

  isFeaturePermitted(permission: string): Observable<boolean> {
    return from(this.userSessionService.isPermitted(permission));
  }
}
