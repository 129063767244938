import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NavMenuModule } from "@solarwinds/nav-menu";

import { LegacyDashboardNavItemComponent } from "./legacy-dashboard-nav-item/legacy-dashboard-nav-item.component";
import { NavItemsComponent } from "./nav-items/nav-items.component";
import { NavComponent } from "./nav/nav.component";

@NgModule({
  imports: [CommonModule, NavMenuModule],
  declarations: [NavComponent, NavItemsComponent, LegacyDashboardNavItemComponent],
  exports: [NavComponent, NavItemsComponent],
})
export class NavModule {}
