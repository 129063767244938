import { Injectable } from "@angular/core";
import { RmmEventType } from "@solarwinds/msp-rmm-event-dispatcher";

import { ViewType } from "../modal.service";

@Injectable({
  providedIn: "root",
})
export class PlatformBarEventUtilityService {
  private readonly platformBarEventTypes: Set<RmmEventType> = new Set([
    RmmEventType.openAbout,
    RmmEventType.openSupportCentre,
  ]);

  filterForPlatformBarEvents(view: string, eventType: RmmEventType): boolean {
    return view !== ViewType.dashboard && this.platformBarEventTypes.has(eventType);
  }

  mapEventTypeToViewType(eventType: RmmEventType): ViewType | never {
    switch (eventType) {
      case RmmEventType.openSupportCentre:
        return ViewType.support;
      case RmmEventType.openAbout:
        return ViewType.about;
      default:
        throw new Error(`Unsupported event type (${eventType})`);
    }
  }
}
