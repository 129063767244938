import { ComponentType, Overlay } from "@angular/cdk/overlay";
import { Injectable, Injector } from "@angular/core";
import { PsaDialogService } from "@solarwinds/msp-psa-common";

import { NovaDialogOverlayService } from "../../nova-dialog-overlay.service";

@Injectable()
export class PsaOverlayService extends NovaDialogOverlayService {
  constructor(injector: Injector, overlay: Overlay, private psaDialogService: PsaDialogService) {
    super(injector, overlay);
  }

  open<T>(component: ComponentType<T>): T {
    const componentInstance: T = super.open(component);

    this.waitForClose();

    return componentInstance;
  }

  private waitForClose(): void {
    this.psaDialogService.closed.subscribe(() => this.overlayRef.dispose());
  }
}
