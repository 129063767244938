import { DeviceStatus } from "../enums/device-status.enum";
import { DeviceType } from "../enums/device-type.enum";

import { serverCondition, workstationCondition } from "./device-query.const";

const offlineCondition = {
  type: "group or",
  children: [
    {
      type: "eq",
      name: "device_status",
      value: DeviceStatus.OFFLINE,
    },
  ],
};

const overdueCondition = {
  type: "group or",
  children: [
    {
      type: "eq",
      name: "device_status",
      value: DeviceStatus.OVERDUE,
    },
  ],
};

const rebootCondition = {
  type: "group or",
  children: [
    {
      type: "eq",
      name: "device_status",
      value: DeviceStatus.REBOOT,
    },
  ],
};

const onlineCondition = {
  type: "group or",
  children: [
    {
      type: "eq",
      name: "device_status",
      value: DeviceStatus.ONLINE,
    },
  ],
};

export const offlineDevicesByTypeQuery = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [offlineCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [offlineCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);
export const overdueDevicesByTypeQuery = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [overdueCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [overdueCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);
export const rebootDevicesByTypeQuery = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [rebootCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [rebootCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);
export const onlineDevicesByTypeQuery = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [onlineCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [onlineCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);
