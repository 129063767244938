import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActiveIssuesService } from "@solarwinds/msp-active-issues";
import { RouteMonitorService } from "@solarwinds/msp-rmm-route-monitor";
import { UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { from, Observable, Subscription } from "rxjs";

import { QAPIAuthService } from "../../auth/qapi-auth.service";
import { DeviceFilterQueryService } from "../service/device/device-filter-query.service";
import { DevicesService } from "../service/device/devices.service";

import { ActiveIssuesPermissionsService } from "./active-issues-permissions.service";

@Component({
  selector: "app-active-issues",
  templateUrl: "./active-issues.component.html",
  styleUrls: ["./active-issues.component.scss"],
})
export class ActiveIssuesComponent implements OnDestroy, OnInit {
  @Input() headingValue: string = $localize`:@@active_issues_title:Active issues`;

  readonly isPermittedToViewActiveIssues: Observable<boolean> = this.permissions.isPermitted();

  private readonly qApiAuthSubscription: Subscription = new Subscription();

  private subscriptions: Subscription = new Subscription();

  constructor(
    private permissions: ActiveIssuesPermissionsService,
    private qApiAuth: QAPIAuthService,
    private userSessionService: UserSessionService,
    private activeIssuesService: ActiveIssuesService,
    private deviceFilterQueryService: DeviceFilterQueryService,
    private deviceService: DevicesService,
    private routeMonitorService: RouteMonitorService
  ) {
    this.qApiAuthSubscription = this.qApiAuth.token.subscribe((token) => token);
  }

  ngOnInit(): void {
    this.subscriptions.add(this.qApiAuthSubscription);

    this.subscriptions.add(
      from(this.userSessionService.getClearCheckSettings()).subscribe((p) => {
        this.permissions.clearChecksPermissions(p);
      })
    );

    this.subscriptions.add(
      this.activeIssuesService.viewInDevicesState$.subscribe((ID) => {
        this.deviceFilterQueryService.fireSelectByQuery(
          this.deviceService.getDeviceByGUIDQuery(ID)
        );
        this.routeMonitorService.navigateByView("devices");
      })
    );

    this.subscriptions.add(
      from(this.userSessionService.getTimeZone()).subscribe((timeZone) => {
        this.activeIssuesService.setTimeZone(timeZone);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
