import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class OverlayService {
  private state: boolean = false;
  private subject = new BehaviorSubject<boolean>(this.state);

  constructor() {}

  get overlayState(): Observable<boolean> {
    return this.subject.asObservable();
  }

  switchOverlay(state: boolean) {
    if (this.state !== state) {
      this.state = state;

      this.subject.next(state);
    }
  }
}
