import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { Observable, zip } from "rxjs";
import { flatMap, last, map } from "rxjs/operators";

import {
  activeConditionQuery,
  installFailedConditionQuery,
  notCompatibleConditionQuery,
  pendingConditionQuery,
  rebootRequiredConditionQuery,
} from "../../consts/backup-feature-install-status-query.const";
import { DeviceType } from "../../enums/device-type.enum";
import { MaestroVersion } from "../../enums/maestro-versions.enum";
import { WidgetDataBuilderService } from "../widget-data-builder.service";

import { FeatureInstallCommonService } from "./feature-install-common.service";
import { FeaturePermissions } from "./feature-permissions.enum";
import { PermissionHandlerService } from "./permission-handler.service";

@Injectable({
  providedIn: "root",
})
export class BackupFeatureInstallStatusService {
  private backupInstallStatusQueries: Map<DeviceType, object>[] = [
    installFailedConditionQuery,
    pendingConditionQuery,
    rebootRequiredConditionQuery,
    notCompatibleConditionQuery,
    activeConditionQuery,
  ];

  constructor(
    private featureInstall: FeatureInstallCommonService,
    private widgetDataBuilder: WidgetDataBuilderService,
    private permissionHandler: PermissionHandlerService
  ) {}

  getBackupFeatureInstallStatus(): Observable<object> {
    const queries = this.featureInstall.getStatusQueries(
      DeviceType.ALL,
      this.backupInstallStatusQueries
    );
    const statuses: Observable<object>[] = this.featureInstall.getStatuses(
      queries,
      MaestroVersion.V1
    );

    const permission: Observable<boolean> = this.permissionHandler.isFeaturePermitted(
      FeaturePermissions.mob
    );
    return this.getWidgetData(queries, statuses, permission);
  }

  getWidgetData(
    queries: object[],
    statuses: Observable<object>[],
    permission: Observable<boolean>
  ): Observable<object> {
    return permission.pipe(
      last(),
      flatMap((permit) =>
        permit
          ? this.getData(queries, statuses)
          : this.widgetDataBuilder.fakeDataObject("backupFeatureInstallStatusData")
      )
    );
  }

  private getData(queries, statuses): Observable<object> {
    return zip(...statuses).pipe(
      map((data: object) =>
        this.featureInstall.createFeatureInstallStatusWidgetData(data, queries)
      ),
      map((data: ChartItem[]) =>
        this.widgetDataBuilder.createDataObject("backupFeatureInstallStatusData", data)
      )
    );
  }
}
