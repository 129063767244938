export const unmonitoredNetworkDevicesQuery = (timestamp: number, searchTerms: any[]) => ({
  size: 0,
  query: {
    bool: {
      must: searchTerms,
      must_not: [{ term: { manufacturer: "" } }],
      filter: {
        nested: {
          path: "deviceNetworkInterfacesList",
          ignore_unmapped: true,
          query: {
            bool: {
              must: [
                {
                  exists: { field: "deviceNetworkInterfacesList.connectedToNetworkID" },
                },
                {
                  bool: {
                    should: [
                      {
                        range: {
                          "deviceNetworkInterfacesList.dateConnectionStateLastChanged": {
                            gte: timestamp,
                          },
                        },
                      },
                      {
                        match: {
                          "deviceNetworkInterfacesList.connectionState": "CONNECTED",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
  aggs: {
    Printers: {
      sum_bucket: {
        buckets_path: "PrintersFilter._count",
      },
    },
    Switches: {
      sum_bucket: {
        buckets_path: "SwitchesFilter._count",
      },
    },
    Routers: {
      sum_bucket: {
        buckets_path: "RoutersFilter._count",
      },
    },
    Firewalls: {
      sum_bucket: {
        buckets_path: "FirewallsFilter._count",
      },
    },
    Other: {
      sum_bucket: {
        buckets_path: "OtherFilter._count",
      },
    },
    Workstations: {
      sum_bucket: {
        buckets_path: "WorkstationsFilter._count",
      },
    },
    Servers: {
      sum_bucket: {
        buckets_path: "ServersFilter._count",
      },
    },
    Laptops: {
      sum_bucket: {
        buckets_path: "LaptopsFilter._count",
      },
    },
    Windows: {
      sum_bucket: {
        buckets_path: "WindowsFilter._count",
      },
    },
    MacOsx: {
      sum_bucket: {
        buckets_path: "MacOsxFilter._count",
      },
    },
    Linux: {
      sum_bucket: {
        buckets_path: "LinuxFilter._count",
      },
    },
    SwitchesFilter: {
      filters: {
        filters: {
          Switch: { match: { type: "Switch" } },
          NetworkSwitch: { match: { type: "NetworkSwitch" } },
        },
      },
    },
    PrintersFilter: {
      filters: {
        filters: {
          Printers: { match: { type: "Printer" } },
        },
      },
    },
    RoutersFilter: {
      filters: {
        filters: {
          Router: { match: { type: "Router" } },
          "Wired router": { match: { type: "Wired router" } },
        },
      },
    },
    FirewallsFilter: {
      filters: {
        filters: {
          Firewall: { match: { type: "Firewall" } },
        },
      },
    },
    OtherFilter: {
      filters: {
        filters: {
          Phone: { match: { type: "Phone" } },
          Router: { match: { type: "Gaming system" } },
          "Wired router": { match: { type: "Home and office automation" } },
          "Wireless router": { match: { type: "NAS/SAN" } },
          "Set-top box": { match: { type: "Set-top box" } },
          Tablet: { match: { type: "Tablet" } },
          UPS: { match: { type: "UPS" } },
          "Virtual machine": { match: { type: "Virtual machine" } },
          "Wireless access point": { match: { type: "Wireless access point" } },
        },
      },
    },
    WorkstationsFilter: {
      filters: {
        filters: {
          Workstation: { match: { type: "Workstation" } },
        },
      },
    },
    ServersFilter: {
      filters: {
        filters: {
          Server: { match: { type: "Server" } },
        },
      },
    },
    LaptopsFilter: {
      filters: {
        filters: {
          Laptop: { match: { type: "Laptop" } },
        },
      },
    },
    WindowsFilter: {
      filters: {
        filters: {
          Windows: { match: { operatingSystem: "Windows" } },
        },
      },
    },
    MacOsxFilter: {
      filters: {
        filters: {
          MacOsx: { match: { operatingSystem: "OSX" } },
        },
      },
    },
    LinuxFilter: {
      filters: {
        filters: {
          Linux: { match: { operatingSystem: "Linux" } },
        },
      },
    },
  },
});

export const BASE_SEARCH_TERMS: any[] = [
  {
    exists: {
      field: "hasRmmAgent",
    },
  },
  {
    match: {
      hasRmmAgent: false,
    },
  },
  {
    match: {
      monitored: false,
    },
  },
  {
    exists: {
      field: "manufacturer",
    },
  },
];
