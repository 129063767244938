import { Injectable } from "@angular/core";
import { PermissionsHandlerService } from "@solarwinds/add-device-module";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { Observable, zip } from "rxjs";
import { map, share } from "rxjs/operators";

import { DashboardPermissions } from "../dashboard-permissions";
import { PermissionHandlerService } from "../service/feature-Install-status/permission-handler.service";

import { AddDevicePermissions } from "./add-device-permissions.enum";

@Injectable({
  providedIn: "root",
})
export class AddDeviceWidgetPermissionHandlerService {
  constructor(
    private permission: PermissionHandlerService,
    private addDevicePermission: PermissionsHandlerService,
    private featurePreviewService: FeaturePreviewService
  ) {}

  permissionCheck(): Observable<void> {
    const createClient: Observable<boolean> = this.permission.isFeaturePermitted(
      AddDevicePermissions.createClient
    );
    const createSite: Observable<boolean> = this.permission.isFeaturePermitted(
      AddDevicePermissions.createSite
    );
    const agentDownload: Observable<boolean> = this.permission.isFeaturePermitted(
      AddDevicePermissions.agentDownload
    );

    const autoRefreshFlag: Observable<boolean> = this.featurePreviewService.isOn(
      DashboardPermissions.autoRefresh
    );
    const unMonitoredDevicesFlag: Observable<boolean> = this.featurePreviewService.isOn(
      DashboardPermissions.unmonitoredDevicesFlag
    );

    return zip(
      createClient,
      createSite,
      agentDownload,
      autoRefreshFlag,
      unMonitoredDevicesFlag
    ).pipe(map((result) => this.setPermission(result), share()));
  }

  private setPermission(result) {
    const permissionObject = {
      createClient: result[0],
      createSite: result[1],
      agentDownload: result[2],
      autoRefreshFlag: result[3],
      unMonitoredDevicesFlag: result[4],
    };

    this.addDevicePermission.permissions(permissionObject);
  }
}
