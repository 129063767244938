import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export enum DeviceFilterId {
  PROBLEM_247 = 1,
  PROBLEM_DSC = 2,
  OVERDUE = 4,
  PROBLEM = 7,
  OFFLINE = 8,
  WINDOWS = 9,
  LINUX = 10,
  OSX = 11,
  TOTAL = 63,
  LAST_RESPONSE = 100,
  TEMPORARY = 999,
  DYNAMIC = 1000,
}

@Injectable({
  providedIn: "root",
})
export class DeviceFilterDropdownService {
  private selectFilter = new Subject<DeviceFilterId>();

  // tslint:disable-next-line:member-ordering
  readonly selectFilterState = this.selectFilter.asObservable();

  fireSelectFilter(filterId: DeviceFilterId): void {
    this.selectFilter.next(filterId);
  }
}
