import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export enum ViewType {
  about = "DashboardAbout",
  dashboard = "devices",
  support = "SupportCenterWindow",
}

@Injectable()
export class ModalService {
  private _isDialogActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _view: Subject<ViewType> = new Subject<ViewType>();

  get isDialogActive(): Observable<boolean> {
    return this._isDialogActive.asObservable();
  }

  get getView(): Observable<string> {
    return this._view.asObservable();
  }

  openDialogByViewType(viewType: ViewType): void {
    this._view.next(viewType);
    this._isDialogActive.next(true);
  }

  closeView(): void {
    this._isDialogActive.next(false);
  }
}
