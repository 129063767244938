import { Injectable } from "@angular/core";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { concat, from, Observable, OperatorFunction } from "rxjs";
import { concatMap, filter, map, toArray } from "rxjs/operators";

import { AddDevicePermissions } from "../add-device/add-device-permissions.enum";
import { DashboardPermissions } from "../dashboard-permissions";
import { UnmonitoredDevicesPermissions } from "../unmonitored-devices/unmonitored-devices-permissions.enum";

import { PermissionHandlerService } from "./feature-Install-status/permission-handler.service";

@Injectable({
  providedIn: "root",
})
export class PermissionVerificationService {
  private featurePreviewFlags = from(Object.values(DashboardPermissions));
  private featurePermittedFlags = from(Object.values(AddDevicePermissions));
  private averyPermittedFlags = from(Object.values(UnmonitoredDevicesPermissions));

  constructor(
    private featurePreviewService: FeaturePreviewService,
    private permission: PermissionHandlerService
  ) {}

  verify(): Observable<string[]> {
    return concat(
      this.getFeaturePreviewFlags(),
      this.getFeaturePermittedFlags(),
      this.getAveryPermittedFlags()
    ).pipe(
      filter((flag: string) => Boolean(flag)),
      toArray()
    );
  }

  private getFeaturePreviewFlags(): Observable<string> {
    return this.featurePreviewFlags.pipe(
      concatMap((flag: string) =>
        this.featurePreviewService.isOn(flag).pipe(this.checkFlagStatus(flag))
      )
    );
  }

  private getFeaturePermittedFlags(): Observable<string> {
    return this.featurePermittedFlags.pipe(
      concatMap((flag: string) =>
        this.permission.isFeaturePermitted(flag).pipe(this.checkFlagStatus(flag))
      )
    );
  }

  private getAveryPermittedFlags(): Observable<string> {
    return this.averyPermittedFlags.pipe(
      concatMap((flag: string) =>
        this.permission.isFeaturePermitted(flag).pipe(this.checkFlagStatus(flag))
      )
    );
  }

  private checkFlagStatus(flag: string): OperatorFunction<boolean, string> {
    return map((flagState: boolean) => (flagState ? flag.toLowerCase() : null));
  }
}
