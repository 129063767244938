import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MspActiveIssuesModule } from "@solarwinds/msp-active-issues";

import { ActiveIssuesComponent } from "./active-issues.component";

@NgModule({
  imports: [CommonModule, MspActiveIssuesModule],
  declarations: [ActiveIssuesComponent],
  exports: [ActiveIssuesComponent],
})
export class ActiveIssuesModule {}
