import { Inject, Injectable } from "@angular/core";

@Injectable()
export class WindowManagerService {
  private readonly logoutUri: string = "default.php?logout=true";

  constructor(@Inject("Window") private window) {}

  public redirectUserToLogout() {
    this.redirectToUrl(this.logoutUri);
  }

  private redirectToUrl(url: string) {
    this.window.top.location.href = url;
  }
}
