export enum DeviceFeature {
  UNSPECIFIED_FEATURE = "UNSPECIFIED_FEATURE",
  TAKE_CONTROL = "TAKE_CONTROL",
  PATCH_MANAGEMENT = "PATCH_MANAGEMENT",
  SITE_CONCENTRATOR = "SITE_CONCENTRATOR",
  MANAGED_ANTIVIRUS = "MANAGED_ANTIVIRUS",
  MOB = "MOB",
  SYSTRAY = "SYSTRAY",
  RBM = "RBM",
  WEB_PROTECTION = "WEB_PROTECTION",
  MAV_BRECK = "MAV_BRECK",
  AVERY = "AVERY",
  ISCAN = "ISCAN",
  WEB_PROTECTION_ECHO = "WEB_PROTECTION_ECHO",
  TAKE_CONTROL_MSPC = "TAKE_CONTROL_MSPC",
  NETPATH = "NETPATH",
  RISK_INTELLIGENCE = "RISK_INTELLIGENCE",
}

export enum DeviceFeatureStatus {
  UNSPECIFIED_STATUS = "UNSPECIFIED_STATUS",
  NOT_INSTALLED = "NOT_INSTALLED",
  INSTALLED_ACTIVE = "INSTALLED_ACTIVE",
  INSTALLED_INACTIVE = "INSTALLED_INACTIVE",
  INSTALL_FAILED = "INSTALL_FAILED",
  NOT_COMPATIBLE = "NOT_COMPATIBLE",
  DEACTIVATING_FAILED = "DEACTIVATING_FAILED",
  PENDING = "PENDING",
  DEACTIVATING = "DEACTIVATING",
  AGENT_NOT_LOCAL_SYSTEM = "AGENT_NOT_LOCAL_SYSTEM",
}

export enum DeviceFeatureDisplayStatus {
  DISPLAY_STATUS_UNSPECIFIED_STATUS = "UNSPECIFIED_STATUS",
  DISPLAY_STATUS_NOT_INSTALLED = "NOT_INSTALLED",
  DISPLAY_STATUS_INCOMPATIBLE = "INCOMPATIBLE",
  DISPLAY_STATUS_ACTIVE = "ACTIVE",
  DISPLAY_STATUS_PENDING = "PENDING",
  DISPLAY_STATUS_INSTALL_FAILED = "INSTALL_FAILED",
  DISPLAY_STATUS_REBOOT_REQUIRED = "REBOOT_REQUIRED",
  DISPLAY_STATUS_DEACTIVATING = "DEACTIVATING",
  DISPLAY_STATUS_DEACTIVATION_FAILED = "DEACTIVATION_FAILED",
  DISPLAY_STATUS_INSTALLED_INACTIVE = "INSTALLED_INACTIVE",
}
