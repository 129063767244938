// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.base.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { configBuilder } from "@solarwinds/config-builder";

import { AppConfig } from "../app/app-config";

export const environment: AppConfig = {
  developmentI18n: true,
  production: false,
  ...configBuilder(require("../config/app.base.json"), require("../config/app.dev.json")),
};
