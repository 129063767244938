import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { IframeManagerService } from "../iframe-manager.service";

/**
 * This is a routable component meant to serve primarily as the reoute communication initiator
 */
@Component({
  selector: "app-legacy-route",
  template: "",
})
export class LegacyRouteComponent implements OnInit, OnDestroy {
  private routeDataSub: Subscription;

  constructor(
    private legacyIframeBus: IframeManagerService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.routeDataSub = this.activatedRoute.data.subscribe((data) =>
      this.legacyIframeBus.updateData(data)
    );
  }

  ngOnDestroy() {
    this.routeDataSub.unsubscribe();
    this.legacyIframeBus.hide();
  }
}
