import { Injectable } from "@angular/core";
import { Data } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";

/**
 * This handles the communication between the routable and stationary component
 */
@Injectable({
  providedIn: "root",
})
export class IframeManagerService {
  private dataSubject: Subject<Data> = new BehaviorSubject<Data>({});
  private hideSubject: Subject<Data> = new BehaviorSubject<Data>({});

  constructor() {}

  get data() {
    return this.dataSubject.asObservable();
  }

  get iframesHiding() {
    return this.hideSubject.asObservable();
  }

  updateData(data: Data) {
    this.dataSubject.next(data);
  }

  hide() {
    this.hideSubject.next();
  }
}
