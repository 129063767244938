import { ElementRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IframeMessageHandlerService {
  constructor() {}

  sendMessage(iframe: ElementRef, message: any) {
    if (iframe) {
      iframe.nativeElement.contentWindow.postMessage(message, window.location.origin);
    }
  }
}
