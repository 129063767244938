import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRequest, rmmRouterEndpoint, rpc } from "@solarwinds/msp-rmm-feature-preview";

interface AdvancedReportingUserStatusResponse {
  result: {
    status: boolean;
  };
}

@Injectable()
export class AdvancedReportingService {
  constructor(private http: HttpClient) {}

  public isEnabled(): Promise<boolean> {
    const request: ApiRequest<Array<null>> = {
      action: "AdvancedReporting",
      method: "status",
      data: [],
      type: rpc,
      tid: 1,
    };

    return new Promise((resolve) => {
      this.http
        .post<AdvancedReportingUserStatusResponse>(rmmRouterEndpoint, request, {})
        .subscribe((data) => {
          resolve(data.result.status);
        });
    });
  }
}
