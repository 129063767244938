import { Component, OnDestroy, OnInit } from "@angular/core";
import { StepService } from "@solarwinds/account-wizard-module";
import { DefaultViewService } from "@solarwinds/msp-dashboard-module";
import { IntegrationStatus, UiMenu, UiMenuList } from "@solarwinds/msp-ecosystem-ui";
import {
  EcosystemUtilService,
  IntegrationInitialisationService,
} from "@solarwinds/msp-rmm-ecosystem";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { EMPTY, forkJoin, from, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { AcceptSsaService } from "./accept-ssa.service";
import { AccountWizardOverlayService } from "./account-wizard-overlay.service";
import { AccountWizardSubmissionService } from "./account-wizard-submission.service";
import { AdvancedReportingService } from "./advanced-reporting/advanced-reporting.service";
import { FeatureFlag } from "./feature-flag.enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly shouldUseNableBranding$: Observable<boolean> = this.featurePreviewService.isOn(
    FeatureFlag.useNableBranding
  );

  isIntegrationManagementPermitted$: Observable<boolean>;

  advancedReportingNavItemEnabled: boolean = false;
  navHidden: boolean = true;
  ngdNewDashEnabled: boolean = false;
  satEnabled: boolean = false;
  integrationStatuses: { [integrationId: string]: IntegrationStatus } = {};

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private featurePreviewService: FeaturePreviewService,
    private accountWizardOverlayService: AccountWizardOverlayService,
    private accountWizardSubmissionService: AccountWizardSubmissionService,
    private stepService: StepService,
    private acceptSsaService: AcceptSsaService,
    private userSessionService: UserSessionService,
    private defaultViewService: DefaultViewService,
    private advancedReportingService: AdvancedReportingService,
    private ecosystemUtilService: EcosystemUtilService,
    private integrationInitialisationService: IntegrationInitialisationService
  ) {}

  get integrationMenus(): UiMenuList[] {
    return this.integrationInitialisationService.integrationMenus;
  }

  get anyIntegrationsPermitted(): boolean {
    return this.integrationInitialisationService?.anyIntegrationsPermitted ?? false;
  }

  get isIntegrationManagementEnabled$(): Observable<boolean> {
    return this.integrationInitialisationService?.isIntegrationManagementEnabled$ ?? EMPTY;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.featurePreviewService
        .isOn("AR_DV379_ENABLE_AR_UI")
        .subscribe((advancedReportingNavItemEnabled) => {
          if (advancedReportingNavItemEnabled) {
            this.checkAdvancedReportingStatus();
          }
        })
    );
    this.subscriptions.add(
      this.featurePreviewService.isOn("SAT").subscribe((satEnabled: boolean) => {
        this.satEnabled = satEnabled;
      })
    );

    const isNgdDashboardEnabled$: Observable<boolean> = this.featurePreviewService.isOn(
      "NGD_RMM28332_ENABLE"
    );
    const ssaAcceptanceRemoved$: Observable<boolean> = this.featurePreviewService.isOn(
      "RMM_32954_REMOVE_SSA_ACCEPTANCE"
    );
    const eulaAccepted$: Observable<boolean> = from(this.userSessionService.getEulaAccepted());
    const activeDevicesCount$: Observable<number> = from(
      this.userSessionService.getActiveDevicesCount()
    );
    this.subscriptions.add(
      forkJoin([isNgdDashboardEnabled$, eulaAccepted$, activeDevicesCount$, ssaAcceptanceRemoved$])
        .pipe(
          map((data) => ({
            ngdEnabled: data[0],
            eulaAccepted: data[1],
            activeDevicesCount: data[2],
            ssaAcceptanceRemoved: data[3],
          }))
        )
        .subscribe(
          (data) => {
            if (this.shouldOpenAccountWizard(data)) {
              this.openAccountWizard();
            } else {
              this.showNavBar();
            }
          },
          () => this.showNavBar()
        )
    );

    this.initEcosystem();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getIntegrationMenuPath(integrationId: string, menu: UiMenu): string {
    return this.ecosystemUtilService.getMenuPath(integrationId, menu);
  }

  integrationMenusPermitted(integrationId: string): boolean {
    const integrationMenusPermitted: { [integrationId: string]: boolean } =
      this.integrationInitialisationService?.integrationMenusPermitted ?? {};

    return integrationMenusPermitted[integrationId] ?? false;
  }

  integrationName(integrationId: string): string | undefined {
    const integrationStatuses: { [integrationId: string]: IntegrationStatus } =
      this.integrationInitialisationService?.integrationStatuses ?? {};

    return integrationStatuses[integrationId]?.integrationName;
  }

  private openAccountWizard(): void {
    this.accountWizardOverlayService.open();
    this.accountWizardSubmissionService.init();
    this.acceptSsaService.init();
    this.stepService.exitAccountWizardEvent.subscribe(() => this.showNavBar());
  }

  private showNavBar(): void {
    this.navHidden = false;
  }

  private checkAdvancedReportingStatus(): void {
    this.advancedReportingService.isEnabled().then((status) => {
      this.advancedReportingNavItemEnabled = status;
    });
  }

  private shouldOpenAccountWizard(data: {
    ngdEnabled: boolean;
    eulaAccepted: boolean;
    ssaAcceptanceRemoved: boolean;
    activeDevicesCount: number;
  }): boolean {
    return (
      (!data.ssaAcceptanceRemoved && data.ngdEnabled && !data.eulaAccepted) ||
      (data.ssaAcceptanceRemoved && data.ngdEnabled && data.activeDevicesCount === 0)
    );
  }

  private initEcosystem(): void {
    this.subscriptions.add(
      this.integrationInitialisationService.integrationInitialisation$.subscribe((data) =>
        this.integrationInitialisationService.initialiseIntegrations(data)
      )
    );
    this.subscriptions.add(
      this.integrationInitialisationService.postIntegrationActivationMenuList$.subscribe(
        (menuList) => this.integrationInitialisationService.updateIntegrationMenus(menuList)
      )
    );
    this.isIntegrationManagementPermitted$ =
      this.integrationInitialisationService?.isIntegrationManagementPermitted$ ?? EMPTY;
  }
}
