import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MspMonitoredDevicesModule } from "@solarwinds/msp-monitored-devices";

import { MonitoredDevicesComponent } from "./monitored-devices.component";

@NgModule({
  imports: [CommonModule, MspMonitoredDevicesModule],
  declarations: [MonitoredDevicesComponent],
  exports: [MonitoredDevicesComponent],
})
export class MonitoredDevicesModule {}
