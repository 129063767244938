import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Params } from "@angular/router";
import { StepService } from "@solarwinds/account-wizard-module";
import { RmmEventDispatcherService, RmmEventType } from "@solarwinds/msp-rmm-event-dispatcher";
import { RouteMonitorService } from "@solarwinds/msp-rmm-route-monitor";
import { UnmonitoredDevicesEventDispatchService } from "@solarwinds/msp-rmm-unmonitored-devices";
import { Subscription } from "rxjs";

import { DeviceFilterQueryService } from "../dashboard/service/device/device-filter-query.service";
import { DeviceFilterDropdownService } from "../device-filter-dropdown.service";
import { IframeMessageHandlerService } from "../iframe-message-handler.service";
import { RMM_DASHBOARD_ROOT } from "../rmm-config";
import { RouteItem } from "../route-item";
import { SessionExpiringHandlerService } from "../session-expiring-handler.service";
import { SessionKeepAliveHandlerService } from "../session-keep-alive-handler.service";

@Component({
  selector: "app-rmm-shell",
  templateUrl: "./rmm-shell.component.html",
  styleUrls: ["./rmm-shell.component.scss"],
})
export class RmmShellComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  @ViewChild("iframe", { static: true }) private iframe: ElementRef;
  private _isHidden: boolean = false;
  private _routeItem: RouteItem;

  constructor(
    @Inject(RMM_DASHBOARD_ROOT) private rmmDashboardRoot: string,
    private sessionKeepAliveHandler: SessionKeepAliveHandlerService,
    private sessionExpiringHandlerService: SessionExpiringHandlerService,
    private rmmEventDispatchService: RmmEventDispatcherService,
    private stepService: StepService,
    private iframeMessageHandler: IframeMessageHandlerService,
    private deviceFilterDropdownService: DeviceFilterDropdownService,
    private deviceFilterQueryService: DeviceFilterQueryService,
    private unmonitoredDevicesEventDispatchService: UnmonitoredDevicesEventDispatchService,
    private routeMonitorService: RouteMonitorService
  ) {}

  @Input()
  set routeItem(item: RouteItem) {
    if (item) {
      /**
       * This shell has been navigated to
       */

      if (!this._routeItem) {
        const queryString = this.getQueryStringFromParams(item.queryParams);
        this._routeItem = {
          url: `${this.rmmDashboardRoot}${item.url}${queryString}`,
        };
        if (item.view) {
          this._routeItem.view = item.view;
        }
        if (item.handleSessionTimeoutWindowPopup) {
          this.listenForSessionExpiring();
        }
      } else {
        if (item.view) {
          this.toggleView(item.view);
          if (item.view === "devices") {
            (<HTMLElement>window.document.querySelector(".defaultView")).style.height = "99.9%";
          }
        }
        if (item.shouldReload) {
          this.reload();
        }
        if (item.shouldNotifyQueryChange && item.queryParams) {
          this.notifyQueryChange(item.queryParams);
        }
      }
    }
  }

  get routeItem(): RouteItem {
    return this._routeItem;
  }

  @HostBinding("class.hide")
  @Input()
  set isHidden(hidden: boolean) {
    this._isHidden = hidden;
    if (this._routeItem && this._routeItem.view) {
      this.allowRefresh(!hidden);
    }
  }

  get isHidden(): boolean {
    return this._isHidden;
  }

  onFinishedLoad(): void {
    this.iframe.nativeElement.contentWindow.addEventListener(
      "mousemove",
      this.mouseMove.bind(this)
    );
  }

  @HostListener("document:mousemove")
  mouseMove(): void {
    this.sessionKeepAliveHandler.keepAlive();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.rmmEventDispatchService.dispatchedRmmEvent$.subscribe((event) =>
        this.dispatchRmmEvent(event.eventType)
      )
    );

    this.subscription.add(
      this.stepService.exitAccountWizardEvent.subscribe(() => {
        this.onExt4Loaded().then(() => {
          this.iframe.nativeElement.contentWindow.Ext4.onReady(() => {
            this.rmmEventDispatchService.dispatchEvent({
              eventType: RmmEventType.setMixedDeviceTab,
            });
            this.rmmEventDispatchService.dispatchEvent({
              eventType: RmmEventType.entityTreeRefresh,
            });

            this.onDeviceFilterComboReady().then(() =>
              this.rmmEventDispatchService.dispatchEvent({
                eventType: RmmEventType.setAllDeviceFilter,
              })
            );
          });
        });
      })
    );

    this.subscription.add(
      this.unmonitoredDevicesEventDispatchService.navigateToNetworkDevice.subscribe((eventBody) => {
        this.onExt4Loaded().then(() => {
          this.routeMonitorService.navigateByView("devices");
          this.sendMessageToIframe({
            type: RmmEventType.navigateNetworkDevice,
            message: eventBody,
          });
        });
      })
    );

    this.subscription.add(
      this.deviceFilterDropdownService.selectFilterState.subscribe((filterId) => {
        this.onExt4Loaded().then(() => {
          this.iframe.nativeElement.contentWindow.Ext4.onReady(() => {
            this.rmmEventDispatchService.dispatchEvent({
              eventType: RmmEventType.setMixedDeviceTab,
            });

            this.onDeviceFilterComboReady().then(() => {
              this.sendMessageToIframe({
                type: RmmEventType.setDeviceFilterDropdown,
                filter: filterId,
              });
            });
          });
        });
      })
    );

    this.subscription.add(
      this.deviceFilterQueryService.selectByQueryState.subscribe(({ criteria }) => {
        this.onExt4Loaded().then(() => {
          this.iframe.nativeElement.contentWindow.Ext4.onReady(() => {
            this.rmmEventDispatchService.dispatchEvent({
              eventType: RmmEventType.setMixedDeviceTab,
            });

            this.onDeviceFilterComboReady().then(() => {
              this.sendMessageToIframe({
                type: RmmEventType.setDeviceFilterQuery,
                criteria,
              });
            });
          });
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getQueryStringFromParams(queryParams: Params): string {
    if (queryParams) {
      return (
        "?" +
        Object.keys(queryParams)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(queryParams[k]))
          .join("&")
      );
    } else {
      return "";
    }
  }

  private reload(): void {
    this.iframe.nativeElement.src = this._routeItem.url;
  }

  private onDeviceFilterComboReady(): Promise<void> {
    return new Promise((resolve) => {
      if (this.canResolveMergedDeviceViewFilterComboStore()) {
        resolve();
      } else {
        this.performDelayedCheckForDeviceFilterCombo(resolve);
      }
    });
  }

  private performDelayedCheckForDeviceFilterCombo(resolveCallback): void {
    this.performDelayedCheck(
      () => this.canResolveMergedDeviceViewFilterComboStore(),
      resolveCallback
    );
  }

  private canResolveMergedDeviceViewFilterComboStore(): boolean {
    return (
      this.iframe.nativeElement.contentWindow.Ext4.ComponentQuery.query(
        "combo[xtype=mergeddeviceviewfiltercombo]"
      ).length > 0
    );
  }

  private onExt4Loaded(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.isExt4Loaded()) {
        resolve();
      } else {
        this.performDelayedCheckForExt4(resolve);
      }
    });
  }

  private performDelayedCheckForExt4(resolveCallback): void {
    this.performDelayedCheck(() => this.isExt4Loaded(), resolveCallback);
  }

  private performDelayedCheck(checkFn, resolveCallback, timeout: number = 500): void {
    setTimeout(() => {
      if (checkFn()) {
        resolveCallback();
      } else {
        this.performDelayedCheckForExt4(resolveCallback);
      }
    }, timeout);
  }

  private isExt4Loaded(): boolean {
    return (
      this.iframe &&
      !(
        this.iframe.nativeElement.contentWindow.Ext4 === undefined ||
        this.iframe.nativeElement.contentWindow.Ext4 === null
      )
    );
  }

  private sendMessageToIframe(message: any): void {
    this.iframeMessageHandler.sendMessage(this.iframe, message);
  }

  private listenForSessionExpiring(): void {
    this.sessionExpiringHandlerService.sessionExpiring.subscribe((state) => {
      if (state) {
        this.sendMessageToIframe({ type: `alert-session-timeout` });
      }
    });
  }

  private toggleView(view: string): void {
    if (this._routeItem.view !== view) {
      this._routeItem.view = view;
      this.sendMessageToIframe({ type: `view-${view}` });
    }
  }

  private allowRefresh(isAllowed: boolean): void {
    this.sendMessageToIframe({
      type: "allow-refresh",
      state: isAllowed,
    });
  }

  private notifyQueryChange(queryParams: Params): void {
    this.sendMessageToIframe({
      type: "route-query-change",
      payload: queryParams,
    });
  }

  private dispatchRmmEvent(rmmEvent: RmmEventType): void {
    this.sendMessageToIframe({
      type: rmmEvent,
    });
  }
}
