import { Injectable } from "@angular/core";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UnmonitoredDevicesPermissions } from "./unmonitored-devices-permissions.enum";

@Injectable({
  providedIn: "root",
})
export class UnmonitoredDevicesPermissionsService {
  constructor(
    private userSessionService: UserSessionService,
    private featurePreview: FeaturePreviewService
  ) {}

  isPermitted(): Observable<boolean> {
    return this.isUsagePermitted().pipe(
      map((permission: boolean[]) => permission[0] && permission[1])
    );
  }

  private isUsagePermitted(): Observable<boolean[]> {
    return combineLatest([
      this.userSessionService.isPermitted(UnmonitoredDevicesPermissions.readAveryNetwork),
      this.featurePreview.isOn("MTD_AB3819_UNMONITORED_DEVICE_MENU"),
    ]);
  }
}
