import { Injectable } from "@angular/core";
import { UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { Subject, timer } from "rxjs";
import { takeUntil, throttleTime } from "rxjs/operators";

import { SessionExpiringHandlerService } from "./session-expiring-handler.service";

@Injectable()
export class SessionKeepAliveHandlerService {
  private userSessionTimeoutPeriod: number;
  private userSessionTimer = new Subject();

  private throttlerSubject = new Subject();
  private throttlerObservable = this.throttlerSubject.asObservable().pipe(throttleTime(1000));

  constructor(
    private userSessionService: UserSessionService,
    private sessionExpiringHandlerService: SessionExpiringHandlerService
  ) {
    this.userSessionService.getUserSessionTimeout().then((timeoutInMinutes) => {
      if (timeoutInMinutes > 0) {
        this.setupInitialTimer(timeoutInMinutes);
      }
    });
  }

  keepAlive(): void {
    this.throttlerSubject.next();
  }

  private setupInitialTimer(timeoutInMinutes): void {
    this.userSessionTimeoutPeriod = timeoutInMinutes * 60 * 1000;
    this.throttlerObservable.subscribe(() => this.restartTimer());
    this.restartTimer();
  }

  private restartTimer(): void {
    if (this.userSessionTimeoutPeriod <= 0) {
      return;
    }

    this.userSessionTimer.next();

    timer(this.userSessionTimeoutPeriod)
      .pipe(takeUntil(this.userSessionTimer))
      .subscribe(() => this.sessionExpiringHandlerService.startLogoutTimer());
  }
}
