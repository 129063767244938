<app-legacy-dashboard-nav-item *ngIf="!isDashboardDefault"></app-legacy-dashboard-nav-item>

<nav-menu-section *ngIf="isOneSubFeatureEnabledAndPermitted$ | async">
  <app-dashboard-nav-items [navItems]="subFeatures$ | async"></app-dashboard-nav-items>
</nav-menu-section>

<nav-menu-item
  *ngIf="allSubFeaturesDisabledOrNotPermitted$ | async"
  i18n-title="@@dashboard_title"
  title="Overview"
  icon="ic_section_dashboard"
  href="/dash"
  noreferrer
>
</nav-menu-item>

<app-legacy-dashboard-nav-item *ngIf="isDashboardDefault"></app-legacy-dashboard-nav-item>
