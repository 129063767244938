import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MspDashboardModule } from "@solarwinds/msp-dashboard-module";

import { ActiveIssuesModule } from "./active-issues/active-issues.module";
import { DashboardComponent } from "./dashboard.component";
import { MonitoredDevicesModule } from "./monitored-devices/monitored-devices.module";
import { NavModule } from "./nav/nav.module";

@NgModule({
  imports: [
    CommonModule,
    MspDashboardModule,
    ActiveIssuesModule,
    MonitoredDevicesModule,
    NavModule,
  ],
  declarations: [DashboardComponent],
  exports: [DashboardComponent, NavModule, ActiveIssuesModule, MonitoredDevicesModule],
})
export class DashboardModule {}
