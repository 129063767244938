import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AcceptSsaService as AccountWizardAcceptSsaService } from "@solarwinds/account-wizard-module";
import {
  ApiRequest,
  ApiResponse,
  FeaturePreviewService,
} from "@solarwinds/msp-rmm-feature-preview";
import { Observable } from "rxjs";

export interface SsaAcceptResult {
  success: boolean;
}

@Injectable()
export class AcceptSsaService {
  readonly apiRouterUrl: string = "api/router.php";
  readonly requestBody: ApiRequest<string> = {
    action: "user.Session",
    method: "eulaAccept",
    data: null,
    type: "rpc",
    tid: null,
  };

  constructor(
    private acceptSsaService: AccountWizardAcceptSsaService,
    private httpClient: HttpClient
  ) {}

  init(): void {
    this.acceptSsaService.getAcceptSsaClickedObservable().subscribe(() => {
      this.acceptSsa().subscribe((response: ApiResponse<SsaAcceptResult>) => {
        if (response.result.success) {
          this.acceptSsaService.onSsaAcceptedStored();
        }
      });
    });
  }

  private acceptSsa(): Observable<ApiResponse<SsaAcceptResult>> {
    localStorage.setItem("set_NGD_as_default", "true");
    return this.httpClient.post<ApiResponse<SsaAcceptResult>>(
      this.apiRouterUrl,
      this.requestBody,
      {}
    );
  }
}
