export enum DeviceStatus {
  OFFLINE = "offline",
  OVERDUE = "overdue",
  REBOOT = "reboot_required",
  ONLINE = "online",
}

export enum DeviceStatusTitle {
  OFFLINE = "Offline",
  OVERDUE = "Overdue",
  REBOOT = "Reboot",
  ONLINE = "Online",
}

export enum CurrentFeatureInstallStatus {
  INSTALL_FAILED = "install_failed",
  PENDING = "pending",
  DEACTIVATING = "deactivating",
  REBOOT_REQUIRED = "reboot_required",
  NOT_INSTALLED = "not_installed",
  NOT_COMPATIBLE = "not_compatible",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum CurrentFeatureInstallStatusTitle {
  INSTALL_FAILED = "Install failed",
  PENDING = "Pending",
  REBOOT_REQUIRED = "Reboot",
  NOT_INSTALLED = "Not installed",
  ACTIVE = "Active",
}
