export const THIRTY_DAYS: number = 30;

export const mockUnmonitoredDevicesSummary = {
  took: 198,
  timed_out: false,
  num_reduce_phases: 2,
  hits: {
    total: {
      value: 51,
      relation: "eq",
    },
    max_score: null,
    hits: [],
  },
  aggregations: {
    WindowsFilter: {
      buckets: {
        Windows: {
          doc_count: 18,
        },
      },
    },
    PrintersFilter: {
      buckets: {
        Printers: {
          doc_count: 0,
        },
      },
    },
    MacOsxFilter: {
      buckets: {
        MacOsx: {
          doc_count: 3,
        },
      },
    },
    LinuxFilter: {
      buckets: {
        Linux: {
          doc_count: 0,
        },
      },
    },
    LaptopsFilter: {
      buckets: {
        Laptop: {
          doc_count: 0,
        },
      },
    },
    WorkstationsFilter: {
      buckets: {
        Workstation: {
          doc_count: 16,
        },
      },
    },
    SwitchesFilter: {
      buckets: {
        NetworkSwitch: {
          doc_count: 0,
        },
        Switch: {
          doc_count: 1,
        },
      },
    },
    RoutersFilter: {
      buckets: {
        Router: {
          doc_count: 0,
        },
        "Wired router": {
          doc_count: 1,
        },
      },
    },
    OtherFilter: {
      buckets: {
        Other: {
          doc_count: 49,
        },
      },
    },
    FirewallsFilter: {
      buckets: {
        Firewall: {
          doc_count: 0,
        },
      },
    },
    ServersFilter: {
      buckets: {
        Server: {
          doc_count: 0,
        },
      },
    },
    Printers: {
      value: 1,
    },
    Switches: {
      value: 2,
    },
    Routers: {
      value: 3,
    },
    Firewalls: {
      value: 4,
    },
    Other: {
      value: 5,
    },
    Workstations: {
      value: 1,
    },
    Servers: {
      value: 2,
    },
    Laptops: {
      value: 3,
    },
    Windows: {
      value: 1,
    },
    MacOsx: {
      value: 2,
    },
    Linux: {
      value: 3,
    },
  },
};
