import { CheckFrequency } from "../enums/check-frequency.enum";
import { DeviceType, DeviceTypes } from "../enums/device-type.enum";

export const serverCondition = {
  type: "eq",
  name: "device_type",
  value: "server",
};

export const workstationCondition = {
  type: "in",
  name: "device_type",
  value: ["desktop", "laptop"],
};

export const problemCondition = {
  type: "group and",
  children: [
    {
      type: "group or",
      children: [
        { type: "eq", name: "check_status", value: "FAILING" },
        {
          type: "group and",
          children: [
            { type: "eq", name: "device_status", value: "overdue" },
            { type: "eq", name: "device_type", value: "server" },
          ],
        },
      ],
    },
    { type: "in", name: "device_status", value: ["online", "overdue"] },
    {
      type: "in",
      name: "check_frequency",
      value: [CheckFrequency.TWO_FOUR_SEVEN, CheckFrequency.DSC],
    },
  ],
};

export const deviceTypeQueryMapping = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: serverCondition,
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: workstationCondition,
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.ALL,
    {
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const deviceByTypesQueryMapping = new Map<DeviceTypes, object>([
  [
    DeviceTypes.SERVER,
    {
      criteria: serverCondition,
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceTypes.DESKTOP,
    {
      criteria: { type: "eq", name: "device_type", value: "desktop" },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceTypes.LAPTOP,
    {
      criteria: { type: "eq", name: "device_type", value: "laptop" },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const problemDeviceTypeQueryMapping = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [problemCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [problemCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.ALL,
    {
      criteria: {
        type: "group and",
        children: [problemCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const failingChecksDevicesCondition = {
  type: "group and",
  children: [
    {
      type: "in",
      name: "device_status",
      value: ["online", "overdue"],
    },
    {
      type: "eq",
      name: "check_status",
      value: "FAILING",
    },
    {
      type: "in",
      name: "check_frequency",
      value: [CheckFrequency.TWO_FOUR_SEVEN, CheckFrequency.DSC],
    },
  ],
};

export const warningChecksDevicesCondition = {
  type: "group and",
  children: [
    {
      type: "in",
      name: "device_status",
      value: ["online", "overdue"],
    },
    {
      type: "eq",
      name: "check_status",
      value: "WARNING",
    },
    {
      type: "in",
      name: "check_frequency",
      value: [CheckFrequency.TWO_FOUR_SEVEN, CheckFrequency.DSC],
    },
  ],
};

export const clearedChecksDevicesCondition = {
  type: "group and",
  children: [
    {
      type: "in",
      name: "device_status",
      value: ["online", "overdue"],
    },
    {
      type: "eq",
      name: "check_status",
      value: "CLEARED",
    },
    {
      type: "in",
      name: "check_frequency",
      value: [CheckFrequency.TWO_FOUR_SEVEN, CheckFrequency.DSC],
    },
  ],
};

export const passingChecksDevicesCondition = {
  type: "group and",
  children: [
    {
      type: "in",
      name: "check_status",
      value: ["CLEARED", "MAINTENANCE", "NEW", "PASSING", "SUPPRESSED", "WARNING"],
    },
    {
      type: "in",
      name: "check_frequency",
      value: [CheckFrequency.TWO_FOUR_SEVEN, CheckFrequency.DSC],
    },
  ],
};

export const passingCheckDeviceTypeQueryMapping = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [passingChecksDevicesCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [passingChecksDevicesCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const failingCheckDeviceTypeQueryMapping = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [failingChecksDevicesCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [failingChecksDevicesCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const warningCheckDeviceTypeQueryMapping = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [warningChecksDevicesCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [warningChecksDevicesCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);

export const clearedCheckDeviceTypeQueryMapping = new Map<DeviceType, object>([
  [
    DeviceType.SERVER,
    {
      criteria: {
        type: "group and",
        children: [clearedChecksDevicesCondition, serverCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
  [
    DeviceType.WORKSTATION,
    {
      criteria: {
        type: "group and",
        children: [clearedChecksDevicesCondition, workstationCondition],
      },
      limit: 1,
      page: 1,
      start: 0,
    },
  ],
]);
