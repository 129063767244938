import { Injectable } from "@angular/core";
import { RouteMonitorService } from "@solarwinds/msp-rmm-route-monitor";
import { BehaviorSubject, Observable, Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { WindowManagerService } from "./window-manager.service";

@Injectable()
export class SessionExpiringHandlerService {
  private readonly timeUntilLogoutInMilliseconds = 30000;
  private stopTimer = new Subject();

  private sessionExpiringSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private windowManagerService: WindowManagerService,
    private routeMonitorService: RouteMonitorService
  ) {}

  get sessionExpiring(): Observable<boolean> {
    return this.sessionExpiringSubject.asObservable();
  }

  cancelLogoutTimer(): void {
    this.notifySessionIsExpiring(false);
    this.stopTimer.next();
  }

  startLogoutTimer(): void {
    this.routeMonitorService.navigateByLastViewUrl();

    this.notifySessionIsExpiring(true);

    timer(this.timeUntilLogoutInMilliseconds)
      .pipe(takeUntil(this.stopTimer))
      .subscribe(() => {
        this.handleLogoutTimerComplete();
      });
  }

  private handleLogoutTimerComplete(): void {
    this.windowManagerService.redirectUserToLogout();
  }

  private notifySessionIsExpiring(value: boolean): void {
    this.sessionExpiringSubject.next(value);
  }
}
