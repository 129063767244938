import { Injectable } from "@angular/core";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { iif, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { failingCheckFrequencyV2Query } from "../../consts/device-query-v2.const";
import {
  deviceTypeQueryMapping,
  problemDeviceTypeQueryMapping,
} from "../../consts/device-query.const";
import { CheckFrequency } from "../../enums/check-frequency.enum";
import { DeviceType } from "../../enums/device-type.enum";
import { MaestroVersion } from "../../enums/maestro-versions.enum";

import { MaestroService } from "./maestro/maestro.service";

@Injectable({
  providedIn: "root",
})
export class DevicesService {
  private readonly deviceTypeQueryMap = deviceTypeQueryMapping;
  private readonly problemDeviceTypeQueryMap = problemDeviceTypeQueryMapping;

  constructor(
    private featurePreviewService: FeaturePreviewService,
    private maestroService: MaestroService
  ) {}

  getTotalCount(): Observable<number> {
    return this.maestroService.getCount(this.getDevicesByTypeQuery(DeviceType.ALL));
  }

  getTotalServerCount(): Observable<number> {
    return this.maestroService.getCount(this.getDevicesByTypeQuery(DeviceType.SERVER));
  }

  getTotalWorkstationCount(): Observable<number> {
    return this.maestroService.getCount(this.getDevicesByTypeQuery(DeviceType.WORKSTATION));
  }

  getTotalProblemCount(): Observable<number> {
    return this.maestroService.getCount(this.getProblemDevicesByTypeQuery(DeviceType.ALL));
  }

  getTotalProblemServerCount(): Observable<number> {
    return this.maestroService.getCount(this.getProblemDevicesByTypeQuery(DeviceType.SERVER));
  }

  getTotalProblemWorkstationCount(): Observable<number> {
    return this.maestroService.getCount(this.getProblemDevicesByTypeQuery(DeviceType.WORKSTATION));
  }

  getTotalDSCFailureCount(): Observable<number> {
    const v1Response = this.maestroService.getCount(
      this.getTotalDscFailureQuery(MaestroVersion.V1)
    );
    const v2Response = this.maestroService.getV2Count(
      this.getTotalDscFailureQuery(MaestroVersion.V2)
    );

    return this.featurePreviewService
      .isOn("RMM_32480_ACTIVATE_MAESTRO_V2_QUERIES")
      .pipe(mergeMap((flagOn: boolean) => iif(() => flagOn, v2Response, v1Response)));
  }

  getTotalDscFailureQuery(version: MaestroVersion): object {
    const queries = {
      [MaestroVersion.V1]: () =>
        this.wrapCriteria(this.failingCheckFrequencyCondition(CheckFrequency.DSC)),
      [MaestroVersion.V2]: () => failingCheckFrequencyV2Query(CheckFrequency.DSC),
    };

    return queries[version]();
  }

  getTotal247FailureCount(): Observable<number> {
    const v1Response = this.maestroService.getCount(
      this.getTotal247FailureQuery(MaestroVersion.V1)
    );
    const v2Response = this.maestroService.getV2Count(
      this.getTotal247FailureQuery(MaestroVersion.V2)
    );

    return this.featurePreviewService
      .isOn("RMM_32480_ACTIVATE_MAESTRO_V2_QUERIES")
      .pipe(mergeMap((flagOn: boolean) => iif(() => flagOn, v2Response, v1Response)));
  }

  getTotal247FailureQuery(version: MaestroVersion): object {
    const queries = {
      [MaestroVersion.V1]: () =>
        this.wrapCriteria(this.failingCheckFrequencyCondition(CheckFrequency.TWO_FOUR_SEVEN)),
      [MaestroVersion.V2]: () => failingCheckFrequencyV2Query(CheckFrequency.TWO_FOUR_SEVEN),
    };

    return queries[version]();
  }

  getDevicesByTypeQuery(deviceType: DeviceType): object {
    if (!this.deviceTypeQueryMap.has(deviceType)) {
      throw new Error(`Unknown device type ${deviceType}`);
    }

    return this.deviceTypeQueryMap.get(deviceType);
  }

  getProblemDevicesByTypeQuery(deviceType: DeviceType): object {
    if (!this.problemDeviceTypeQueryMap.has(deviceType)) {
      throw new Error(`Unknown device type ${deviceType}`);
    }

    return this.problemDeviceTypeQueryMap.get(deviceType);
  }

  getDeviceByGUIDQuery(deviceGUID: string): object {
    return this.wrapCriteria(this.deviceByGUIDCondition(deviceGUID));
  }

  wrapCriteria(criteria: object): object {
    return {
      criteria,
      limit: 1,
      page: 1,
      start: 0,
    };
  }

  private failingCheckFrequencyCondition(checkFrequency: CheckFrequency): object {
    return {
      type: "group and",
      children: [
        {
          type: "eq",
          name: "check_status",
          value: "FAILING",
        },
        {
          type: "eq",
          name: "check_frequency",
          value: checkFrequency,
        },
        { type: "in", name: "device_status", value: ["online", "overdue"] },
      ],
    };
  }

  private deviceByGUIDCondition(deviceGUID: string) {
    return {
      type: "group and",
      children: [
        {
          type: "eq",
          name: "guid",
          value: deviceGUID,
        },
      ],
    };
  }
}
