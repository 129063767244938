import { ElementRef, Injectable } from "@angular/core";

@Injectable()
export class FileSaveService {
  constructor() {}

  saveAs(data: any, filename: string, link: ElementRef): void {
    const blob: Blob = new Blob([data], { type: "application/octet-stream" });
    const url: string = window.URL.createObjectURL(blob);
    if (link && link.nativeElement) {
      link.nativeElement.href = url;
      link.nativeElement.download = filename;
      link.nativeElement.click();
    } else {
      const a: HTMLAnchorElement = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }
}
