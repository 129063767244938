import { Component, Input } from "@angular/core";

import { NavLink } from "../enums/nav-link.enum";
import { NavItems } from "../nav-items";

@Component({
  selector: "app-dashboard-nav-items",
  templateUrl: "./nav-items.component.html",
})
export class NavItemsComponent {
  @Input()
  navItems: NavItems;

  NavLink: typeof NavLink = NavLink;

  isLinkEnabled(navLink: NavLink): boolean {
    return (this.navItems ?? {})[navLink] ?? false;
  }
}
