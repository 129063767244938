import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { Observable } from "rxjs";

import { DeviceType } from "../../enums/device-type.enum";
import { MaestroVersion } from "../../enums/maestro-versions.enum";
import { MaestroService } from "../device/maestro/maestro.service";

@Injectable({
  providedIn: "root",
})
export class FeatureInstallCommonService {
  constructor(private maestro: MaestroService) {}

  getStatusQueries(deviceType: DeviceType, queries: Map<DeviceType, object>[]): object[] {
    return queries.map((query: Map<DeviceType, object>) =>
      this.getFeatureInstallStatusFromQuery(deviceType, query)
    );
  }

  getStatuses(queries, version: MaestroVersion): Observable<object>[] {
    if (version === MaestroVersion.V2) {
      return queries.map((query: Map<DeviceType, object>) => this.maestro.getV2Count(query));
    }

    return queries.map((query: Map<DeviceType, object>) => this.maestro.getCount(query));
  }

  createFeatureInstallStatusWidgetData(data: object, queries: object): ChartItem[] {
    const statuses = [
      $localize`:@@install_failed:Failed`,
      $localize`Pending`,
      $localize`Reboot`,
      $localize`Not installed`,
      $localize`Active`,
    ];

    return statuses.map((status, i) => ({
      name: status,
      value: data[i],
      sourceQuery: {
        deviceFilter: queries[i],
      },
    }));
  }

  private getFeatureInstallStatusFromQuery(
    deviceType: DeviceType,
    installStatusQuery: Map<DeviceType, object>
  ): object {
    if (!installStatusQuery.has(deviceType)) {
      throw new Error(`Unknown device type ${deviceType}`);
    }

    return installStatusQuery.get(deviceType);
  }
}
