import { Injectable } from "@angular/core";
import {
  DashboardData,
  MspDashboardService,
  WidgetClickEvent,
} from "@solarwinds/msp-dashboard-module";
import { RouteMonitorService } from "@solarwinds/msp-rmm-route-monitor";
import { UnmonitoredDevicesConfigurationService } from "@solarwinds/msp-rmm-unmonitored-devices";
import { DeviceFilterDropdownService } from "app/device-filter-dropdown.service";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { UnmonitoredDevicesConfigProvider } from "../../unmonitored-devices/unmonitored-devices-config-provider.service";
import { UnmonitoredDevicesDefaultFilterService } from "../../unmonitored-devices/unmonitored-devices-default-filter-service";
import { DeviceType } from "../enums/device-type.enum";
import { MaestroVersion } from "../enums/maestro-versions.enum";

import { DeviceFilterQueryService } from "./device/device-filter-query.service";
import { DeviceLastRebootService } from "./device/device-last-reboot.service";
import { DeviceOsService } from "./device/device-os.service";
import { DeviceStatusService } from "./device/device-status.service";
import { DeviceTypesService } from "./device/device-types.service";
import { DevicesService } from "./device/devices.service";
import { UnmonitoredDevicesService } from "./device/unmonitored-devices.service";
import { BackupFeatureInstallStatusService } from "./feature-Install-status/backup-feature-install-status.service";
import { MavFeatureInstallStatusService } from "./feature-Install-status/mav-feature-install-status.service";
import { PatchManagementInstallStatusService } from "./feature-Install-status/patch-management-install-status.service";
import { WebProtectionInstallStatusService } from "./feature-Install-status/web-protection-install-status.service";
import { ProblemDevicesService } from "./problem-devices.service";

@Injectable({
  providedIn: "root",
})
export class DashboardDataHandler {
  constructor(
    private mspDashboardService: MspDashboardService,
    private deviceService: DevicesService,
    private deviceStatusService: DeviceStatusService,
    private problemDevicesService: ProblemDevicesService,
    private deviceOsService: DeviceOsService,
    private patchManagementService: PatchManagementInstallStatusService,
    private mavFeatureInstallStatusService: MavFeatureInstallStatusService,
    private deviceLastRebootService: DeviceLastRebootService,
    private backupInstallStatusService: BackupFeatureInstallStatusService,
    private webProtectionInstallStatusService: WebProtectionInstallStatusService,
    private deviceTypesService: DeviceTypesService,
    private deviceFilterQueryService: DeviceFilterQueryService,
    private deviceFilterDropdownService: DeviceFilterDropdownService,
    private routeMonitorService: RouteMonitorService,
    private unmonitoredDevicesDefaultFilterService: UnmonitoredDevicesDefaultFilterService,
    private unmonitoredDevicesConfigService: UnmonitoredDevicesConfigurationService,
    private unmonitoredDevicesConfigHandler: UnmonitoredDevicesConfigProvider,
    private unmonitoredDevicesService: UnmonitoredDevicesService
  ) {}

  dashboardWidgetData(): Observable<any[]> {
    return forkJoin([
      this.getTotalCount(),
      this.getTotalProblemCount(),
      this.getTotalDSCFailureCount(),
      this.getTotal247FailureCount(),
      this.problemDevicesService.getServers(),
      this.problemDevicesService.getWorkstations(),
      this.deviceStatusService.getServerStatus(),
      this.deviceStatusService.getWorkstation(),
      this.deviceOsService.getDevicesByMainOs(),
      this.patchManagementService.getPatchManagementStatus(),
      this.mavFeatureInstallStatusService.getMavFeatureInstallStatus(),
      this.deviceLastRebootService.getLastBootTimeServers(),
      this.backupInstallStatusService.getBackupFeatureInstallStatus(),
      this.webProtectionInstallStatusService.getWebProtectionFeatureInstallStatus(),
      this.deviceTypesService.getDeviceTypes(),
      this.unmonitoredDevicesService.getUnmonitoredNetworkDevices(),
      this.unmonitoredDevicesService.getUnmonitoredDevicesByOs(),
      this.unmonitoredDevicesService.getUnmonitoredDevices(),
      this.bigNumClickThrough(),
      this.barClickThrough(),
      this.openUnmonitoredDevicesWithFilter(),
    ]);
  }

  private getTotalCount(): Observable<void> {
    return this.deviceService.getTotalCount().pipe(
      map((value) => {
        this.mspDashboardService.deviceData.next({
          value,
          serviceUnavailable: false,
          noDataAvailable: false,
          sourceQuery: {
            deviceFilter: this.deviceService.getDevicesByTypeQuery(DeviceType.ALL),
          },
          icon: "ic_workstation",
        } as DashboardData<number>);
      })
    );
  }

  private getTotalProblemCount(): Observable<void> {
    return this.deviceService.getTotalProblemCount().pipe(
      map((value) => {
        this.mspDashboardService.problemDevicesData.next({
          value,
          serviceUnavailable: false,
          noDataAvailable: false,
          sourceQuery: {
            deviceFilter: this.deviceService.getProblemDevicesByTypeQuery(DeviceType.ALL),
          },
        } as DashboardData<number>);
      })
    );
  }

  private getTotalDSCFailureCount(): Observable<void> {
    return this.deviceService.getTotalDSCFailureCount().pipe(
      map((value) => {
        this.mspDashboardService.dscDevicesData.next({
          value,
          serviceUnavailable: false,
          noDataAvailable: false,
          sourceQuery: {
            deviceFilter: this.deviceService.getTotalDscFailureQuery(MaestroVersion.V1),
          },
        } as DashboardData<number>);
      })
    );
  }

  private getTotal247FailureCount(): Observable<void> {
    return this.deviceService.getTotal247FailureCount().pipe(
      map((value: number) => {
        this.mspDashboardService.twoFourSevenDeviceData.next({
          value,
          serviceUnavailable: false,
          noDataAvailable: false,
          sourceQuery: {
            deviceFilter: this.deviceService.getTotal247FailureQuery(MaestroVersion.V1),
          },
        } as DashboardData<number>);
      })
    );
  }

  private bigNumClickThrough(): Observable<void> {
    return this.mspDashboardService.bigNumClickThrough.asObservable().pipe(
      map(({ sourceQuery }: WidgetClickEvent<number>) => {
        if (!sourceQuery) {
          return;
        }

        if (sourceQuery["filterId"]) {
          this.deviceFilterDropdownService.fireSelectFilter(sourceQuery["filterId"]);
        }

        if (sourceQuery["deviceFilter"]) {
          this.deviceFilterQueryService.fireSelectByQuery(sourceQuery["deviceFilter"]);
        }

        this.redirectToDevices();
      })
    );
  }

  private barClickThrough(): Observable<void> {
    return this.mspDashboardService.barClickThrough.pipe(
      map(({ sourceQuery }: WidgetClickEvent<number>) => {
        if (sourceQuery && sourceQuery["deviceFilter"]) {
          this.deviceFilterQueryService.fireSelectByQuery(sourceQuery["deviceFilter"]);
          this.redirectToDevices();
        }
      })
    );
  }

  private redirectToDevices(): void {
    this.routeMonitorService.navigateByView("devices");
  }

  private openUnmonitoredDevicesWithFilter(): Observable<void> {
    return this.mspDashboardService.openUnmonitoredDevicesWithFilter.pipe(
      map((clickEvent: any) => {
        this.unmonitoredDevicesDefaultFilterService.setFilter(
          of({
            groupId: clickEvent.sourceQuery.toString(),
            filterId: clickEvent.name,
          })
        );

        this.unmonitoredDevicesConfigService.setConfig(
          this.unmonitoredDevicesConfigHandler.getConfig()
        );

        this.routeMonitorService.navigate("/unmonitored-devices");
      })
    );
  }
}
