import { Component, OnInit } from "@angular/core";
import { DefaultViewService } from "@solarwinds/msp-dashboard-module";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FeatureFlag } from "../../../feature-flag.enum";
import { ActiveIssuesPermissionsService } from "../../active-issues/active-issues-permissions.service";
import { UnmonitoredDevicesPermissionsService } from "../../unmonitored-devices/unmonitored-devices-permissions.service";
import { NavItems } from "../nav-items";

@Component({
  selector: "app-dashboard-nav",
  templateUrl: "./nav.component.html",
})
export class NavComponent implements OnInit {
  subFeatures$: Observable<NavItems>;
  allSubFeaturesDisabledOrNotPermitted$: Observable<boolean>;
  isOneSubFeatureEnabledAndPermitted$: Observable<boolean>;

  private subFeatureEnabled$: Observable<boolean[]>;

  constructor(
    private featurePreviewService: FeaturePreviewService,
    private activeIssuesPermissionsService: ActiveIssuesPermissionsService,
    private unmonitoredDevicesPermissionsService: UnmonitoredDevicesPermissionsService,
    private defaultViewService: DefaultViewService
  ) {}

  get isDashboardDefault(): boolean {
    return this.defaultViewService.isDashboardDefault();
  }

  ngOnInit(): void {
    const isActiveIssuesPermitted$: Observable<boolean> = this.activeIssuesPermissionsService.isPermitted();
    const isUnmonitoredDevicesPermitted$: Observable<boolean> = this.unmonitoredDevicesPermissionsService.isPermitted();
    const isMonitoredDevicesEnabled$: Observable<boolean> = this.featurePreviewService.isOn(
      FeatureFlag.monitoredDevices
    );

    this.subFeatures$ = forkJoin({
      activeIssues: isActiveIssuesPermitted$,
      unmonitoredDevices: isUnmonitoredDevicesPermitted$,
      monitoredDevices: isMonitoredDevicesEnabled$,
    });

    this.subFeatureEnabled$ = this.subFeatures$.pipe(
      map((subFeatureMap) => subFeatureMap ?? {}),
      map((subFeatureMap) => Object.values(subFeatureMap) ?? [])
    );
    this.isOneSubFeatureEnabledAndPermitted$ = this.subFeatureEnabled$.pipe(
      map((subFeatures) => subFeatures.some((isPermitted) => isPermitted))
    );
    this.allSubFeaturesDisabledOrNotPermitted$ = this.subFeatureEnabled$.pipe(
      map((permitted) => permitted.every((isPermitted) => !isPermitted))
    );
  }
}
