/* tslint:disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { MockedType } from '@solarwinds/quasar-api-client';
import { MockResolversWrapper } from '@solarwinds/quasar-api-client'
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CheckFrequencyItem: any;
  DateTime: String;
};


export type ActiveIssues = {
  readonly __typename?: 'ActiveIssues';
  readonly checks: ReadonlyArray<Maybe<Check>>;
  readonly total: CheckStatusTotals;
  readonly filters: ActiveIssuesFilterValues;
  readonly id: Scalars['ID'];
  readonly parameters: FilterInputParameters;
};

export type ActiveIssuesFilterSort = {
  readonly sortBy: SortByInput;
  readonly direction: SortDirection;
};

export type ActiveIssuesFilterValues = {
  readonly __typename?: 'ActiveIssuesFilterValues';
  readonly status?: Maybe<CheckStatusTotals>;
  /** @deprecated Use `checkTypeFilter field` */
  readonly checkType?: Maybe<CheckTypesTotals>;
  readonly checkTypeFilter?: Maybe<ReadonlyArray<Maybe<CheckTypeInformationWithTotal>>>;
  readonly deviceType?: Maybe<DeviceTypeTotals>;
};


export type ActiveIssuesFilterValuesCheckTypeFilterArgs = {
  type: ReadonlyArray<Maybe<CheckType>>;
};

export type Agent = {
  readonly __typename?: 'Agent';
  readonly version?: Maybe<Scalars['String']>;
};

export enum AuditAction {
  Unknown = 'UNKNOWN',
  Delete = 'DELETE',
  Edit = 'EDIT',
  Add = 'ADD',
  Actioned = 'ACTIONED',
  Cancel = 'CANCEL',
  Run = 'RUN'
}

export type AuditedChecksResponse = {
  readonly __typename?: 'AuditedChecksResponse';
  readonly message: Scalars['String'];
  readonly auditedChecks: Scalars['Boolean'];
};

export enum AuditWhat {
  AgentKey = 'AGENT_KEY',
  AgentManuallyRemoved = 'AGENT_MANUALLY_REMOVED',
  Alert = 'ALERT',
  AlertRoutingPolicy = 'ALERT_ROUTING_POLICY',
  AllAssets = 'ALL_ASSETS',
  Asset = 'ASSET',
  AssetTrackingSettings = 'ASSET_TRACKING_SETTINGS',
  AutomatedTask = 'AUTOMATED_TASK',
  AutomatedTaskClear = 'AUTOMATED_TASK_CLEAR',
  AutomaticInvoicePayments = 'AUTOMATIC_INVOICE_PAYMENTS',
  AveryAction = 'AVERY_ACTION',
  AveryPolicy = 'AVERY_POLICY',
  AvPolicy = 'AV_POLICY',
  Check = 'CHECK',
  CheckClear = 'CHECK_CLEAR',
  CheckOutageEmailAlert = 'CHECK_OUTAGE_EMAIL_ALERT',
  CheckOutageSmsAlert = 'CHECK_OUTAGE_SMS_ALERT',
  CheckRecoveryEmailAlert = 'CHECK_RECOVERY_EMAIL_ALERT',
  CheckRecoverySmsAlert = 'CHECK_RECOVERY_SMS_ALERT',
  Client = 'CLIENT',
  Company = 'COMPANY',
  ControlNowMigration = 'CONTROL_NOW_MIGRATION',
  CriticalEventsExclusion = 'CRITICAL_EVENTS_EXCLUSION',
  CustomDomains = 'CUSTOM_DOMAINS',
  DashboardSecurity = 'DASHBOARD_SECURITY',
  DefaultMonthlyReportContent = 'DEFAULT_MONTHLY_REPORT_CONTENT',
  DeleteDuplicateAsset = 'DELETE_DUPLICATE_ASSET',
  Device = 'DEVICE',
  DeviceAutoUpdate = 'DEVICE_AUTO_UPDATE',
  DeviceFiltersAction = 'DEVICE_FILTERS_ACTION',
  DeviceFiltersFavourite = 'DEVICE_FILTERS_FAVOURITE',
  DeviceOverdueEmailAlert = 'DEVICE_OVERDUE_EMAIL_ALERT',
  DeviceSms = 'DEVICE_SMS',
  Discount = 'DISCOUNT',
  DiskEncryptionManagerAction = 'DISK_ENCRYPTION_MANAGER_ACTION',
  DiskEncryptionReportGenerated = 'DISK_ENCRYPTION_REPORT_GENERATED',
  EmailConfirmed = 'EMAIL_CONFIRMED',
  ForceVipreInstall = 'FORCE_VIPRE_INSTALL',
  GlobalAutoUpdate = 'GLOBAL_AUTO_UPDATE',
  Hogshead = 'HOGSHEAD',
  HwAsset = 'HW_ASSET',
  InactiveManagedOnlineBackupStorageAccount = 'INACTIVE_MANAGED_ONLINE_BACKUP_STORAGE_ACCOUNT',
  MailTemplate = 'MAIL_TEMPLATE',
  ManagedAntivirusAction = 'MANAGED_ANTIVIRUS_ACTION',
  ManagedAntivirusInstall = 'MANAGED_ANTIVIRUS_INSTALL',
  ManagedAntivirusPolicySettings = 'MANAGED_ANTIVIRUS_POLICY_SETTINGS',
  ManagedOnlineBackupKillSwitch = 'MANAGED_ONLINE_BACKUP_KILL_SWITCH',
  ManagedOnlineBackupPolicySettings = 'MANAGED_ONLINE_BACKUP_POLICY_SETTINGS',
  ManagedOnlineBackupRequestEncryptionKey = 'MANAGED_ONLINE_BACKUP_REQUEST_ENCRYPTION_KEY',
  ManagedOnlineBackupRunAllBackupsOnDevice = 'MANAGED_ONLINE_BACKUP_RUN_ALL_BACKUPS_ON_DEVICE',
  ManagedOnlineBackupSeedLoading = 'MANAGED_ONLINE_BACKUP_SEED_LOADING',
  ManagedOnlineBackupUserAgreement = 'MANAGED_ONLINE_BACKUP_USER_AGREEMENT',
  MavBreckAction = 'MAV_BRECK_ACTION',
  MavBreckPolicy = 'MAV_BRECK_POLICY',
  MavUserAgreement = 'MAV_USER_AGREEMENT',
  MobileDevice = 'MOBILE_DEVICE',
  MobileDeviceManagementPolicySettings = 'MOBILE_DEVICE_MANAGEMENT_POLICY_SETTINGS',
  MobileDeviceSendCommand = 'MOBILE_DEVICE_SEND_COMMAND',
  MonitoringTemplate = 'MONITORING_TEMPLATE',
  MonitoringTemplateCheck = 'MONITORING_TEMPLATE_CHECK',
  MonitoringTemplatePolicySettings = 'MONITORING_TEMPLATE_POLICY_SETTINGS',
  NcentralMigration = 'NCENTRAL_MIGRATION',
  Note = 'NOTE',
  PasswordReset = 'PASSWORD_RESET',
  PasswordResetRequest = 'PASSWORD_RESET_REQUEST',
  PasswordSet = 'PASSWORD_SET',
  PasswordSetRequest = 'PASSWORD_SET_REQUEST',
  PatchManagementApproval = 'PATCH_MANAGEMENT_APPROVAL',
  PatchManagementPatchApprovalStatus = 'PATCH_MANAGEMENT_PATCH_APPROVAL_STATUS',
  PatchManagementPolicySettings = 'PATCH_MANAGEMENT_POLICY_SETTINGS',
  PatchManagementRunRemediation = 'PATCH_MANAGEMENT_RUN_REMEDIATION',
  PatchManagementRunVulnerabilityScan = 'PATCH_MANAGEMENT_RUN_VULNERABILITY_SCAN',
  PatchManagementUninstallPatches = 'PATCH_MANAGEMENT_UNINSTALL_PATCHES',
  PendingActionReverted = 'PENDING_ACTION_REVERTED',
  ProblemHandling = 'PROBLEM_HANDLING',
  Psa = 'PSA',
  PsaConnectwiseMigration = 'PSA_CONNECTWISE_MIGRATION',
  PsaTicket = 'PSA_TICKET',
  PublicApiKey = 'PUBLIC_API_KEY',
  RebootDeviceFromDashboard = 'REBOOT_DEVICE_FROM_DASHBOARD',
  RecoveryKeyReportGenerated = 'RECOVERY_KEY_REPORT_GENERATED',
  RemoteBackgroundPolicySettings = 'REMOTE_BACKGROUND_POLICY_SETTINGS',
  RemoteBackgroundSession = 'REMOTE_BACKGROUND_SESSION',
  RemoteBackgroundSessionAdvanced = 'REMOTE_BACKGROUND_SESSION_ADVANCED',
  RemoteProcessControl = 'REMOTE_PROCESS_CONTROL',
  RemoteServiceControl = 'REMOTE_SERVICE_CONTROL',
  RemoteSupportSession = 'REMOTE_SUPPORT_SESSION',
  Report = 'REPORT',
  RevokeTrustedDevices = 'REVOKE_TRUSTED_DEVICES',
  Role = 'ROLE',
  RunAssetScanOnDeviceFromDashboard = 'RUN_ASSET_SCAN_ON_DEVICE_FROM_DASHBOARD',
  RunChecksOnDeviceFromDashboard = 'RUN_CHECKS_ON_DEVICE_FROM_DASHBOARD',
  RunTaskNow = 'RUN_TASK_NOW',
  RunWorkstationScanOnDeviceFromDashboard = 'RUN_WORKSTATION_SCAN_ON_DEVICE_FROM_DASHBOARD',
  Script = 'SCRIPT',
  ServiceForClient = 'SERVICE_FOR_CLIENT',
  SetLicenceCount = 'SET_LICENCE_COUNT',
  Site = 'SITE',
  SiteConcentrator = 'SITE_CONCENTRATOR',
  SiteInstallationPackage = 'SITE_INSTALLATION_PACKAGE',
  SnmpPreset = 'SNMP_PRESET',
  SnmpPresetStatusMapping = 'SNMP_PRESET_STATUS_MAPPING',
  SupportRequest = 'SUPPORT_REQUEST',
  SwAsset = 'SW_ASSET',
  SystemTrayApplicationIcon = 'SYSTEM_TRAY_APPLICATION_ICON',
  SystemTrayApplicationPolicySettings = 'SYSTEM_TRAY_APPLICATION_POLICY_SETTINGS',
  TakeControlPolicySettings = 'TAKE_CONTROL_POLICY_SETTINGS',
  TakeControlReplaceExistingTeamviewerInstallation = 'TAKE_CONTROL_REPLACE_EXISTING_TEAMVIEWER_INSTALLATION',
  TakeControlSession = 'TAKE_CONTROL_SESSION',
  TeamviewerSession = 'TEAMVIEWER_SESSION',
  TwoFaResetFailedIncorrectRecoveryCode = 'TWO_FA_RESET_FAILED_INCORRECT_RECOVERY_CODE',
  TwoFaResetFailedRetryLimitExceeded = 'TWO_FA_RESET_FAILED_RETRY_LIMIT_EXCEEDED',
  Unknown = 'UNKNOWN',
  User = 'USER',
  UserAcceptedEula = 'USER_ACCEPTED_EULA',
  UserLoggedIn = 'USER_LOGGED_IN',
  UserLoggedOut = 'USER_LOGGED_OUT',
  UserLoginFailedAccountSuspended = 'USER_LOGIN_FAILED_ACCOUNT_SUSPENDED',
  UserLoginFailedBlockedIp = 'USER_LOGIN_FAILED_BLOCKED_IP',
  UserLoginFailedIncorrect_2FaSecurityCode = 'USER_LOGIN_FAILED_INCORRECT_2FA_SECURITY_CODE',
  UserLoginFailedIncorrectPassword = 'USER_LOGIN_FAILED_INCORRECT_PASSWORD',
  UserLoginSetting = 'USER_LOGIN_SETTING',
  UserPermissionsClientGroup = 'USER_PERMISSIONS_CLIENT_GROUP',
  UserReset_2FaCredentials = 'USER_RESET_2FA_CREDENTIALS',
  VarMobOverageSettings = 'VAR_MOB_OVERAGE_SETTINGS',
  WallchartSetting = 'WALLCHART_SETTING',
  WebProtectionEchoAction = 'WEB_PROTECTION_ECHO_ACTION',
  WebProtectionPolicySettings = 'WEB_PROTECTION_POLICY_SETTINGS',
  WebProtectionRefreshData = 'WEB_PROTECTION_REFRESH_DATA',
  WorkstationAutoUpdate = 'WORKSTATION_AUTO_UPDATE'
}

export type BaseCheckType = {
  readonly type: CheckType;
  readonly label: Scalars['String'];
};

export type Check = Node & {
  readonly __typename?: 'Check';
  readonly id: Scalars['ID'];
  readonly frequency: Scalars['CheckFrequencyItem'];
  readonly status: CheckStatus;
  readonly scheduleRun?: Maybe<Scalars['String']>;
  readonly lastResultDateTime?: Maybe<Scalars['DateTime']>;
  readonly reportedAt?: Maybe<Scalars['DateTime']>;
  readonly device: Device;
  /** @deprecated Use `DescriptionsInformation field` */
  readonly type?: Maybe<CheckType>;
  readonly typeInformation?: Maybe<CheckTypeInformation>;
  /**
   * This field represent the end result of `messageID` after replacing ` Mustache ` tags.
   * 
   * eg: messageID: "Drive Space Change Check - {{driveletter}}",
   */
  readonly description?: Maybe<Scalars['String']>;
  /** This is to show results description */
  readonly resultDescription?: Maybe<Scalars['String']>;
};

export enum CheckClearOption {
  NextRun = 'NEXT_RUN',
  NextPass = 'NEXT_PASS',
  Time = 'TIME',
  Empty = 'EMPTY'
}

export type CheckError = {
  readonly __typename?: 'CheckError';
  readonly message: Scalars['String'];
};

export enum CheckFrequency {
  TwentyFourSeven = 'TWENTY_FOUR_SEVEN',
  Dsc = 'DSC'
}


export type CheckIdAndDeviceId = {
  readonly deviceId: Scalars['String'];
  readonly checkId: Scalars['String'];
};

/**
 * Using this union type in a query
 * ```
 * mutation clearChecks {
 *   clearChecks(
 *     clearCheckInput: {
 *       checkIdAndDeviceIdList:[CheckIdAndDeviceId],
 *       clearOption:CheckClearOption
 *     }
 *   ){
 *     ... on  AuditedChecksResponse{
 *       ...
 *     }
 *     ... on CheckError{
 *       ...
 *     }
 *   }
 * }
 * ```
 * [More about Inline fragments](http://spec.graphql.org/June2018/#sec-Inline-Fragments)
 */
export type ChecksResult = AuditedChecksResponse | CheckError;

/**
 * Statuses are **ordered** **by** **severity**,
 * 1. FAILING
 * 2. SOFT FAILURE = SUPPRESSED + WARNING
 * 3. CLEARED
 */
export enum CheckStatus {
  Failing = 'FAILING',
  Cleared = 'CLEARED',
  Passing = 'PASSING',
  New = 'NEW',
  Warning = 'WARNING',
  Maintenance = 'MAINTENANCE',
  Suppressed = 'SUPPRESSED'
}

export type CheckStatusTotals = {
  readonly __typename?: 'CheckStatusTotals';
  readonly FAILING?: Maybe<Scalars['Int']>;
  readonly CLEARED?: Maybe<Scalars['Int']>;
  readonly PASSING?: Maybe<Scalars['Int']>;
  readonly NEW?: Maybe<Scalars['Int']>;
  readonly WARNING?: Maybe<Scalars['Int']>;
  readonly MAINTENANCE?: Maybe<Scalars['Int']>;
  readonly SUPPRESSED?: Maybe<Scalars['Int']>;
  readonly SOFT_FAILURE?: Maybe<Scalars['Int']>;
};

export enum CheckType {
  AgentUptime = 'AGENT_UPTIME',
  AntiVirus = 'ANTI_VIRUS',
  HackerAlert = 'HACKER_ALERT',
  PhysicalDisk = 'PHYSICAL_DISK',
  PhysicalMemory = 'PHYSICAL_MEMORY',
  CriticalEvents = 'CRITICAL_EVENTS',
  Vulnerability = 'VULNERABILITY',
  Backup = 'BACKUP',
  Exchange = 'EXCHANGE',
  FileSize = 'FILE_SIZE',
  EventLog = 'EVENT_LOG',
  WindowsServerUpdateServices = 'WINDOWS_SERVER_UPDATE_SERVICES',
  AutoStartService = 'AUTO_START_SERVICE',
  DiskSpace = 'DISK_SPACE',
  DiskSpaceChange = 'DISK_SPACE_CHANGE',
  PerformanceMonitoringProcessorUtilisation = 'PERFORMANCE_MONITORING_PROCESSOR_UTILISATION',
  PerformanceMonitoringMemory = 'PERFORMANCE_MONITORING_MEMORY',
  PerformanceMonitoringNetwork = 'PERFORMANCE_MONITORING_NETWORK',
  PerformanceMonitoringDisk = 'PERFORMANCE_MONITORING_DISK',
  PerformanceMonitoringProcessorQueue = 'PERFORMANCE_MONITORING_PROCESSOR_QUEUE',
  Website = 'WEBSITE',
  Snmp = 'SNMP',
  Bandwidth = 'BANDWIDTH',
  WebProtectionBandwidth = 'WEB_PROTECTION_BANDWIDTH',
  TcpService = 'TCP_SERVICE',
  Ping = 'PING',
  Mta = 'MTA',
  PackageManagement = 'PACKAGE_MANAGEMENT',
  Daemon = 'DAEMON',
  Process = 'PROCESS',
  LogFile = 'LOG_FILE',
  SqlServer = 'SQL_SERVER',
  OsxUpdate = 'OSX_UPDATE',
  Service = 'SERVICE',
  AutomatedTask = 'AUTOMATED_TASK',
  ManagedOnlineBackup = 'MANAGED_ONLINE_BACKUP',
  ManagedAntiVirus = 'MANAGED_ANTI_VIRUS',
  Script = 'SCRIPT',
  Breck = 'BRECK',
  BreckUpdate = 'BRECK_UPDATE',
  EchoBandwidth = 'ECHO_BANDWIDTH',
  PhysicalDiskSmart = 'PHYSICAL_DISK_SMART',
  PrinterMarker = 'PRINTER_MARKER',
  PrinterPaper = 'PRINTER_PAPER',
  NetworkdeviceConnectivity = 'NETWORKDEVICE_CONNECTIVITY',
  NetworkdevicePing = 'NETWORKDEVICE_PING',
  RouterInterface = 'ROUTER_INTERFACE',
  GruntWork = 'GRUNT_WORK',
  Hogshead = 'HOGSHEAD',
  NetworkdeviceSnmp = 'NETWORKDEVICE_SNMP',
  DemManagedAntiVirus = 'DEM_MANAGED_ANTI_VIRUS'
}

export type CheckTypeInformation = BaseCheckType & {
  readonly __typename?: 'CheckTypeInformation';
  readonly type: CheckType;
  /**
   * User friendly display string of the `Check Type`
   * 
   * eg: PHYSICAL_MEMORY = Physical Memory Health Check
   * 
   * [More check type descriptions](https://github.com/logicnow/CheckProcessor/blob/04bdd2a3e8b37d3c57b0b7e0fde0155e2522b037/libs/description-utils/src/main/resources/descriptions.yaml)
   */
  readonly label: Scalars['String'];
};

export type CheckTypeInformationWithTotal = BaseCheckType & {
  readonly __typename?: 'CheckTypeInformationWithTotal';
  readonly type: CheckType;
  /**
   * User friendly display string of the `Check Type`
   * 
   * eg: PHYSICAL_MEMORY = Physical Memory Health Check
   * 
   * [More check type descriptions](https://github.com/logicnow/CheckProcessor/blob/04bdd2a3e8b37d3c57b0b7e0fde0155e2522b037/libs/description-utils/src/main/resources/descriptions.yaml)
   */
  readonly label: Scalars['String'];
  /** Total value of a selected check type */
  readonly total?: Maybe<Scalars['Int']>;
};

export type CheckTypesTotals = {
  readonly __typename?: 'CheckTypesTotals';
  readonly AGENT_UPTIME?: Maybe<Scalars['Int']>;
  readonly ANTI_VIRUS?: Maybe<Scalars['Int']>;
  readonly HACKER_ALERT?: Maybe<Scalars['Int']>;
  readonly PHYSICAL_DISK?: Maybe<Scalars['Int']>;
  readonly PHYSICAL_MEMORY?: Maybe<Scalars['Int']>;
  readonly CRITICAL_EVENTS?: Maybe<Scalars['Int']>;
  readonly VULNERABILITY?: Maybe<Scalars['Int']>;
  readonly BACKUP?: Maybe<Scalars['Int']>;
  readonly EXCHANGE?: Maybe<Scalars['Int']>;
  readonly FILE_SIZE?: Maybe<Scalars['Int']>;
  readonly EVENT_LOG?: Maybe<Scalars['Int']>;
  readonly WINDOWS_SERVER_UPDATE_SERVICES?: Maybe<Scalars['Int']>;
  readonly AUTO_START_SERVICE?: Maybe<Scalars['Int']>;
  readonly DISK_SPACE?: Maybe<Scalars['Int']>;
  readonly DISK_SPACE_CHANGE?: Maybe<Scalars['Int']>;
  readonly PERFORMANCE_MONITORING_PROCESSOR_UTILISATION?: Maybe<Scalars['Int']>;
  readonly PERFORMANCE_MONITORING_MEMORY?: Maybe<Scalars['Int']>;
  readonly PERFORMANCE_MONITORING_NETWORK?: Maybe<Scalars['Int']>;
  readonly PERFORMANCE_MONITORING_DISK?: Maybe<Scalars['Int']>;
  readonly PERFORMANCE_MONITORING_PROCESSOR_QUEUE?: Maybe<Scalars['Int']>;
  readonly WEBSITE?: Maybe<Scalars['Int']>;
  readonly SNMP?: Maybe<Scalars['Int']>;
  readonly BANDWIDTH?: Maybe<Scalars['Int']>;
  readonly WEB_PROTECTION_BANDWIDTH?: Maybe<Scalars['Int']>;
  readonly TCP_SERVICE?: Maybe<Scalars['Int']>;
  readonly PING?: Maybe<Scalars['Int']>;
  readonly MTA?: Maybe<Scalars['Int']>;
  readonly PACKAGE_MANAGEMENT?: Maybe<Scalars['Int']>;
  readonly DAEMON?: Maybe<Scalars['Int']>;
  readonly PROCESS?: Maybe<Scalars['Int']>;
  readonly LOG_FILE?: Maybe<Scalars['Int']>;
  readonly SQL_SERVER?: Maybe<Scalars['Int']>;
  readonly OSX_UPDATE?: Maybe<Scalars['Int']>;
  readonly SERVICE?: Maybe<Scalars['Int']>;
  readonly AUTOMATED_TASK?: Maybe<Scalars['Int']>;
  readonly MANAGED_ONLINE_BACKUP?: Maybe<Scalars['Int']>;
  readonly MANAGED_ANTI_VIRUS?: Maybe<Scalars['Int']>;
  readonly SCRIPT?: Maybe<Scalars['Int']>;
  readonly BRECK?: Maybe<Scalars['Int']>;
  readonly BRECK_UPDATE?: Maybe<Scalars['Int']>;
  readonly ECHO_BANDWIDTH?: Maybe<Scalars['Int']>;
  readonly PHYSICAL_DISK_SMART?: Maybe<Scalars['Int']>;
  readonly PRINTER_MARKER?: Maybe<Scalars['Int']>;
  readonly PRINTER_PAPER?: Maybe<Scalars['Int']>;
  readonly NETWORKDEVICE_CONNECTIVITY?: Maybe<Scalars['Int']>;
  readonly NETWORKDEVICE_PING?: Maybe<Scalars['Int']>;
  readonly ROUTER_INTERFACE?: Maybe<Scalars['Int']>;
  readonly GRUNT_WORK?: Maybe<Scalars['Int']>;
  readonly HOGSHEAD?: Maybe<Scalars['Int']>;
  readonly NETWORKDEVICE_SNMP?: Maybe<Scalars['Int']>;
  readonly DEM_MANAGED_ANTI_VIRUS?: Maybe<Scalars['Int']>;
};

export type ClearCheckInput = {
  readonly checkIdAndDeviceIdList: ReadonlyArray<Maybe<CheckIdAndDeviceId>>;
  readonly clearOption: CheckClearOption;
  readonly toDateTime?: Maybe<Scalars['DateTime']>;
  readonly technicalNote?: Maybe<Scalars['String']>;
  readonly clientFacingNote?: Maybe<Scalars['String']>;
};

export type ClearCheckSuccess = {
  readonly __typename?: 'ClearCheckSuccess';
  readonly checkId?: Maybe<Scalars['String']>;
};

export type Client = {
  readonly __typename?: 'Client';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly sites?: Maybe<ReadonlyArray<Maybe<Site>>>;
};


export type Device = {
  readonly __typename?: 'Device';
  readonly id: Scalars['String'];
  readonly type: DeviceType;
  readonly name: Scalars['String'];
  readonly checks?: Maybe<ReadonlyArray<Maybe<Check>>>;
  readonly site: Site;
  readonly os: Scalars['String'];
  readonly agent: Agent;
};

export enum DeviceType {
  Server = 'SERVER',
  Workstation = 'WORKSTATION',
  Laptop = 'LAPTOP',
  Unknown = 'UNKNOWN'
}

export type DeviceTypeTotals = {
  readonly __typename?: 'DeviceTypeTotals';
  readonly SERVER: Scalars['Int'];
  readonly WORKSTATION: Scalars['Int'];
  readonly LAPTOP: Scalars['Int'];
};

export type FilterInputParameters = {
  readonly __typename?: 'FilterInputParameters';
  readonly status: ReadonlyArray<Maybe<CheckStatus>>;
  readonly deviceType: ReadonlyArray<Maybe<DeviceType>>;
  readonly checkType: ReadonlyArray<Maybe<CheckType>>;
  readonly search: Scalars['String'];
  readonly sorter: SortParams;
};

/**
 * These are input values used by `Active Issues`
 * to get a filtered view of the checks:
 */
export type FilterInputs = {
  readonly status: ReadonlyArray<Maybe<CheckStatus>>;
  readonly deviceType: ReadonlyArray<Maybe<DeviceType>>;
  readonly checkType: ReadonlyArray<Maybe<CheckType>>;
  readonly search: Scalars['String'];
  readonly sorter: ActiveIssuesFilterSort;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly runChecks: ChecksResult;
  readonly clearChecks: ChecksResult;
};


export type MutationRunChecksArgs = {
  runCheckInput?: Maybe<RunCheckInput>;
};


export type MutationClearChecksArgs = {
  clearCheckInput?: Maybe<ClearCheckInput>;
};

export type Node = {
  readonly id: Scalars['ID'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly checkTypeInformation?: Maybe<ReadonlyArray<Maybe<CheckTypeInformation>>>;
  readonly device: Device;
  readonly activeIssues: ActiveIssues;
  readonly sitesByDevice: ReadonlyArray<Maybe<Site>>;
};


export type QueryDeviceArgs = {
  deviceID: Scalars['ID'];
};


export type QueryActiveIssuesArgs = {
  input: FilterInputs;
};


export type QuerySitesByDeviceArgs = {
  deviceID: Scalars['ID'];
};

export type RunCheckInput = {
  readonly checkIdAndDeviceIdList: ReadonlyArray<Maybe<CheckIdAndDeviceId>>;
};

export type Site = {
  readonly __typename?: 'Site';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly devices?: Maybe<ReadonlyArray<Maybe<Device>>>;
  readonly client: Client;
  readonly totalChecks?: Maybe<Scalars['Int']>;
  readonly statusChecks?: Maybe<CheckStatus>;
};

export enum SortByInput {
  Status = 'STATUS',
  Type = 'TYPE',
  Clientname = 'CLIENTNAME',
  Lastresultdatetime = 'LASTRESULTDATETIME',
  Devicename = 'DEVICENAME',
  Sitename = 'SITENAME'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  Original = 'ORIGINAL'
}

export type SortParams = {
  readonly __typename?: 'SortParams';
  readonly sortBy: SortByInput;
  readonly direction: SortDirection;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (obj: T, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ActiveIssues: ResolverTypeWrapper<MockedType<ActiveIssues>>;
  ID: ResolverTypeWrapper<MockedType<Scalars['ID']>>;
  ActiveIssuesFilterSort: ResolverTypeWrapper<MockedType<ActiveIssuesFilterSort>>;
  ActiveIssuesFilterValues: ResolverTypeWrapper<MockedType<ActiveIssuesFilterValues>>;
  Agent: ResolverTypeWrapper<MockedType<Agent>>;
  String: ResolverTypeWrapper<MockedType<Scalars['String']>>;
  AuditAction: ResolverTypeWrapper<MockedType<AuditAction>>;
  AuditedChecksResponse: ResolverTypeWrapper<MockedType<AuditedChecksResponse>>;
  Boolean: ResolverTypeWrapper<MockedType<Scalars['Boolean']>>;
  AuditWhat: ResolverTypeWrapper<MockedType<AuditWhat>>;
  BaseCheckType: ResolversTypes['CheckTypeInformation'] | ResolversTypes['CheckTypeInformationWithTotal'];
  Check: ResolverTypeWrapper<MockedType<Check>>;
  CheckClearOption: ResolverTypeWrapper<MockedType<CheckClearOption>>;
  CheckError: ResolverTypeWrapper<MockedType<CheckError>>;
  CheckFrequency: ResolverTypeWrapper<MockedType<CheckFrequency>>;
  CheckFrequencyItem: ResolverTypeWrapper<MockedType<Scalars['CheckFrequencyItem']>>;
  CheckIdAndDeviceId: ResolverTypeWrapper<MockedType<CheckIdAndDeviceId>>;
  ChecksResult: MockedType<ResolversTypes['AuditedChecksResponse'] | ResolversTypes['CheckError']>;
  CheckStatus: ResolverTypeWrapper<MockedType<CheckStatus>>;
  CheckStatusTotals: ResolverTypeWrapper<MockedType<CheckStatusTotals>>;
  Int: ResolverTypeWrapper<MockedType<Scalars['Int']>>;
  CheckType: ResolverTypeWrapper<MockedType<CheckType>>;
  CheckTypeInformation: ResolverTypeWrapper<MockedType<CheckTypeInformation>>;
  CheckTypeInformationWithTotal: ResolverTypeWrapper<MockedType<CheckTypeInformationWithTotal>>;
  CheckTypesTotals: ResolverTypeWrapper<MockedType<CheckTypesTotals>>;
  ClearCheckInput: ResolverTypeWrapper<MockedType<ClearCheckInput>>;
  ClearCheckSuccess: ResolverTypeWrapper<MockedType<ClearCheckSuccess>>;
  Client: ResolverTypeWrapper<MockedType<Client>>;
  DateTime: ResolverTypeWrapper<MockedType<Scalars['DateTime']>>;
  Device: ResolverTypeWrapper<MockedType<Device>>;
  DeviceType: ResolverTypeWrapper<MockedType<DeviceType>>;
  DeviceTypeTotals: ResolverTypeWrapper<MockedType<DeviceTypeTotals>>;
  FilterInputParameters: ResolverTypeWrapper<MockedType<FilterInputParameters>>;
  FilterInputs: ResolverTypeWrapper<MockedType<FilterInputs>>;
  Mutation: ResolverTypeWrapper<{}>;
  Node: ResolversTypes['Check'];
  Query: ResolverTypeWrapper<{}>;
  RunCheckInput: ResolverTypeWrapper<MockedType<RunCheckInput>>;
  Site: ResolverTypeWrapper<MockedType<Site>>;
  SortByInput: ResolverTypeWrapper<MockedType<SortByInput>>;
  SortDirection: ResolverTypeWrapper<MockedType<SortDirection>>;
  SortParams: ResolverTypeWrapper<MockedType<SortParams>>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActiveIssues: MockedType<ActiveIssues>;
  ID: MockedType<Scalars['ID']>;
  ActiveIssuesFilterSort: MockedType<ActiveIssuesFilterSort>;
  ActiveIssuesFilterValues: MockedType<ActiveIssuesFilterValues>;
  Agent: MockedType<Agent>;
  String: MockedType<Scalars['String']>;
  AuditedChecksResponse: MockedType<AuditedChecksResponse>;
  Boolean: MockedType<Scalars['Boolean']>;
  BaseCheckType: ResolversParentTypes['CheckTypeInformation'] | ResolversParentTypes['CheckTypeInformationWithTotal'];
  Check: MockedType<Check>;
  CheckError: MockedType<CheckError>;
  CheckFrequencyItem: MockedType<Scalars['CheckFrequencyItem']>;
  CheckIdAndDeviceId: MockedType<CheckIdAndDeviceId>;
  ChecksResult: MockedType<ResolversParentTypes['AuditedChecksResponse'] | ResolversParentTypes['CheckError']>;
  CheckStatusTotals: MockedType<CheckStatusTotals>;
  Int: MockedType<Scalars['Int']>;
  CheckTypeInformation: MockedType<CheckTypeInformation>;
  CheckTypeInformationWithTotal: MockedType<CheckTypeInformationWithTotal>;
  CheckTypesTotals: MockedType<CheckTypesTotals>;
  ClearCheckInput: MockedType<ClearCheckInput>;
  ClearCheckSuccess: MockedType<ClearCheckSuccess>;
  Client: MockedType<Client>;
  DateTime: MockedType<Scalars['DateTime']>;
  Device: MockedType<Device>;
  DeviceTypeTotals: MockedType<DeviceTypeTotals>;
  FilterInputParameters: MockedType<FilterInputParameters>;
  FilterInputs: MockedType<FilterInputs>;
  Mutation: {};
  Node: ResolversParentTypes['Check'];
  Query: {};
  RunCheckInput: MockedType<RunCheckInput>;
  Site: MockedType<Site>;
  SortParams: MockedType<SortParams>;
};

export type IntlDirectiveArgs = {  };

export type IntlDirectiveResolver<Result, Parent, ContextType = any, Args = IntlDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActiveIssuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActiveIssues'] = ResolversParentTypes['ActiveIssues']> = {
  checks?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Check']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['CheckStatusTotals'], ParentType, ContextType>;
  filters?: Resolver<ResolversTypes['ActiveIssuesFilterValues'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  parameters?: Resolver<ResolversTypes['FilterInputParameters'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveIssuesFilterValuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActiveIssuesFilterValues'] = ResolversParentTypes['ActiveIssuesFilterValues']> = {
  status?: Resolver<Maybe<ResolversTypes['CheckStatusTotals']>, ParentType, ContextType>;
  checkType?: Resolver<Maybe<ResolversTypes['CheckTypesTotals']>, ParentType, ContextType>;
  checkTypeFilter?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CheckTypeInformationWithTotal']>>>, ParentType, ContextType, RequireFields<ActiveIssuesFilterValuesCheckTypeFilterArgs, 'type'>>;
  deviceType?: Resolver<Maybe<ResolversTypes['DeviceTypeTotals']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AgentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Agent'] = ResolversParentTypes['Agent']> = {
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AuditedChecksResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuditedChecksResponse'] = ResolversParentTypes['AuditedChecksResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  auditedChecks?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BaseCheckTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCheckType'] = ResolversParentTypes['BaseCheckType']> = {
  __resolveType: TypeResolveFn<'CheckTypeInformation' | 'CheckTypeInformationWithTotal', ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CheckType'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type CheckResolvers<ContextType = any, ParentType extends ResolversParentTypes['Check'] = ResolversParentTypes['Check']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['CheckFrequencyItem'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CheckStatus'], ParentType, ContextType>;
  scheduleRun?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastResultDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reportedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CheckType']>, ParentType, ContextType>;
  typeInformation?: Resolver<Maybe<ResolversTypes['CheckTypeInformation']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resultDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CheckErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckError'] = ResolversParentTypes['CheckError']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface CheckFrequencyItemScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CheckFrequencyItem'], any> {
  name: 'CheckFrequencyItem';
}

export type ChecksResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChecksResult'] = ResolversParentTypes['ChecksResult']> = {
  __resolveType: TypeResolveFn<'AuditedChecksResponse' | 'CheckError', ParentType, ContextType>;
};

export type CheckStatusTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckStatusTotals'] = ResolversParentTypes['CheckStatusTotals']> = {
  FAILING?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  CLEARED?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PASSING?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  NEW?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  WARNING?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MAINTENANCE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SUPPRESSED?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SOFT_FAILURE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CheckTypeInformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckTypeInformation'] = ResolversParentTypes['CheckTypeInformation']> = {
  type?: Resolver<ResolversTypes['CheckType'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CheckTypeInformationWithTotalResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckTypeInformationWithTotal'] = ResolversParentTypes['CheckTypeInformationWithTotal']> = {
  type?: Resolver<ResolversTypes['CheckType'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CheckTypesTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckTypesTotals'] = ResolversParentTypes['CheckTypesTotals']> = {
  AGENT_UPTIME?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ANTI_VIRUS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  HACKER_ALERT?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PHYSICAL_DISK?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PHYSICAL_MEMORY?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  CRITICAL_EVENTS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  VULNERABILITY?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  BACKUP?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  EXCHANGE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  FILE_SIZE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  EVENT_LOG?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  WINDOWS_SERVER_UPDATE_SERVICES?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  AUTO_START_SERVICE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  DISK_SPACE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  DISK_SPACE_CHANGE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PERFORMANCE_MONITORING_PROCESSOR_UTILISATION?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PERFORMANCE_MONITORING_MEMORY?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PERFORMANCE_MONITORING_NETWORK?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PERFORMANCE_MONITORING_DISK?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PERFORMANCE_MONITORING_PROCESSOR_QUEUE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  WEBSITE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SNMP?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  BANDWIDTH?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  WEB_PROTECTION_BANDWIDTH?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  TCP_SERVICE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PING?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MTA?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PACKAGE_MANAGEMENT?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  DAEMON?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PROCESS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LOG_FILE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SQL_SERVER?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  OSX_UPDATE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SERVICE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  AUTOMATED_TASK?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MANAGED_ONLINE_BACKUP?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  MANAGED_ANTI_VIRUS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SCRIPT?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  BRECK?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  BRECK_UPDATE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ECHO_BANDWIDTH?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PHYSICAL_DISK_SMART?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PRINTER_MARKER?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PRINTER_PAPER?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  NETWORKDEVICE_CONNECTIVITY?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  NETWORKDEVICE_PING?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ROUTER_INTERFACE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  GRUNT_WORK?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  HOGSHEAD?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  NETWORKDEVICE_SNMP?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  DEM_MANAGED_ANTI_VIRUS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClearCheckSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClearCheckSuccess'] = ResolversParentTypes['ClearCheckSuccess']> = {
  checkId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client'] = ResolversParentTypes['Client']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sites?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Site']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DeviceType'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checks?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Check']>>>, ParentType, ContextType>;
  site?: Resolver<ResolversTypes['Site'], ParentType, ContextType>;
  os?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  agent?: Resolver<ResolversTypes['Agent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeviceTypeTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeviceTypeTotals'] = ResolversParentTypes['DeviceTypeTotals']> = {
  SERVER?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  WORKSTATION?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  LAPTOP?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FilterInputParametersResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilterInputParameters'] = ResolversParentTypes['FilterInputParameters']> = {
  status?: Resolver<ReadonlyArray<Maybe<ResolversTypes['CheckStatus']>>, ParentType, ContextType>;
  deviceType?: Resolver<ReadonlyArray<Maybe<ResolversTypes['DeviceType']>>, ParentType, ContextType>;
  checkType?: Resolver<ReadonlyArray<Maybe<ResolversTypes['CheckType']>>, ParentType, ContextType>;
  search?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sorter?: Resolver<ResolversTypes['SortParams'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  runChecks?: Resolver<ResolversTypes['ChecksResult'], ParentType, ContextType, RequireFields<MutationRunChecksArgs, never>>;
  clearChecks?: Resolver<ResolversTypes['ChecksResult'], ParentType, ContextType, RequireFields<MutationClearChecksArgs, never>>;
};

export type NodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']> = {
  __resolveType: TypeResolveFn<'Check', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  checkTypeInformation?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CheckTypeInformation']>>>, ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType, RequireFields<QueryDeviceArgs, 'deviceID'>>;
  activeIssues?: Resolver<ResolversTypes['ActiveIssues'], ParentType, ContextType, RequireFields<QueryActiveIssuesArgs, 'input'>>;
  sitesByDevice?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Site']>>, ParentType, ContextType, RequireFields<QuerySitesByDeviceArgs, 'deviceID'>>;
};

export type SiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Site'] = ResolversParentTypes['Site']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  devices?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Device']>>>, ParentType, ContextType>;
  client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  totalChecks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  statusChecks?: Resolver<Maybe<ResolversTypes['CheckStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SortParamsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SortParams'] = ResolversParentTypes['SortParams']> = {
  sortBy?: Resolver<ResolversTypes['SortByInput'], ParentType, ContextType>;
  direction?: Resolver<ResolversTypes['SortDirection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Resolvers<ContextType = any> = {
  ActiveIssues?: ActiveIssuesResolvers<ContextType>;
  ActiveIssuesFilterValues?: ActiveIssuesFilterValuesResolvers<ContextType>;
  Agent?: AgentResolvers<ContextType>;
  AuditedChecksResponse?: AuditedChecksResponseResolvers<ContextType>;
  BaseCheckType?: BaseCheckTypeResolvers<ContextType>;
  Check?: CheckResolvers<ContextType>;
  CheckError?: CheckErrorResolvers<ContextType>;
  CheckFrequencyItem?: GraphQLScalarType;
  ChecksResult?: ChecksResultResolvers<ContextType>;
  CheckStatusTotals?: CheckStatusTotalsResolvers<ContextType>;
  CheckTypeInformation?: CheckTypeInformationResolvers<ContextType>;
  CheckTypeInformationWithTotal?: CheckTypeInformationWithTotalResolvers<ContextType>;
  CheckTypesTotals?: CheckTypesTotalsResolvers<ContextType>;
  ClearCheckSuccess?: ClearCheckSuccessResolvers<ContextType>;
  Client?: ClientResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Device?: DeviceResolvers<ContextType>;
  DeviceTypeTotals?: DeviceTypeTotalsResolvers<ContextType>;
  FilterInputParameters?: FilterInputParametersResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Site?: SiteResolvers<ContextType>;
  SortParams?: SortParamsResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  intl?: IntlDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;
export type MockResolvers = MockResolversWrapper<Resolvers>;