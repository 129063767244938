import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  CustomChecksConfigService,
  EventDispatcherService,
  WizardTypeEnum,
} from "@solarwinds/monitoring-checks";
import { MdmDialogService, MdmNavigationService } from "@solarwinds/msp-mdm-ui";
import { RmmEventDispatcherService, RmmEventType } from "@solarwinds/msp-rmm-event-dispatcher";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { RouteMonitorService } from "@solarwinds/msp-rmm-route-monitor";
import { UnmonitoredDevicesConfigurationService } from "@solarwinds/msp-rmm-unmonitored-devices";
import { omit } from "lodash-es";
import { Observable, Subscription } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { CheckType } from "../../generated/resolvers-types";
import { AccountSettingsProvider } from "../auth/account-settings-provider";
import { ClientGroupsPermissionsHandlerService } from "../client-groups/services/client-groups-permissions-handler.service";
import { DeviceModelMappers } from "../custom-checks/device-model-mappers";
import { CustomChecksConfigHandlerService } from "../custom-checks/services/custom-checks-config-handler.service";
import { AddDeviceWidgetPermissionHandlerService } from "../dashboard/add-device/add-device-widget-permission-handler.service";
import { FeatureFlag } from "../feature-flag.enum";
import { IframeManagerService } from "../iframe-manager.service";
import { ModalService } from "../modal.service";
import { OverlayService } from "../overlay.service";
import { PlatformBarEventUtilityService } from "../platform-bar/platform-bar-event-utility.service";
import { PsaService } from "../psa";
import { RouteItem } from "../route-item";
import { SessionExpiringHandlerService } from "../session-expiring-handler.service";
import { UnmonitoredDevicesConfigProvider } from "../unmonitored-devices/unmonitored-devices-config-provider.service";
import { ViewportOverlayService } from "../viewport-overlay.service";

import { DashboardRedirectService } from "./dashboard-redirect.service";

enum EventType {
  overlay = "overlay",
  navigate = "navigate",
  sessionTimeout = "sessionTimeout",
  viewportOverlay = "viewportOverlay",
  dialogClose = "dialogClose",
  viewDeviceInAssetTracking = "viewDeviceInAssetTracking",
  openPsaScreen = "openPsaScreen",
  openNetworkDevicesCustomCheckWizard = "openNetworkDevicesCustomCheckWizard",
  openNetworkDevicesCustomChecksLibrary = "openNetworkDevicesCustomChecksLibrary",
  openNetworkDevicesCustomCheckLite = "openNetworkDevicesCustomCheckLite",
  openClientGroupSettings = "openClientGroupSettings",
  openPsaDialog = "openPsaDialog",
  openSecurityPostureWizard = "openSecurityPostureWizard",
  openMdmProfileLibrary = "openMdmProfileLibrary",
  openMdmDeviceLibrary = "openMdmDeviceLibrary",
  openMdmCommandHistory = "openMdmCommandHistory",
  openViewProfiles = "openViewProfiles",
  openRemoveProfiles = "openRemoveProfiles",
  openInstallProfiles = "openInstallProfiles",
  openUnmonitoredDevices = "openUnmonitoredDevices",
  openActiveIssues = "openActiveIssues",
}

interface EventData {
  type: EventType;
  payload: {
    [value: string]: any;
  };
}

@Component({
  selector: "app-shell-container",
  templateUrl: "./shell-container.component.html",
  styleUrls: ["./shell-container.component.scss"],
})
export class ShellContainerComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();

  urls: string[] = [];
  routeItem: RouteItem;
  showSpinner = new Observable();
  browserRefreshed = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private iframeManagerService: IframeManagerService,
    private overlayService: OverlayService,
    private viewportOverlayService: ViewportOverlayService,
    private modalService: ModalService,
    private sessionExpiringHandlerService: SessionExpiringHandlerService,
    private routeMonitorService: RouteMonitorService,
    private psaService: PsaService,
    private featurePreviewService: FeaturePreviewService,
    private addDevicePermission: AddDeviceWidgetPermissionHandlerService,
    private clientGroupsPermissionsHandlerService: ClientGroupsPermissionsHandlerService,
    private customChecksConfigService: CustomChecksConfigService,
    private customChecksConfigHandlerService: CustomChecksConfigHandlerService,
    private deviceModelMappers: DeviceModelMappers,
    private router: Router,
    private rmmEventDispatchService: RmmEventDispatcherService,
    private customChecksEventDispatch: EventDispatcherService,
    private dashboardRedirectService: DashboardRedirectService,
    private unmonitoredDevicesConfigService: UnmonitoredDevicesConfigurationService,
    private accountSettingsProvider: AccountSettingsProvider,
    private unmonitoredDevicesConfigHandler: UnmonitoredDevicesConfigProvider,
    private admNavigationService: MdmNavigationService,
    private admDialogService: MdmDialogService,
    private platformBarEventUtilityService: PlatformBarEventUtilityService
  ) {}

  ngOnInit(): void {
    this.dashboardRedirectService.redirectToNGDashboard();
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        this.dashboardRedirectService.catchNavigatedRoute(event, this.router);
      })
    );

    this.subscriptions.add(this.addDevicePermission.permissionCheck().subscribe());

    this.subscriptions.add(
      this.clientGroupsPermissionsHandlerService.permissionCheck().subscribe()
    );

    this.customChecksConfigService.setConfig(this.customChecksConfigHandlerService.getConfig());

    this.unmonitoredDevicesConfigService.setConfig(
      this.unmonitoredDevicesConfigHandler.getConfig()
    );

    this.subscriptions.add(
      this.customChecksEventDispatch.monitoringChecksQuit.subscribe(() =>
        this.rmmEventDispatchService.dispatchEvent({
          eventType: RmmEventType.networkDeviceChecksReload,
        })
      )
    );

    this.subscriptions.add(
      this.iframeManagerService.data.subscribe((data) => {
        const queryParams = this.getForwardedQueryParams(
          this.activatedRoute.snapshot.queryParams,
          data.forwardQueryParams
        );
        this.routeItem = {
          url: data.url,
        };

        if (queryParams) {
          this.routeItem.queryParams = queryParams;
        }
        if (data.shouldReload) {
          this.routeItem.shouldReload = true;
        }
        if (!this.isUrlCached(data.url)) {
          /**
           * One time initialisation of shell
           */
          this.urls.push(data.url);

          if (data.overlay) {
            this.overlayService.switchOverlay(true);
          }
          if (data.viewportOverlay) {
            this.viewportOverlayService.switchViewportOverlay(true);
          }
          if (data.handleSessionTimeoutWindowPopup) {
            this.routeItem.handleSessionTimeoutWindowPopup = data.handleSessionTimeoutWindowPopup;
          }
        }
        if (data.view) {
          this.routeItem.view = data.view;
        }
        if (data.shouldNotifyQueryChange) {
          this.routeItem.shouldNotifyQueryChange = data.shouldNotifyQueryChange;
        }
        this.routeMonitorService.setUrlFromView(data.view);
      })
    );

    this.subscriptions.add(
      this.iframeManagerService.iframesHiding.subscribe(() => {
        this.routeItem = { url: null };
      })
    );

    this.subscriptions.add(
      this.featurePreviewService
        .isOn(FeatureFlag.platformBarv2)
        .pipe(
          filter((isEnabled) => Boolean(isEnabled)),
          switchMap(() =>
            this.rmmEventDispatchService.dispatchedRmmEvent$.pipe(
              filter((event) =>
                this.platformBarEventUtilityService.filterForPlatformBarEvents(
                  this?.routeItem?.view ?? "",
                  event?.eventType
                )
              ),
              map((event) =>
                this.platformBarEventUtilityService.mapEventTypeToViewType(event?.eventType)
              )
            )
          )
        )
        .subscribe((viewType) => this.modalService.openDialogByViewType(viewType))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  viewAddDeviceWizard(): boolean {
    return this.routeItem && this.routeItem.view === "add-device-view";
  }

  viewRmmShell(url: string): boolean {
    return !this.viewAddDeviceWizard() && this.routeItem.url === url;
  }

  getRouteItemForCurrentShell(url): RouteItem | null {
    return this.viewRmmShell(url) ? this.routeItem : null;
  }

  @HostListener("window:message", ["$event"])
  receiveMessage(event: MessageEvent): void {
    if (event.origin !== window.location.origin) {
      return;
    }

    this.processMessage(event.data);
  }

  trackByUrl(item: string): string {
    return item;
  }

  private getForwardedQueryParams(queryParams: Params, forwardQueryParams: string[]): Params {
    if (!forwardQueryParams || !forwardQueryParams.length || !Object.keys(queryParams).length) {
      return null;
    }
    return Object.keys(queryParams)
      .filter((name) => forwardQueryParams.indexOf(name) !== -1)
      .reduce((result, name) => {
        result[name] = queryParams[name];
        return result;
      }, {});
  }

  private isUrlCached(routeUrl: string): boolean {
    return this.urls.some((url) => url === routeUrl);
  }

  /**
   * @param {EventData} data    content of postMessage
   */
  private processMessage(data: EventData): void {
    switch (data.type) {
      case EventType.overlay:
        this.overlayService.switchOverlay(data.payload.value);
        break;
      case EventType.viewportOverlay:
        this.viewportOverlayService.switchViewportOverlay(data.payload.value);
        break;
      case EventType.navigate:
        this.routeMonitorService.navigateByView(data.payload.value);
        break;
      case EventType.dialogClose:
        this.modalService.closeView();
        break;
      case EventType.sessionTimeout:
        if (!data.payload.value) {
          this.sessionExpiringHandlerService.cancelLogoutTimer();
        }
        break;
      case EventType.viewDeviceInAssetTracking:
        this.routeMonitorService.navigateWithParams("assettracking", data.payload);
        break;
      case EventType.openPsaScreen:
        this.psaService.openPsaScreen(data.payload.screen, omit(data.payload, "screen"));
        break;
      case EventType.openNetworkDevicesCustomCheckWizard:
        this.featurePreviewService
          .isOn("NETWORK_DEVICES_CUSTOM_CHECKS_LIBRARY")
          .subscribe((isEnabled) => this.customChecksConfigService.setLibraryEnabled(isEnabled));

        this.customChecksConfigService.setDevices(
          this.deviceModelMappers.getDevices(data.payload.selectedDevice)
        );
        this.customChecksConfigService.setWizardType(WizardTypeEnum.DeviceOnly);

        if (data.payload.editMode) {
          this.router.navigateByUrl(
            "/edit-check/" + data.payload.templateResourceUuid + "?origin=2"
          );
        } else {
          this.router.navigateByUrl("/wizard" + "?origin=2");
        }
        break;
      case EventType.openNetworkDevicesCustomChecksLibrary:
        this.featurePreviewService
          .isOn("NETWORK_DEVICE_MTD_AB3588_CUSTOM_CHECKS_COMMUNITY")
          .subscribe((isEnabled) => this.customChecksConfigService.setCommunityEnabled(isEnabled));
        this.router.navigateByUrl("/library" + "?origin=2");
        break;
      case EventType.openUnmonitoredDevices:
        this.featurePreviewService
          .isOn("MTD_AB3819_UNMONITORED_DEVICE_MENU")
          .subscribe((isEnabled) => {
            if (isEnabled) {
              this.router.navigateByUrl("/unmonitored-devices");
            }
          });
        break;
      case EventType.openActiveIssues:
        if (data.payload) {
          if (Array.isArray(data.payload.checkTypes)) {
            const checkTypes: CheckType[] = data.payload.checkTypes.filter((type) =>
              this.isValidCheckTypeEnum(type)
            );
            if (checkTypes.length > 0) {
              return this.routeToActiveIssuesWithParameters(checkTypes);
            }
          }

          if (this.isValidCheckTypeEnum(data.payload.checkTypes)) {
            return this.routeToActiveIssuesWithParameters(data.payload.checkTypes);
          }
        }
        this.routeMonitorService.navigate("/active-issues");
        break;
      case EventType.openNetworkDevicesCustomCheckLite:
        this.customChecksConfigService.setDevices(
          this.deviceModelMappers.getDevices(data.payload.selectedDevice)
        );
        this.router.navigateByUrl("/lite" + "?origin=2");
        break;
      case EventType.openPsaDialog:
        this.psaService.openPsaDialog(data.payload.dialog, data.payload);
        break;
      case EventType.openClientGroupSettings:
        this.handleOpenClientGroupSettings(data);
        break;
      case EventType.openSecurityPostureWizard:
        this.admNavigationService.showOnboarding();
        break;
      case EventType.openMdmProfileLibrary:
        this.admNavigationService.showProfileLibrary();
        break;
      case EventType.openMdmDeviceLibrary:
        this.admNavigationService.showDeviceLibrary();
        break;
      case EventType.openMdmCommandHistory:
        this.admDialogService.showDeviceCommandLogDialog(data.payload.device);
        break;
      case EventType.openInstallProfiles:
        this.admNavigationService.showInstallProfiles(data.payload.applicationDeviceId);
        break;
      case EventType.openRemoveProfiles:
        this.admNavigationService.showRemoveProfiles(data.payload.applicationDeviceId);
        break;
      case EventType.openViewProfiles:
        this.admDialogService.showDeviceProfilesDialog(data.payload.device);
        break;
    }
  }

  private handleOpenClientGroupSettings(data: EventData): void {
    if (data.payload.isDirectCustomer) {
      this.router.navigateByUrl("/department-groups");
    } else {
      this.router.navigateByUrl("/client-groups");
    }
  }

  private isValidCheckTypeEnum(value: any): boolean {
    return Object.values(CheckType).includes(value as CheckType);
  }

  private routeToActiveIssuesWithParameters(checkTypes: CheckType[] | CheckType): void {
    this.routeMonitorService.navigateWithEmbeddedParams("/active-issues", {
      checkType: checkTypes,
    });
  }
}
