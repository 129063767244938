<ad-add-device-workflow
  *ngIf="viewAddDeviceWizard()"
  [hidden]="!viewAddDeviceWizard()"
  i18n-wizardTitle
  wizardTitle="Add Device"
></ad-add-device-workflow>
<app-rmm-shell
  *ngFor="let url of urls; trackBy: trackByUrl"
  [routeItem]="getRouteItemForCurrentShell(url)"
  [isHidden]="!viewRmmShell(url)"
>
</app-rmm-shell>
