import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { deviceMainOSQueryMapping } from "../../consts/device-os-query.const";
import { DeviceType } from "../../enums/device-type.enum";
import { WidgetDataBuilderService } from "../widget-data-builder.service";

import { DevicesTypeQuery } from "./devices";
import { MaestroService } from "./maestro/maestro.service";

@Injectable({
  providedIn: "root",
})
export class DeviceOsService {
  private readonly deviceMainOSQueryMap = deviceMainOSQueryMapping;
  private readonly osNameMap = new Map([
    ["windows", ["Windows", "Service Pack"]],
    ["service pack", ["Windows", "Service Pack"]],
    ["mac", ["mac", "OS X"]],
    ["os x", ["mac", "OS X"]],
    ["linux", ["Linux"]],
  ]);

  constructor(
    private maestro: MaestroService,
    private widgetDataBuilder: WidgetDataBuilderService
  ) {}

  getDevicesByMainOs(): Observable<object> {
    return this.maestro.getDevices(this.getDeviceByTypesQuery(DeviceType.ALL)).pipe(
      map((devices: DevicesTypeQuery) => this.createMainOsNamesFromDevices(devices)),
      map((data: ChartItem[]) => this.widgetDataBuilder.createDataObject("devicesByMainOS", data))
    );
  }

  private getDeviceByTypesQuery(deviceType: DeviceType): object {
    if (!this.deviceMainOSQueryMap.has(deviceType)) {
      throw new Error(`Unknown device type ${deviceType}`);
    }

    return this.deviceMainOSQueryMap.get(deviceType);
  }

  private createMainOsNamesFromDevices(devices: DevicesTypeQuery): ChartItem[] {
    const allOsNames: any[] = this.findOsName(devices);
    const uniqueOsNames: object = this.uniqueOsNamesListHandler(allOsNames);

    return this.createDevicesByMainOsWidgetData(uniqueOsNames);
  }

  private findOsName(devices): any[] {
    const elements = devices.elements || devices.data || [];

    return elements.map((device) => {
      if (device.osVersion) {
        return this.extractOsName(device.osVersion);
      }

      if (device.osversion) {
        return this.extractOsName(device.osversion);
      }

      if (device.os) {
        return device.os.match(/((?:[a-z][a-z]+))/gi)[0];
      }

      return "Windows";
    });
  }

  private extractOsName(osVersion: string) {
    const matches = osVersion.match(/(Windows|Linux|mac|OS X|Service Pack)/i);
    return matches && matches.length !== 0 ? this.mapOsName(matches[0]) : "Windows";
  }

  private uniqueOsNamesListHandler(allOsNames): object {
    return allOsNames.reduce(
      (occurrence: object, osName: string) => this.getUniqueOsNames(occurrence, osName),
      {}
    );
  }

  private getUniqueOsNames(occurrence: object, targetName: any): object {
    occurrence[targetName] = (occurrence[targetName] || 0) + 1;
    return occurrence;
  }

  private createDevicesByMainOsWidgetData(uniqueOsNames: object): ChartItem[] {
    return Object.keys(uniqueOsNames).map((os: string) => ({
      name: this.osNameHandler(os),
      value: uniqueOsNames[os],
      sourceQuery: {
        deviceFilter: {
          ...this.deviceMainOSQueryMap,
          criteria: {
            type: "in",
            name: "os_version",
            value: this.osNameMap.get(os.toLowerCase()),
          },
        },
      },
    }));
  }

  private mapOsName(os) {
    if (os === "Service Pack") {
      return "windows";
    }

    if (os === "OS X") {
      return "mac";
    }

    return os.toLowerCase();
  }

  private osNameHandler(os) {
    const osName = `${os.charAt(0).toUpperCase()}${os.slice(1)}`;

    if (osName === "Tux") {
      return "Linux";
    }

    return osName;
  }
}
