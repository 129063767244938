import { Injectable } from "@angular/core";
import { ChartItem, MspDashboardService } from "@solarwinds/msp-dashboard-module";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WidgetDataBuilderService {
  constructor(private mspDashboardService: MspDashboardService) {}

  createDataObject(serviceMethod: string, data: ChartItem[]): object {
    return this.generateChartItem(serviceMethod, data);
  }

  fakeDataObject(serviceMethod: string): Observable<object> {
    return of(this.generateChartItem(serviceMethod, []));
  }

  private generateChartItem(serviceMethod: string, data: ChartItem[]): object {
    return this.mspDashboardService[serviceMethod]({
      serviceUnavailable: data.length > 0 ? false : true,
      noDataAvailable: data.length > 0 ? false : true,
      value: data.length > 0 ? data : null,
      forbidden: data.length > 0 ? false : true,
    });
  }
}
