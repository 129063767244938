import { Injectable } from "@angular/core";
import { ChartItem } from "@solarwinds/msp-dashboard-module";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { iif, Observable, zip } from "rxjs";
import { flatMap, last, map, mergeMap } from "rxjs/operators";

import {
  activeConditionQuery as activeConditionQueryV2,
  installFailedConditionQuery as installFailedConditionQueryV2,
  notCompatibleConditionQuery as notCompatibleConditionQueryV2,
  pendingConditionQuery as pendingConditionQueryV2,
  rebootRequiredConditionQuery as rebootRequiredConditionQueryV2,
} from "../../consts/mav-feature-install-status-query-v2.const";
import {
  activeConditionQuery,
  installFailedConditionQuery,
  notCompatibleConditionQuery,
  pendingConditionQuery,
  rebootRequiredConditionQuery,
} from "../../consts/mav-feature-install-status-query.const";
import { DeviceType } from "../../enums/device-type.enum";
import { MaestroVersion } from "../../enums/maestro-versions.enum";
import { WidgetDataBuilderService } from "../widget-data-builder.service";

import { FeatureInstallCommonService } from "./feature-install-common.service";
import { FeaturePermissions } from "./feature-permissions.enum";
import { PermissionHandlerService } from "./permission-handler.service";

@Injectable({
  providedIn: "root",
})
export class MavFeatureInstallStatusService {
  constructor(
    private featureInstall: FeatureInstallCommonService,
    private widgetDataBuilder: WidgetDataBuilderService,
    private permissionHandler: PermissionHandlerService,
    private featurePreviewService: FeaturePreviewService
  ) {}

  private static mavFeatureStatusQueries(version: MaestroVersion): Map<DeviceType, object>[] {
    if (version === MaestroVersion.V2) {
      return [
        installFailedConditionQueryV2,
        pendingConditionQueryV2,
        rebootRequiredConditionQueryV2,
        notCompatibleConditionQueryV2,
        activeConditionQueryV2,
      ];
    }

    return [
      installFailedConditionQuery,
      pendingConditionQuery,
      rebootRequiredConditionQuery,
      notCompatibleConditionQuery,
      activeConditionQuery,
    ];
  }

  getMavFeatureInstallStatus(): Observable<object> {
    const queriesV1 = this.featureInstall.getStatusQueries(
      DeviceType.ALL,
      MavFeatureInstallStatusService.mavFeatureStatusQueries(MaestroVersion.V1)
    );

    const queriesV2 = this.featureInstall.getStatusQueries(
      DeviceType.ALL,
      MavFeatureInstallStatusService.mavFeatureStatusQueries(MaestroVersion.V2)
    );

    const statusesV1: Observable<object>[] = this.featureInstall.getStatuses(
      queriesV1,
      MaestroVersion.V1
    );

    const statusesV2: Observable<object>[] = this.featureInstall.getStatuses(
      queriesV2,
      MaestroVersion.V2
    );

    const permission: Observable<boolean> = this.permissionHandler.isFeaturePermitted(
      FeaturePermissions.mav
    );

    const v1WidgetData = this.getWidgetData(queriesV1, statusesV1, permission);
    const v2WidgetData = this.getWidgetData(queriesV1, statusesV2, permission);

    return this.featurePreviewService
      .isOn("RMM_32480_ACTIVATE_MAESTRO_V2_QUERIES")
      .pipe(mergeMap((flagOn: boolean) => iif(() => flagOn, v2WidgetData, v1WidgetData)));
  }

  private getWidgetData(
    queries: object[],
    statuses: Observable<object>[],
    permission: Observable<boolean>
  ): Observable<object> {
    return permission.pipe(
      last(),
      flatMap((permit) =>
        permit
          ? this.getData(queries, statuses)
          : this.widgetDataBuilder.fakeDataObject("mavFeatureInstallStatusData")
      )
    );
  }

  private getData(queries, statuses): Observable<object> {
    return zip(...statuses).pipe(
      map((data: object) =>
        this.featureInstall.createFeatureInstallStatusWidgetData(data, queries)
      ),
      map((data: ChartItem[]) =>
        this.widgetDataBuilder.createDataObject("mavFeatureInstallStatusData", data)
      )
    );
  }
}
