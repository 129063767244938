import { Inject, Injectable } from "@angular/core";
import { LOCALE_ID } from "@angular/core";
import { FeaturePreviewService } from "@solarwinds/msp-rmm-feature-preview";
import { SessionResponse, UserSessionService } from "@solarwinds/msp-rmm-user-session";
import { ApiTokenProvider, SsoAuthToken } from "@solarwinds/quasar-api-client";
import { FeatureFlag } from "app/feature-flag.enum";
import { from, iif, Observable } from "rxjs";
import { map, mergeMap, retry } from "rxjs/operators";

import { AccountSettingsProvider } from "./account-settings-provider";
import { AccountSettings } from "./account-settings.interface";

export interface LegacyCompositeToken {
  scopes: Set<string>;
  token: string;
  expiry: Date;
}
@Injectable({
  providedIn: "root",
})
export class QAPIAuthService implements ApiTokenProvider {
  readonly token = this.handleTokenBasedOnFeatureFlag();

  constructor(
    private acSettingsProvider: AccountSettingsProvider,
    private featurePreview: FeaturePreviewService,
    private userSessionService: UserSessionService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  private handleTokenBasedOnFeatureFlag(): Observable<SsoAuthToken | LegacyCompositeToken> {
    return this.featurePreview
      .isOn(FeatureFlag.ssoFlag)
      .pipe(
        mergeMap((flagState) =>
          iif(
            () => flagState,
            this.getSsoAccessToken(),
            this.acSettingsProvider
              .settingsList()
              .pipe(map((s: AccountSettings) => this.createQuasarToken(s)))
          )
        )
      );
  }

  private getSsoAccessToken(): Observable<SsoAuthToken> {
    const sessionResponse = from(
      this.userSessionService.loadSession().then(({ result }: SessionResponse) => result)
    ).pipe(retry(5));

    return sessionResponse.pipe(
      map(({ accountSettings }) => ({
        token: accountSettings?.dashboardServer?.ssoAccessToken,
      }))
    );
  }

  private createQuasarToken({
    maestro,
    checkProcessor,
    clientGroupClients,
    dashboardServer,
  }: AccountSettings): LegacyCompositeToken {
    const { JWT, url } = checkProcessor;
    const timeNow: number = Math.round(new Date().getTime() / 1000);
    const timeEnd: number = timeNow + 3600;

    const tokenPayload = {
      nbf: timeNow,
      iat: timeNow,
      exp: timeEnd,
      scopes: ["rmm"],
      context: {
        rmm: {
          maestro: {
            ...maestro,
          },
          checkProcessor: {
            JWT,
            url,
          },
          clientGroupClients,
          locale: this.locale,
          dashboardServer,
        },
      },
    };

    const tokenHeader = {
      typ: "JWT",
      alg: "HS256",
    };

    return {
      scopes: new Set<string>(tokenPayload.scopes),
      token: `${btoa(JSON.stringify(tokenHeader).toString())}.${btoa(
        JSON.stringify(tokenPayload)
      ).replace(/=+$/, "")}.sig`,
      expiry: new Date(timeEnd * 1000),
    };
  }
}
