<nav-menu-item i18n-title="@@dashboards" title="Dashboards" icon="ic_section_dashboard">
  <nav-menu-item
    i18n-title="@@dashboard_title"
    title="Overview"
    href="/dash"
    noreferrer
  ></nav-menu-item>
  <nav-menu-item
    [hidden]="!isLinkEnabled(NavLink.MonitoredDevices)"
    i18n-title="@@monitored_devices_title"
    title="Monitored Devices"
    href="/monitored-devices"
    noreferrer
  ></nav-menu-item>
  <nav-menu-item
    [hidden]="!isLinkEnabled(NavLink.UnmonitoredDevices)"
    i18n-title="@@unmonitored_devices_title"
    title="Unmonitored Devices"
    href="/unmonitored-devices"
    noreferrer
  ></nav-menu-item>
  <nav-menu-item
    [hidden]="!isLinkEnabled(NavLink.ActiveIssues)"
    i18n-title="@@active_issues_title"
    title="Active Issues"
    href="/active-issues"
    noreferrer
  ></nav-menu-item>
</nav-menu-item>
