import { ElementRef, Injectable } from "@angular/core";
import {
  AccountWizardSpinnerService,
  AddDeviceService,
  StepService,
} from "@solarwinds/account-wizard-module";
import { DownloadAgentService } from "@solarwinds/account-wizard-module";
import { AddDeviceFormValues } from "@solarwinds/account-wizard-module/lib/add-device-form-values";
import { zip } from "rxjs";

import { AgentService } from "./agent.service";
import { ClientService } from "./client.service";
import { SiteService } from "./site.service";

@Injectable()
export class AccountWizardSubmissionService {
  constructor(
    private addDeviceService: AddDeviceService,
    private clientService: ClientService,
    private siteService: SiteService,
    private agentService: AgentService,
    private spinner: AccountWizardSpinnerService,
    private downloadAgentService: DownloadAgentService,
    private stepService: StepService
  ) {}

  init(): void {
    const downloadLinkLoadedEvent = this.downloadAgentService.downloadLinkLoaded;
    const addDeviceEvent = this.addDeviceService.addDeviceEvent;
    const deviceDownloadEvent = zip(downloadLinkLoadedEvent, addDeviceEvent);
    this.spinner.start();
    deviceDownloadEvent.subscribe(async ([downloadLink, addDeviceFormValues]) => {
      await this.handleDeviceEvent(<AddDeviceFormValues>addDeviceFormValues, downloadLink);
    });
  }

  private async handleDeviceEvent(
    { clientName, siteName, selectedDeviceOs }: AddDeviceFormValues,
    downloadLink: ElementRef
  ) {
    const { clientId, siteId } = await this.createClientWithSite(clientName, siteName);
    const agentVersion = await this.agentService
      .getAvailableVersions()
      .toPromise()
      .then((versions) =>
        this.agentService.getCurrentAgentVersionForOs(versions, selectedDeviceOs)
      );

    const buildResponse = await this.agentService
      .buildAgentPackage(clientId, siteId, agentVersion)
      .toPromise()
      .then((result) => {
        this.spinner.end();
        return result;
      });

    if (buildResponse !== "OK") {
      throw new Error("Unable to build agent");
    }

    this.stepService.exitAccountWizardEvent.subscribe(() => {
      if (downloadLink && downloadLink.nativeElement) {
        URL.revokeObjectURL(downloadLink.nativeElement.href);
      }
    });

    this.agentService.downloadAgentPackage(clientId, siteId, agentVersion, downloadLink);
  }

  private createClientWithSite(clientName: string, siteName: string) {
    return this.clientService
      .createClient(clientName)
      .then(({ clientid, success, formerrors }) => {
        if (!success) {
          throw new Error(
            `Unable to create client with name: "${clientName}" [${formerrors.toString()}]`
          );
        }
        return Promise.all([this.siteService.createSite(siteName, clientid), clientid]);
      })
      .then(([response, clientid]) => {
        if (!response || !response.result || !response.result.newSiteId) {
          throw new Error(
            `Unable to create site with name: "${siteName}" under client ${clientName}`
          );
        }
        return { clientId: clientid, siteId: response.result.newSiteId };
      });
  }
}
